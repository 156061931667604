import {
  HTTP_INTERCEPTORS,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  from,
  mergeMap,
  take,
} from 'rxjs';
import {
  AmplifyProvider,
  CoreAuthAmplifyProvider,
  CoreAuthConfigProvider,
} from '../providers';

@Injectable()
export class CoreAuthInterceptor implements HttpInterceptor {
  private _requestOngoing: BehaviorSubject<boolean>;
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: AmplifyProvider,
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType
  ) {
    this._requestOngoing = new BehaviorSubject(false as boolean);
  }
  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    // If the request is not 1 of our our api endpoints, we don't need to add the token.
    const addToken = this.config.api_endpoints.some((ep) =>
      request.url.includes(ep)
    );

    if (!addToken) {
      return next.handle(request);
    }

    return this._requestOngoing.pipe(
      /**
       * Prevent parrallel requests made to our api when working offline to
       * get around a racing issue.
       * - https://halfserious.atlassian.net/browse/SS-587
       */
      filter((_requestOngoing: boolean) => _requestOngoing === false),
      take(1),
      mergeMap(() => {
        // if (process.env.NG_APP_IS_OFFLINE === 'true') {
        //   this._requestOngoing.next(true);
        // }
        return from(this.coreAuthAmplify.currentSessionIdToken());
      }),
      mergeMap((currentSessionIdToken: string) => {
        request = request.clone({
          withCredentials: false,
          setHeaders: {
            Authorization: currentSessionIdToken,
          },
        });

        return next.handle(request);
      }),
      catchError((error: unknown) => {
        console.warn(error);
        console.log('CoreAuthInterceptor catchError', request);
        return next.handle(request);
      }),
      finalize(() => {
        // if (process.env.NG_APP_IS_OFFLINE === 'true') {
        //   this._requestOngoing.next(false);
        // }
      })
    );
  }
}

export const provideCoreAuthInterceptor = () => ({
  provide: HTTP_INTERCEPTORS,
  useClass: CoreAuthInterceptor,
  multi: true,
});
