// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatbotConversationType } from './chatbotConversationType';
import { ChatbotConversationExchangeDto } from './chatbotConversationExchangeDto';


export interface ChatbotConversationDto { 
    /**
     * Unique identifier of the user.
     */
    userId: string;
    /**
     * DateTime of the last update of the Conversation.
     */
    lastUpdateDateTime: string;
    /**
     * Unique identifier of the Conversation.
     */
    conversationId: number;
    conversationType: ChatbotConversationType;
    exchanges: Array<ChatbotConversationExchangeDto>;
}
export namespace ChatbotConversationDto {
}


