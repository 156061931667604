import { CoreError } from '@serious-stack/core/error';

export interface CoreAuthConfig {
  auth: {
    aws_project_region: string;
    aws_cognito_identity_pool_id?: string; // Validate
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
  };
  api_endpoints: string[];
}

export const CoreAuthError = CoreError;
export type CoreAuthError = CoreError;

export enum CoreAuthErrorType {
  CORE_AUTH_SIGNIN_ERROR = 'CORE_AUTH_SIGNIN_ERROR',
  CORE_AUTH_SIGNIN_CHANGE_PASSWORD_ERROR = 'CORE_AUTH_SIGNIN_CHANGE_PASSWORD_ERROR',
  CORE_AUTH_UNKNOWN_ERROR = 'CORE_AUTH_UNKNOWN_ERROR',
}

export interface AuthProvider {
  signIn: (username: string, password: string) => Promise<any>;
  signOut: () => Promise<void>;
  currentSessionIdToken: () => Promise<string>;
}
