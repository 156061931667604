import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import * as fromDesignSystem from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import * as fromFeatureFlags from '../../feature-flags';
import * as fromStore from '../../store';
import { SidebarButtonComponent } from './sidebar-button.component';
import { SidebarLogoComponent } from './sidebar-logo.component';

@Component({
  selector: 'ch-sidebar',
  standalone: true,
  styles: [
    `
      :host {
        z-index: 1; // Need for version tooltip to show over the main content

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;

        flex-shrink: 0;
        width: 5rem;
        height: 100%;
        min-height: 100vh;
        padding-top: 0.75rem;

        background-color: rgb(var(--color-neutral-900));

        .ch-board-sidebar-menu {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
        }
      }
    `,
  ],
  template: `
    <ch-sidebar-logo></ch-sidebar-logo>

    <div class="ch-board-sidebar-menu">
      <ch-sidebar-button
        glyph="grid_view"
        routerLink="/home/tmn-dashboard"
        [active]="!!(isTmnDashboardLoaded$ | async)"
      ></ch-sidebar-button>

      <ch-sidebar-button
        glyph="cardiology"
        routerLink="/home/equipment-health"
        [active]="!!(isEquipmentHealthLoaded$ | async)"
      ></ch-sidebar-button>
    </div>

    <ch-sidebar-button
      glyph="logout"
      title="Logout"
      (click)="signOut()"
    ></ch-sidebar-button>
  `,
  imports: [
    CommonModule,
    RouterModule,
    SidebarLogoComponent,
    SidebarButtonComponent,
    fromDesignSystem.CommonNgDesignSystemModule,
    fromFeatureFlags.FeatureFlagsDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly route = inject(Router);

  public readonly isTmnDashboardLoaded$ = this.route.events.pipe(
    filter((event: any) => event.routerEvent instanceof NavigationEnd),
    map<{ routerEvent: NavigationEnd }, boolean>((event) => {
      return (
        event.routerEvent.url.includes('tmn-dashboard') ||
        event.routerEvent.urlAfterRedirects.includes('tmn-dashboard')
      );
    })
  );

  public readonly isEquipmentHealthLoaded$ = this.route.events.pipe(
    filter((event: any) => event.routerEvent instanceof NavigationEnd),
    map<{ routerEvent: NavigationEnd }, boolean>((event) => {
      return (
        event.routerEvent.url.includes('equipment-health') ||
        event.routerEvent.urlAfterRedirects.includes('equipment-health')
      );
    })
  );

  ngOnInit() {
    this.route.events
      .pipe(filter((event: any) => event.routerEvent instanceof NavigationEnd))
      .subscribe();
  }

  signOut() {
    this.store.dispatch(fromStore.AppActions.signOutRequested());
  }
}
