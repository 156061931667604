// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatbotConversationMessageAttachmentDto } from './chatbotConversationMessageAttachmentDto';


export interface ChatbotConversationMessageDto { 
    /**
     * Text of the message.
     */
    text: string;
    /**
     * DateTime of the message.
     */
    timestamp: string;
    /**
     * Attachments.
     */
    attachments?: Array<ChatbotConversationMessageAttachmentDto>;
}

