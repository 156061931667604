import { InjectionToken } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import {
  excludeKeys,
  localStorageStrategy,
  persistState,
} from '@ngneat/elf-persist-state';
import { withRequestsStatus } from '@ngneat/elf-requests';
import * as fromModels from '../../models';

export enum Request {
  ROUTE_ROLE_ACCESS = 'route-role-access',
}

export interface UserType extends fromModels.User {
  role: fromModels.RoleName;
  company: string;
  cognitoIdentity: {
    username: string;
    email: string;
    provider: string;
  };
  password?: string;
}

export interface AuthCodeValidationType {
  email: string;
  password: string;
}

export interface DateFormat {
  dateFormat: string;
  timezone: string;
  locale: string;
}

export interface StoreProps {
  user: UserType;
  isLoading: boolean;
  authCodeValidationData: AuthCodeValidationType;
  dateFormat: DateFormat;
}

const name = 'charly-ui-store';
const { state, config } = createState(
  withProps<StoreProps>({
    isLoading: false,
    user: null as never,
    authCodeValidationData: null as never,
    dateFormat: {
      dateFormat: 'longDate',
      timezone: 'America/Toronto',
      locale: 'fr_CA',
    },
  }),
  withRequestsStatus<Request.ROUTE_ROLE_ACCESS>()
);

const store = new Store({ name, state, config });

persistState(store, {
  key: name,
  storage: localStorageStrategy,
  source: () =>
    store.pipe(
      excludeKeys([
        'requestsStatus',
        'isLoading',
        'user',
        'authCodeValidationData',
      ])
    ),
});

export type providerType = typeof store;
export const providerToken = new InjectionToken<providerType>('STORE', {
  providedIn: 'root',
  factory: () => store,
});

export default {
  provide: providerToken,
  useValue: store,
};
