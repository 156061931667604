import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import {
  SkeletonAnimation,
  SkeletonShape,
} from '../../molecules/skeleton/skeleton.enum';

@Directive({
  selector: '[adSkeleton]',
})
export class SkeletonDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input() adSkeletonAnimation: SkeletonAnimation = 'pulse';
  @Input() adSkeletonHeight = '100%';
  @Input() adSkeletonWidth = '100%';
  @Input() adSkeletonShape: SkeletonShape = 'rectangle';

  roundings: { [key: string]: string } = {
    rectangle: '0',
    circle: '50%',
  };

  ngOnInit() {
    const component = this.el.nativeElement;
    component.classList.add('bg-neutral-100');

    if (this.adSkeletonAnimation === 'wave') {
      component.classList.add('skeleton-directive');
    } else {
      component.classList.add('animate-pulse');
    }

    this.renderer.setStyle(component, 'height', this.adSkeletonHeight);
    this.renderer.setStyle(component, 'width', this.adSkeletonWidth);
    this.renderer.setStyle(
      component,
      'border-radius',
      this.roundings[this.adSkeletonShape]
    );
  }
}
