import { CustomError } from 'ts-custom-error';

export class CoreError extends CustomError {
  public constructor({
    name,
    message,
    cause,
    extra,
    tags,
  }: {
    name: string;
    message: string;
    cause?: unknown;
    extra?: any;
    tags?: Array<string>;
  }) {
    super(message);
    Object.defineProperty(this, 'name', { value: name });
    Object.defineProperty(this, 'message', { value: message });
    (cause as Error)?.stack &&
      Object.defineProperty(this, 'stack', {
        value: this.stack?.concat((cause as { stack: string })?.stack),
      });
    (this as any).cause = cause;
    (this as any).extra = extra;
    (this as any).tags = tags;
  }
}
