import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonNgDesignSystemModule } from '@common/ng-design-system';
import { TimestampToRelativePipe } from './equipment-health-timestamp-to-relative.pipe';

@Component({
  selector: 'ch-equipment-health-kanban-column-card-kpi',
  standalone: true,
  imports: [CommonNgDesignSystemModule, TimestampToRelativePipe],
  styles: [
    `
      :host {
        @apply flex flex-1 justify-center;
      }
    `,
  ],
  template: `
    <div class="flex flex-col items-center gap-0.5 text-sm text-neutral-50 ">
      <span class="font-semibold text-lg text-center">{{ value() }}</span>
      <span class="font-semibold text-center">{{ alarmName() }}</span>
      <span class="text-xs text-center text-neutral-300">{{ range() }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthKanbanColumnCardKpiComponent {
  readonly value = input.required<string | number>();
  readonly alarmName = input.required<string>();
  readonly range = input.required<string>();
}
