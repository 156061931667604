<ad-icon
  *ngIf="leadingIcon"
  [glyph]="leadingIcon"
  [color]="iconColor()"
></ad-icon>
<p class="text-p3">
  <ng-content></ng-content>
</p>
<ad-icon
  *ngIf="trailingIcon"
  (click)="trailingClick($event)"
  [glyph]="trailingIcon"
  [color]="iconColor()"
></ad-icon>
