import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';

import {
  compareDateRanges,
  compareDates,
  previousMonth,
  previousWeek,
  previousYear,
} from '../../helpers';

import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModeLabelType } from './calendar';

export type DateRangeType = 'today' | 'week' | 'month' | 'year';

export type DateFiltersType = {
  range?: DateRangeType;
  label?: string;
};

@Component({
  selector: 'ad-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarOrganism),
      multi: true,
    },
  ],
})
export class CalendarOrganism {
  private _date: Date | Date[] = new Date();

  @Input()
  public get date(): Date | Date[] {
    return this._date;
  }
  public set date(value: Date | Date[]) {
    this._date = value;
  }

  @Input() minDate: any;
  @Input() maxDate: any;

  @Input()
  selectionMode?: 'single' | 'range' = 'single';

  @Input()
  selectionModeLabels: SelectionModeLabelType = {
    single: 'Single',
    range: 'Date Range',
  };

  @Input()
  dateRangeFilterSelection?: DateRangeType;

  @Input()
  dateRangeFilters?: DateFiltersType[] = [
    { range: 'today', label: 'Today' },
    { range: 'week', label: 'Last Week' },
    { range: 'month', label: 'Last Month' },
    { range: 'year', label: 'Last Year' },
  ];

  @Output() selectedDate: EventEmitter<any> = new EventEmitter();

  value: any;

  constructor(private fb: FormBuilder) {}

  toggleSelectionMode(mode: 'single' | 'range'): void {
    mode === 'single'
      ? (this.selectionMode = 'single')
      : (this.selectionMode = 'range');
  }

  dateFilter(dateRange?: DateRangeType): void {
    if (dateRange === 'today') {
      this.selectionMode = 'single';
      this.dateRangeFilterSelection = 'today';
      this.date = new Date();
    } else if (dateRange === 'week') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'week';

      this.date = previousWeek();
    } else if (dateRange === 'month') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'month';

      this.date = previousMonth();
    } else if (dateRange === 'year') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'year';

      this.date = previousYear();
    }
  }

  /**
   * Compares the date selected to the current date range selection, and returns whether the filter chip should be active.
   *
   * @param {DateRangeType} dateFilter - the date range currently applied to the calendar.
   * @returns {boolean} - whether the date selected falls within the current date range selection.
   */
  filterSelectionState(dateFilter?: DateRangeType): boolean {
    // check if date is a range or single date
    if (Array.isArray(this.date)) {
      // checks for each filter type
      if (dateFilter === 'week') {
        const week = previousWeek();

        return compareDateRanges(this.date, week);
      }

      if (dateFilter === 'month') {
        const month = previousMonth();
        return compareDateRanges(this.date, month);
      }

      if (dateFilter === 'year') {
        const year = previousYear();
        return compareDateRanges(this.date, year);
      }
    } else if (dateFilter === 'today') {
      // return whether the date selected is today
      return compareDates(new Date(this.date), new Date());
    }
    // If date is not an array and dateFilter is not 'today', return false
    return false;
  }

  handleChangedValue(event: any) {
    this.selectedDate.emit(event);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
}
