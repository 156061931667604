import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import classNames from 'classnames';

import {
  ColorButtonType,
  StyleType,
} from '../../common-ng-design-system.colors';
import { RippleEffectDirective } from '../../directives/ripple/ripple.directive';

import { ButtonKindType, ButtonSizeType, ButtonStateType } from './button.enum';

@Component({
  selector: 'button[ds-button], a[ds-button]', // eslint-disable-line @angular-eslint/component-selector
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [
    {
      directive: RippleEffectDirective,
    },
  ],
})
export class ButtonMolecule {
  @Input()
  kind: ButtonKindType = 'filled';

  @Input()
  color: ColorButtonType = 'primary';

  @Input()
  size: ButtonSizeType = 'xs';

  @Input()
  theme: StyleType = 'light';

  @Input()
  @HostBinding('disabled')
  disabled = false;

  private pressed = false;

  @HostBinding('style.--rippleColor')
  private get rippleColor() {
    const repeatedColors = {
      primary: `var(--color-${this.color}-500)`,
      success: `var(--color-${this.color}-500)`,
      warning: `var(--color-${this.color}-500)`,
      danger: `var(--color-${this.color}-500)`,
      neutral: `var(--color-${this.color}-500)`,
      resolve: `var(--color-${this.color}-700)`,
      secondary: `var(--color-${this.color}-600)`,
    };

    const colorMappings = {
      light: {
        filled: {
          primary: 'var(--color-shades-0)',
          success: 'var(--color-shades-0)',
          warning: 'var(--color-shades-0)',
          danger: 'var(--color-shades-0)',
          neutral: 'var(--color-shades-0)',
          resolve: 'var(--color-shades-0)',
          secondary: 'var(--color-shades-0)',
        },
        tonal: repeatedColors,
        outlined: repeatedColors,
        text: repeatedColors,
      },
      dark: {
        filled: 'var(--color-shades-0)',
        tonal: 'var(--color-shades-0)',
        outlined: 'var(--color-shades-0)',
        text: 'var(--color-shades-0)',
      },
    };

    const colorMapping = colorMappings[this.theme][this.kind];

    if (typeof colorMapping === 'string') {
      return colorMapping;
    } else if (typeof colorMapping === 'object') {
      return colorMapping[this.color];
    }

    return 'transparent';
  }

  @HostBinding('class')
  public get classes() {
    const type: ButtonStateType = `${this.kind}-${this.color}${
      this.disabled ? '-disabled' : ''
    }${this.pressed ? '-pressed' : ''}`;

    const typeStyle =
      this.theme === 'dark' ? `type-dark-${type}` : `type-${type}`;

    return classNames(`ds-button size-${this.size} ${typeStyle}`, {
      'text-caption': this.size === 'xs',
      'text-p3': this.size === 'sm',
      'text-p2': this.size === 'md',
      'text-p1': this.size === 'lg' || this.size === 'xl',
    });
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.pressed = true;
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.pressed = false;
  }
}
