import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const duplicateValidator = (existingValues: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      // The value is empty, which is considered valid.
      return null;
    }

    const duplicateExists = existingValues.includes(control.value);

    if (duplicateExists) {
      // The value is valid or empty
      return { duplicateValues: true };
    } else {
      return null; // Value is not in the valid list
    }
  };
};

export const phoneValidator = (phone: string): boolean => {
  // Regular expression for phone validation
  const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;

  // Check if the input value matches the regex pattern
  return phonePattern.test(phone);
};

export const phoneFormValidator = (control: AbstractControl) => {
  if (
    !control.value ||
    typeof control.value !== 'string' ||
    (typeof control.value === 'string' && control.value.length === 0)
  ) {
    return null; // no value input
  }

  const phoneNumbers = control.value?.split(',');
  const invalidPhones = phoneNumbers.filter((phone: string) => {
    return !phoneValidator(phone);
  }); // filter invalid phones

  if (invalidPhones.length > 0) {
    return { invalidPhones: true }; // invalid phones
  } else {
    return null; // all phones valid
  }
};

export const emailValidator = (email: string): boolean => {
  // Regular expression for email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if the input value matches the regex pattern
  return emailPattern.test(email);
};

export const emailFormValidator = (control: AbstractControl) => {
  if (
    !control.value ||
    typeof control.value !== 'string' ||
    (typeof control.value === 'string' && control.value.length === 0)
  ) {
    return null; // valid email
  }

  const emails = control.value.toString().split(',');
  const invalidEmails = emails.filter((email: string) => {
    return !emailValidator(email.trim());
  }); // filter invalid emails

  if (!invalidEmails.length) {
    return null; // all emails valid
  } else {
    return { invalidEmails: true }; // invalid emails
  }
};

export const exemptionValidator = (control: AbstractControl) => {
  const value = control.value || '';
  return value.length >= 4 ? null : { minLength: true };
};

export const noWhitespaceValidator = (control: AbstractControl) => {
  return (control.value || '').trim().length ? null : { whitespace: true };
};
