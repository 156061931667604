// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EquipmentHealthDiagnosticView { 
    /**
     * The diagnostic auto-incremented primary key
     */
    equipment_diagnostic_id: number;
    /**
     * Local timestamp
     */
    timestamp: string;
    /**
     * Equipment foreign key to the Equipment table
     */
    equipment_id: string;
    /**
     * Equipment type
     */
    equipment_type: string;
    /**
     * The diagnostic name
     */
    name: string;
    /**
     * The diagnostic status
     */
    status: EquipmentHealthDiagnosticView.StatusEnum;
    /**
     * Occurrences of the event in the last 6 months
     */
    occurrences: number;
    /**
     * Trend, percent, of the event in the last 7 days
     */
    trend: number;
    /**
     * Description of the diagnostic
     */
    description: string;
    /**
     * Name of the alarm
     */
    alarm_name: string;
    /**
     * Occurrence count of the `Engine oil level - low mark` alarm
     */
    occurrences_ld: number;
}
export namespace EquipmentHealthDiagnosticView {
    export type StatusEnum = 'todo' | 'ongoing' | 'incharge';
    export const StatusEnum = {
        todo: 'todo' as StatusEnum,
        ongoing: 'ongoing' as StatusEnum,
        incharge: 'incharge' as StatusEnum
    };
}


