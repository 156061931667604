import { TitleCasePipe, registerLocaleData } from '@angular/common';
import {
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeFrCA from '@angular/common/locales/fr-CA';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as fromDesignSystem from '@common/ng-design-system';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CalendarModule } from 'primeng/calendar';
import { ImageModule } from 'primeng/image';
import { SplitterModule } from 'primeng/splitter';
import languaguesImport from '../assets/languages-import';
import { environment } from '../environments/environment';
import * as fromGenerated from './_generated';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as fromAuth from './auth';
import * as fromFeatureFlags from './feature-flags';
import * as fromChatbot from './home/chatbot';
import * as fromSidebar from './home/sidebar';
import * as fromPipes from './pipes';
import * as fromStore from './store';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, languaguesImport);
}

registerLocaleData(localeFrCA);

@NgModule({
  declarations: [AppComponent, fromAuth.SignInPage],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    CalendarModule,
    SplitterModule,
    ImageModule,
    DragulaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    fromGenerated.ApiModule,
    fromDesignSystem.CommonNgDesignSystemModule,
    fromPipes.DateFormatPipe,
    fromSidebar.SidebarComponent,
    fromFeatureFlags.FeatureFlagsDirective,
  ],
  providers: [
    provideState(fromChatbot.chatbotFeature),
    provideStore({
      featureFlagsStore: fromFeatureFlags.featureFlagsReducer,
    }),
    provideEffects([
      fromStore.AppEffects,
      fromChatbot.ConversationEffects,
      fromFeatureFlags.FeatureFlagsEffects,
    ]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
    }),
    TitleCasePipe,
    {
      provide: fromGenerated.BASE_PATH,
      useValue: environment.charlyApi.baseUrl,
    },
    fromGenerated.TmnDashboardService,
    fromGenerated.EquipmentHealthService,
    fromAuth.provideCoreAuthInterceptor(),
    fromAuth.CoreAuthConfigProvider.provideCoreAuthConfigProvider(),
    fromAuth.provideCoreAuthAmplifyProvider(),
    fromFeatureFlags.provideLaunchDarklyClient(),
    fromFeatureFlags.provideLaunchDarklyInitializer(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
