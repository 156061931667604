import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagKey, FeatureFlagsState } from './feature-flags.state';

const selectFeatureFlagsState =
  createFeatureSelector<Readonly<FeatureFlagsState>>('featureFlagsStore');

export const selectFeatureFlag = (featureFlagKey: FeatureFlagKey) =>
  createSelector(
    selectFeatureFlagsState,
    (state) => state.entities[featureFlagKey]?.value || false
  );

export const selectReady = () =>
  createSelector(selectFeatureFlagsState, (state) => state.ready);
