import { Directive, HostListener, Input, ElementRef } from '@angular/core';

/* The code below does the following:
1. Grabs the click position relative ot the button on mousemove.
2. Adds those values as CSS variables through JS DOM methods
3. Calculates the radius of the circle that will ripple within the element.
4. Adds a '.pressed' class on mouse down but waits until both the mouseup occurs AND enough time has elasped for the ripple animation.
6. Set the left and top position of the circle to the position of the click event. */
@Directive({
  selector: '[adRipple]',
  standalone: true,
})
export class RippleEffectDirective {
  @Input() adRippleDisabled = false;
  constructor(private hostElement: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.adRippleDisabled) {
      const button = this.hostElement.nativeElement;

      const { left, top } = button.getBoundingClientRect();
      const clickX = event.pageX - left;
      const clickY = event.pageY - top;

      button.style.setProperty('--rippleLeft', `${clickX}px`);
      button.style.setProperty('--rippleTop', `${clickY}px`);

      button.classList.add('pressed');

      Promise.all([
        new Promise((resolve) =>
          button.addEventListener('mouseup', resolve, { once: true })
        ),
        new Promise((resolve) => setTimeout(resolve, 300)),
      ]).then(() => button.classList.remove('pressed'));
    }
  }
}
