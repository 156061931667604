<form class="card datepicker-container">
  <p-calendar
    [inline]="true"
    name="date"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [selectionMode]="selectionMode"
    (ngModelChange)="handleChangedValue($event)"
    [ngModel]="date"
  >
  </p-calendar>
  <!-- <div class="datepicker-radio-container">
    <ad-radio
      [text]="selectionModeLabels.single"
      name="date-select"
      value="single"
      (click)="toggleSelectionMode('single')"
      [selected]="selectionMode === 'single'"
    ></ad-radio>
    <ad-radio
      [text]="selectionModeLabels.range"
      name="date-select"
      (click)="toggleSelectionMode('range')"
      value="range"
      [selected]="selectionMode === 'range'"
    ></ad-radio>
  </div>

  <div *ngIf="dateRangeFilters?.length" class="datepicker-chip-container">
    <ad-chip
      *ngFor="let dateRange of dateRangeFilters"
      [selected]="filterSelectionState(dateRange?.range)"
      (selectChipEvent)="dateFilter(dateRange?.range)"
      color="primary"
      >{{ dateRange.label }}</ad-chip
    >
  </div> -->
</form>
