// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TmnDashboardTbjDataDto } from './tmnDashboardTbjDataDto';
import { TmnDashboardChartSeriesDataDto } from './tmnDashboardChartSeriesDataDto';


export interface TmnDashboardChartDataDto { 
    tnmTotal: Array<TmnDashboardChartSeriesDataDto>;
    tnmConcasseur: Array<TmnDashboardChartSeriesDataDto>;
    tbj: TmnDashboardTbjDataDto;
}

