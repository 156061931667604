<div class="chart-container">
  <div class="chart-title">
    <span>{{ 'TNM_CHART.TITLE' | translate }}</span>
    <ad-checkbox
      class="mb-3"
      color="danger"
      [selected]="showConcasseur"
      size="md"
      text="{{ 'TNM_CHART.CONCASSEUR' | translate }}"
      (click)="toggleConcasseur()"
      id="showConcasseur"
      name="showConcasseur"
      value="showConcasseur"
    ></ad-checkbox>
  </div>

  <!-- Ugly patch to force Chart.js refresh some drawing artifacts -->
  <div
    class="chart-wrapper"
    [ngStyle]="{ width: forceRefreshCanvas ? '99.9%' : '100%' }"
  >
    <canvas
      class="chart-w"
      id="chartTnm"
      baseChart
      [type]="'line'"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      [plugins]="lineChartPlugins"
    >
    </canvas>
  </div>
</div>
