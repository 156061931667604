export enum IconAtomWeatherEnum {
  'cloudy' = 'cloudy',
  'mostly-cloudy' = 'mostly-cloudy',
  'partly-sunny' = 'partly-sunny',
  'rain-snow' = 'rain-snow',
  'raining' = 'raining',
  'snow' = 'snow',
  'sunny' = 'sunny',
}

export enum IconAtomGlyphEnum {
  'alert' = 'alert',
  'analytics' = 'analytics',
  'bus_add' = 'bus_add',
  'bus-filled' = 'bus-filled',
  'bus' = 'bus',
  'calendar' = 'calendar',
  'camion' = 'camion',
  'caret_down' = 'caret_down',
  'caret_left' = 'caret_left',
  'caret_right' = 'caret_right',
  'caret_up' = 'caret_up',
  'check' = 'check',
  'checkbox-check' = 'checkbox-check',
  'chevron_down' = 'chevron_down',
  'chevron_left' = 'chevron_left',
  'chevron_right' = 'chevron_right',
  'chevron_up' = 'chevron_up',
  'circuit' = 'circuit',
  'close' = 'close',
  'contract' = 'contract',
  'data_transfer' = 'data_transfer',
  'download' = 'download',
  'email-stroke' = 'email-stroke',
  'email' = 'email',
  'file' = 'file',
  'fuel' = 'fuel',
  'fullscreen-exit' = 'fullscreen-exit',
  'fullscreen' = 'fullscreen',
  'graphic_eq' = 'graphic_eq',
  'grid_view' = 'grid_view',
  'handyman' = 'handyman',
  'haul-truck' = 'haul-truck',
  'cardiology' = 'cardiology',
  'history' = 'history',
  'info-i' = 'info-i',
  'info' = 'info',
  'layout' = 'layout',
  'location-empty' = 'location-empty',
  'location-filled' = 'location-filled',
  'location' = 'location',
  'logout' = 'logout',
  'maintenance-filled' = 'maintenance-filled',
  'map_options' = 'map_options',
  'menu' = 'menu',
  'minus' = 'minus',
  'more-horizontal' = 'more-horizontal',
  'more-vertical' = 'more-vertical',
  'navigation-filled' = 'navigation-filled',
  'notification' = 'notification',
  'oil' = 'oil',
  'open_new' = 'open_new',
  'page' = 'page',
  'password-hide' = 'password-hide',
  'password-show' = 'password-show',
  'phone' = 'phone',
  'play_realtime' = 'play_realtime',
  'plus' = 'plus',
  'profile-add' = 'profile-add',
  'profile-filled' = 'profile-filled',
  'profile' = 'profile',
  'rds-filled' = 'rds-filled',
  'rds' = 'rds',
  'reset' = 'reset',
  'resources' = 'resources',
  'restart' = 'restart',
  'route' = 'route',
  'schedule' = 'schedule',
  'search' = 'search',
  'seat' = 'seat',
  'send' = 'send',
  'settings' = 'settings',
  'share' = 'share',
  'support' = 'support',
  'target' = 'target',
  'time' = 'time',
  'trending-down' = 'trending-down',
  'trending-flat' = 'trending-flat',
  'trending-up' = 'trending-up',
  'unavailable-filled' = 'unavailable-filled',
  'upload-filled' = 'upload-filled',
  'users-filled' = 'users-filled',
  'stars-outline' = 'stars-outline',
  'oil-empty' = 'oil-empty',
}

export type IconAtomWeatherType = keyof typeof IconAtomWeatherEnum;

export type IconAtomGlyphType = keyof typeof IconAtomGlyphEnum;

export type IconAtomType =
  | keyof typeof IconAtomGlyphEnum
  | keyof typeof IconAtomWeatherEnum;
