import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'chTimestamp',
  standalone: true,
})
export class TimestampPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(timestamp: string): string {
    const dateTime = DateTime.fromJSDate(new Date(timestamp))
      .setLocale(this.translateService.currentLang || 'fr_CA')
      .setZone('America/Toronto');

    return dateTime.toFormat("d LLLL 'à' h'h'mm a"); //=> 'April 20'
  }
}
