<div
  class="ch-datepicker"
  [ngClass]="{
    'ch-datepicker--mobile': displayType === 'mobile',
    'ch-datepicker--has-dropdown-open': datepickerIsShown
  }"
>
  <div class="ch-header-date" (click)="dateClick()">
    <span class="ch-header-date-text text-wrapper-p2">{{
      date | chDateFormat
    }}</span>
    <ad-icon
      class="ch-header-date-icon"
      [glyph]="calendarIcon"
      [color]="iconColor()"
      [fillColor]="iconFillColor()"
    ></ad-icon>
  </div>

  <div
    class="ch-datepicker-wrapper"
    [ngClass]="{
      'ch-datepicker-wrapper--open': datepickerIsShown,
      'ch-datepicker-wrapper--close': !datepickerIsShown
    }"
  >
    <div class="ch-datepicker-wrapper__inner">
      <ad-calendar
        [date]="date"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (selectedDate)="onDateChanged($event)"
      ></ad-calendar>
    </div>
  </div>
</div>

<div
  class="ch-backdrop"
  (click)="closeDropdown()"
  [ngClass]="{
    'ch-backdrop--open': datepickerIsShown,
    'ch-backdrop--close': !datepickerIsShown
  }"
></div>
