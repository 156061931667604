import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';
import { TmnDashboardChartData } from '../models';
import { TmnDashboardActions } from './tmn-dashboard.actions';

export interface TmnDashboardState {
  ui: {
    datePicker: {
      selectedDate: Date;
      minDate: Date;
      maxDate: Date;
    };
  };
  data: fromGenerated.TmnDashboardChartDataDto;
  loaded: boolean;
  error: string | null;
}

const initialTmnDashboardState: TmnDashboardState = {
  ui: {
    datePicker: {
      selectedDate: new Date('2023-10-01T00:00:00.000-04:00'),
      minDate: new Date('2022-01-01T00:00:00.000-05:00'),
      maxDate: new Date('2023-12-05T00:00:00.000-05:00'),
    },
  },
  data: new TmnDashboardChartData(),
  loaded: false,
  error: null,
};

export const tmnDashboardFeature = createFeature({
  name: 'tmn-dashboard',
  reducer: createReducer(
    initialTmnDashboardState,
    on(TmnDashboardActions.loadTmnDashboardDataRequested, (state) => ({
      ...state,
      loaded: false,
      error: null,
    })),
    on(TmnDashboardActions.loadTmnDashboardDataSuccess, (state, { data }) => ({
      ...state,
      data,
      loaded: true,
      error: null,
    })),
    on(TmnDashboardActions.loadTmnDashboardDataFailure, (state, { error }) => ({
      ...state,
      loaded: true,
      error,
    })),
    on(TmnDashboardActions.setDate, (state, { date }) => ({
      ...state,
      ui: {
        ...state.ui,
        datePicker: {
          ...state.ui.datePicker,
          selectedDate: date,
        },
      },
    }))
  ),
  extraSelectors: ({ selectUi }) => ({
    selectDatePicker: createSelector(selectUi, (state) => state.datePicker),
  }),
});
