// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The current running state of the execution
 */
export type MegRunningStateEnum = 'none' | 'initializing' | 'running' | 'paused' | 'waiting' | 'completed' | 'cancelled';

export const MegRunningStateEnum = {
    none: 'none' as MegRunningStateEnum,
    initializing: 'initializing' as MegRunningStateEnum,
    running: 'running' as MegRunningStateEnum,
    paused: 'paused' as MegRunningStateEnum,
    waiting: 'waiting' as MegRunningStateEnum,
    completed: 'completed' as MegRunningStateEnum,
    cancelled: 'cancelled' as MegRunningStateEnum
};

