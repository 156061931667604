/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  standalone: true,
  selector: 'ch-chatbot-input',
  template: `
    <div class="ch-input-container">
      <textarea
        maxlength="300"
        id="ch_ask_question"
        #chatInput
        [(ngModel)]="value"
        (input)="onInput()"
        (keydown)="onKeydown($event)"
        (change)="handleChangedValue($event)"
        class="ch-input-box text-p3"
        placeholder="Écrire votre message..."
      ></textarea>
    </div>
  `,
  styleUrls: ['./chatbot-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChatbotInputComponent),
      multi: true,
    },
  ],
  imports: [FormsModule],
})
export class ChatbotInputComponent implements ControlValueAccessor {
  @ViewChild('chatInput', { static: false }) chatInput: ElementRef | undefined;

  @Output() newQuestion: EventEmitter<string> = new EventEmitter();

  @Input() isDisabled = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  value = '';

  constructor() {}

  onKeydown(keyDown: KeyboardEvent) {
    if (keyDown.key === 'Enter') {
      keyDown.preventDefault();

      if (this.isDisabled) {
        return;
      }

      this.newQuestion.emit(this.value);

      this.value = '';

      this.updateHeight();
    }
  }

  handleChangedValue(event: any) {
    this.value = event.target.value;
    const newValue = this.chatInput?.nativeElement.value;

    if (newValue.length > 2) {
      this.onChange(newValue);
    }
  }

  writeValue(value: any) {
    this.value = value;
    this.updateHeight();
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onInput(): void {
    this.onChange(this.value);
    this.updateHeight();
  }

  onFocus() {}

  updateHeight() {
    const textarea = this.chatInput?.nativeElement as HTMLTextAreaElement;
    if (!textarea) {
      return;
    }

    textarea.style.height = `${textarea.scrollHeight}px`;

    if (textarea.scrollHeight === 0 || textarea.value === '') {
      textarea.style.height = '3.9rem';
    }
  }
}
