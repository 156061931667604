import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import * as fromGenerated from '../_generated';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class CharlyGptService {
  private readonly headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  private apiAiUrl = '';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private chatbotService: fromGenerated.ChatbotService,
    private translateService: TranslateService
  ) {
    const config = this.configService.getConfig();

    this.apiAiUrl = config.charlyGpt.baseUrl;
  }

  createNewConversation(
    conversationType: fromGenerated.ChatbotConversationType
  ): Observable<fromGenerated.ChatbotConversationDto> {
    return this.chatbotService
      .chatbotControllerCreateChatbotConversation(conversationType)
      .pipe(
        map((conversation) => {
          return {
            ...conversation,
            exchanges: this.createPresentationExchange(),
          };
        })
      );
  }

  getCurrentConversation(
    conversationType: fromGenerated.ChatbotConversationType
  ): Observable<fromGenerated.ChatbotConversationDto> {
    return this.chatbotService
      .chatbotControllerGetChatbotConversation(conversationType)
      .pipe(
        map((conversation) => {
          return {
            ...conversation,
            exchanges: [
              ...this.createPresentationExchange(),
              ...conversation.exchanges,
            ],
          };
        })
      );
  }

  saveConversation(
    conversation: Readonly<fromGenerated.ChatbotConversationDto>,
    exchanges: fromGenerated.ChatbotConversationExchangeDto[]
  ) {
    const toSave = {
      ...conversation,
      exchanges,
    };

    return this.chatbotService.chatbotControllerUpdateChatbotConversation(
      toSave
    );
  }

  createNewExchange(
    question: string,
    contextDate: Date
  ): Observable<fromGenerated.ChatbotConversationExchangeDto> {
    return of({
      id: uuidv4(),
      type: 'waiting',
      ai: {
        text: '',
        timestamp: '',
      },
      human: {
        text: question,
        timestamp: contextDate.toISOString(),
      },
    });
  }

  /**
   * Returns a new instance of the current conversation with the AI answer.
   * @param conversationType
   * @param currentExchange
   * @param allExchanges
   * @returns
   */
  getAiAnswer(
    conversationType: fromGenerated.ChatbotConversationType,
    currentExchange: Readonly<fromGenerated.ChatbotConversationExchangeDto>,
    allExchanges: Readonly<fromGenerated.ChatbotConversationExchangeDto[]>
  ): Observable<fromGenerated.ChatbotConversationExchangeDto> {
    const data = {
      command: 'question',
      question: currentExchange.human?.text,
      chatbotId: conversationType,
      contextDate: currentExchange.human?.timestamp,
      conversation: { exchanges: allExchanges },
    };
    return this.http
      .post<any>(this.apiAiUrl, data, {
        headers: this.headers,
      })
      .pipe(
        map((response: any) => {
          let aiResponse = response['response'];
          const aiTimestamp = response['responseTimestamp'];
          const requestTimestamp = response['requestTimestamp'];

          if (!aiResponse) {
            aiResponse = {
              text: this.translateService.instant('CHATBOT.ERROR_MESSAGE'),
            };
          }

          return {
            id: currentExchange.id,
            type: 'normal',
            ai: {
              text: aiResponse.text,
              timestamp: aiTimestamp,
              attachments: aiResponse.attachments,
            },
            human: {
              ...currentExchange.human,
              timestamp: requestTimestamp,
            },
          } as fromGenerated.ChatbotConversationExchangeDto;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('getAiAnswer error', error);

          const message = this.translateService.instant(
            'CHATBOT.EXCEPTION_MESSAGE'
          );
          return of({
            id: currentExchange.id,
            type: 'error',
            ai: {
              text: message,
              timestamp: new Date().toISOString(),
            },
            human: {
              ...currentExchange.human,
            },
          } as fromGenerated.ChatbotConversationExchangeDto);
        })
      );
  }

  getAiVersion(): Observable<any> {
    return this.http
      .post<any>(
        this.apiAiUrl,
        { command: 'version' },
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((res: any) => res['version']),
        catchError(this.handleError)
      );
  }

  private createPresentationExchange(): fromGenerated.ChatbotConversationExchangeDto[] {
    return [
      {
        type: 'presentation',
        id: uuidv4(),
        ai: {
          text: this.translateService.instant('CHATBOT.WELCOME'),
          timestamp: new Date().toISOString(),
        },
      },
      {
        type: 'presentation',
        id: uuidv4(),
        ai: {
          text: this.translateService.instant('CHATBOT.NEED_HELP'),
          timestamp: new Date().toISOString(),
        },
      },
    ];
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return of('-'); // throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
