import { Inject, Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { selectRequestStatus, updateRequestStatus } from '@ngneat/elf-requests';
import { Observable } from 'rxjs';
import {
  DateFormat,
  Request,
  StoreProps,
  providerToken,
  providerType,
} from './store.provider';

@Injectable({
  providedIn: 'root',
})
export class StoreRepository {
  constructor(
    @Inject(providerToken)
    public readonly store: providerType
  ) {}

  public get storeValue(): StoreProps {
    return this.store.getValue();
  }

  public get user$() {
    return this.store.pipe(select(({ user }) => user));
  }

  public get isLoading$() {
    return this.store.pipe(select(({ isLoading }) => isLoading));
  }

  public get dateFormat$(): Observable<DateFormat | undefined> {
    return this.store.pipe(select(({ dateFormat }) => dateFormat));
  }

  public selectRequestStatus(request: Request): Observable<{
    value: 'idle' | 'pending' | 'success' | 'error';
    error?: Error;
  }> {
    return this.store.pipe<any>(selectRequestStatus(request));
  }

  updateRequestStatus(
    key: Request,
    value: Parameters<typeof updateRequestStatus>[1] | 'error',
    error?: Parameters<typeof updateRequestStatus>[2]
  ) {
    return this.store.update(
      updateRequestStatus(key as Request, value as any, error)
    );
  }

  public reset() {
    this.store.reset();
  }
}
