import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Amplify } from 'aws-amplify';

import { AuthProvider, CoreAuthErrorType } from '../interfaces';

import { AuthError, fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage, sessionStorage } from 'aws-amplify/utils';
import { CoreAuthConfigProvider } from '.';
import { CoreAuthError } from '../interfaces';
import { CoreAuthRepository } from '../repositories';

@Injectable()
export class AmplifyProvider implements AuthProvider {
  constructor(
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType,
    private readonly authRepository: CoreAuthRepository
  ) {
    Amplify.configure({
      ...config.auth,
      aws_cognito_signup_attributes: ['EMAIL', 'NAME'],
      aws_cognito_mfa_configuration: 'OFF',
    } as any);

    this.setStorage();
  }

  async signIn(username: string, password: string) {
    try {
      this.setStorage();

      return await signIn({ username, password });
    } catch (error: any) {
      if (
        error instanceof AuthError &&
        error.name === 'UserAlreadyAuthenticatedException'
      ) {
        return { isSignedIn: true };
      }

      if (error instanceof AuthError && !['Unknown'].includes(error.name)) {
        const ae = error as AuthError;

        throw new CoreAuthError({
          name: CoreAuthErrorType.CORE_AUTH_SIGNIN_ERROR,
          message: 'Invalid email or password.',
          cause: ae,
        });
      }

      throw new CoreAuthError({
        name: CoreAuthErrorType.CORE_AUTH_UNKNOWN_ERROR,
        message: error.message,
        cause: error,
      });
    }
  }

  async signOut(): Promise<void> {
    try {
      return await signOut();
    } catch (error) {
      console.error('signOut', error);
      throw error;
    }
  }

  async currentSessionIdToken(): Promise<string> {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      if (idToken === undefined) {
        return '';
      }

      return idToken.toString();
    } catch (error) {
      console.error('currentSessionIdToken', error);
      throw error;
    }
  }

  async isSignedIn(): Promise<boolean> {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    if (idToken === undefined) {
      return true;
    }
    return false;
  }

  private setStorage() {
    if (this.authRepository.store$.value.isRemembered) {
      cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
    } else {
      cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
    }
  }
}

export type providerType = typeof AmplifyProvider;

export const providerToken = new InjectionToken<providerType>(
  'CORE_AUTH_AMPLIFY'
);

export const provideCoreAuthAmplifyProvider = () => ({
  provide: providerToken,
  useClass: AmplifyProvider,
});
