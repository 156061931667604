// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EquipmentHealthDiagnosticStatusUpdateDto { 
    /**
     * The diagnostic status
     */
    status: EquipmentHealthDiagnosticStatusUpdateDto.StatusEnum;
}
export namespace EquipmentHealthDiagnosticStatusUpdateDto {
    export type StatusEnum = 'todo' | 'ongoing' | 'incharge';
    export const StatusEnum = {
        todo: 'todo' as StatusEnum,
        ongoing: 'ongoing' as StatusEnum,
        incharge: 'incharge' as StatusEnum
    };
}


