import { CommonModule, NgFor } from '@angular/common';
import { Component, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ChatbotMessageComponent } from '../chatbot-message/chatbot-message.component';
import { ChatbotState, chatbotFeature } from '../store';

@Component({
  standalone: true,
  selector: 'ch-chatbot-message-list',
  template: `
    <div class="chatbot-message-list-content">
      <ch-chatbot-message
        *ngFor="let msg of currentExchanges$ | async; trackBy: identify"
        id="message-{{ msg.id }}"
        [message]="msg.text"
        [attachments]="msg.attachments"
        [type]="msg.type"
        [loading]="!!msg?.isWaiting"
      ></ch-chatbot-message>
    </div>
  `,
  styleUrls: ['./chatbot-message-list.component.scss'],
  imports: [NgFor, ChatbotMessageComponent, CommonModule],
})
export class ChatbotMessageListComponent {
  private readonly store = inject<Store<ChatbotState>>(Store);

  flags$ = this.store.select(chatbotFeature.selectFlags);

  currentExchanges$ = this.store.select(
    chatbotFeature.selectChatbotMessageModelList
  );

  constructor(private ref: ElementRef) {
    this.currentExchanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.scrollNewItemIntoView();
    });
  }

  // This is needed to prenvent the redrawing of the whole
  // list when a new message is added
  identify(index: number, item: any) {
    return item.id;
  }

  private scrollNewItemIntoView() {
    setTimeout(() => {
      const elements = this.ref.nativeElement.querySelectorAll(
        '.chatbot-message-list-content'
      );
      const contentDiv = elements[elements.length - 1] as HTMLElement;
      contentDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 100);
  }
}
