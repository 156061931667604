import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { filter, tap } from 'rxjs';
import * as fromChatbot from '../chatbot';
import {
  TmnDashboardActions,
  TmnDashboardState,
  tmnDashboardFeature,
} from './store';
import { TmnDashboardChartComponent } from './tmn-dashboard-chart/tmn-dashboard-chart.component';
import { TmnDashboardDatePickerComponent } from './tmn-dashboard-datepicker';

@Component({
  standalone: true,
  selector: 'ch-tmn-dashboard',
  template: `
    <div class="container-header">
      <span class="ch-dashboard-title text-h6--semibold">{{
        'TNM_DASHBOARD.TITLE' | translate
      }}</span>
      <ch-tmn-dashboard-datepicker
        *ngIf="datePicker$ | async as datePicker"
        class="ch-dashboard-datepicker"
        calendarIcon="chevron_down"
        titleDateFormat="d MMMM yyyy"
        displayType="desktop"
        [date]="datePicker.selectedDate"
        [minDate]="datePicker.minDate"
        [maxDate]="datePicker.maxDate"
        (closed)="onDateChanged($event)"
      ></ch-tmn-dashboard-datepicker>
    </div>

    <div class="container-tmn-dashboard-rows">
      <div class="">
        <div style="position: relative; width: 100%">
          <ch-tmn-dashboard-chart
            [data]="data$ | async"
            displayType="desktop"
          ></ch-tmn-dashboard-chart>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-areas: 'header' 'content';

        .container-header {
          grid-area: header;
          position: relative;
          display: inline-flex;
          padding: 1rem 2.5rem;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgb(var(--color-neutral-800));
          height: 5rem;

          .ch-dashboard-title {
            display: inline;
          }

          .ch-dashboard-datepicker {
            flex-grow: unset;
          }
        }

        .container-tmn-dashboard-rows {
          grid-area: content;
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          position: relative;
          margin: 0px;
          grid-template: 1fr 1fr/ 1fr;
          height: 100%;
          width: 100%;
        }
      }
    `,
  ],
  imports: [
    AsyncPipe,
    TmnDashboardChartComponent,
    TmnDashboardDatePickerComponent,
    TranslateModule,
    NgIf,
  ],
})
// Dashboard Tonnes Métrique Naturelles
export class TmnDashboardComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly chatbotStore =
    inject<Store<fromChatbot.ChatbotState>>(Store);
  private readonly store = inject<Store<TmnDashboardState>>(Store);

  datePicker$ = this.store.select(tmnDashboardFeature.selectDatePicker);
  data$ = this.store.select(tmnDashboardFeature.selectData);
  isChatBotOpen$ = this.chatbotStore.select(
    fromChatbot.chatbotFeature.selectIsChatOpen
  );

  constructor() {
    this.route.fragment
      .pipe(
        takeUntilDestroyed(),
        filter<any | string | null>((fragment) => !!fragment),
        tap<string>((fragment) => {
          this.store.dispatch(
            TmnDashboardActions.setDate({
              date: DateTime.fromISO(fragment, {
                locale: 'fr',
                zone: 'America/Toronto',
              }).toJSDate(),
            })
          );
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(TmnDashboardActions.loadTmnDashboardDataRequested());
    this.store.dispatch(fromChatbot.ChatbotActions.enableChat());

    this.store.dispatch(
      fromChatbot.ChatbotActions.setConversationType({
        conversationType: 'equipment-status',
      })
    );
  }

  onDateChanged(event: Date) {
    const fragment = `${event.getFullYear()}-${String(
      event.getMonth() + 1
    ).padStart(2, '0')}-${String(event.getDate()).padStart(2, '0')}`;

    return this.router.navigate(['/home', 'tmn-dashboard'], {
      fragment,
    });
  }
}
