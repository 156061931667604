import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { interval, tap } from 'rxjs';
import * as fromChatbot from '../chatbot';
import { EquipmentHealthHeaderComponent } from './equipment-health-header.component';
import { EquipmentHealthKanbanComponent } from './equipment-health-kanban.component';
import { EquipmentHealthSubheaderComponent } from './equipment-health-subheader.component';
import { EquipmentHealthActions, EquipmentHealthState } from './store';

@Component({
  selector: 'ch-equipment-health',
  standalone: true,
  imports: [
    EquipmentHealthHeaderComponent,
    EquipmentHealthSubheaderComponent,
    EquipmentHealthKanbanComponent,
  ],
  template: `
    <div
      class="grid grid-rows-[80px_80px_calc(100vh-160px-24px)] *:place-content-center *:h-full *:px-10"
    >
      <ch-equipment-health-header></ch-equipment-health-header>
      <ch-equipment-health-subheader></ch-equipment-health-subheader>
      <ch-equipment-health-kanban></ch-equipment-health-kanban>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthComponent implements OnInit {
  private readonly chatbotStore =
    inject<Store<fromChatbot.ChatbotState>>(Store);
  private readonly store = inject<Store<EquipmentHealthState>>(Store);
  private readonly translateService = inject(TranslateService);

  constructor() {
    this.keepDateInSync();
  }

  ngOnInit(): void {
    this.store.dispatch(
      fromChatbot.ChatbotActions.setConversationType({
        conversationType: 'fiabiliste',
      })
    );

    this.store.dispatch(EquipmentHealthActions.init());
  }

  private keepDateInSync(): void {
    interval(60000)
      .pipe(
        takeUntilDestroyed(),
        tap(() =>
          this.store.dispatch(
            EquipmentHealthActions.setDate({
              date: DateTime.now(),
            })
          )
        )
      )
      .subscribe();
  }
}
