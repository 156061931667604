import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'ch-meg-header',
  standalone: true,
  imports: [TranslateModule],
  styles: [
    `
      :host {
        @apply border-b border-neutral-800;
      }
    `,
  ],
  template: `
    <div class="flex justify-between items-center">
      <span class="font-heading text-2xl">{{ 'MEG.TITLE' | translate }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegHeaderComponent {}
