import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import * as fromFeatureFlags from '.';

@Directive({
  standalone: true,
  selector: '[chFeatureFlag]',
})
export class FeatureFlagsDirective implements OnInit, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  private readonly storeFeatureFlag = inject(
    Store<fromFeatureFlags.FeatureFlagsState>
  );

  @Input()
  chFeatureFlag?: fromFeatureFlags.FeatureFlagKey;
  private subscription?: Subscription;

  ngOnInit(): void {
    if (!this.chFeatureFlag) {
      return console.error('chFeatureFlag is required');
    }

    this.subscription = this.storeFeatureFlag
      .select(fromFeatureFlags.selectFeatureFlag(this.chFeatureFlag))
      .pipe(
        tap((featureFlagEnabled) => {
          featureFlagEnabled
            ? (this.elementRef.nativeElement.style.display = '')
            : (this.elementRef.nativeElement.style.display = 'none');
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
