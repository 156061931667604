import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import { TmnDashboardChartData, TmnDashboardTbjData } from '../models';
import { TmnDashboardActions } from './tmn-dashboard.actions';
import {
  TmnDashboardState,
  tmnDashboardFeature,
} from './tmn-dashboard.reducer';

@Injectable()
export class TmnDashboardEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<TmnDashboardState>>(Store);
  private readonly dashboardService = inject(fromGenerated.TmnDashboardService);

  loadDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TmnDashboardActions.loadTmnDashboardDataRequested),
      concatLatestFrom(() =>
        this.store.select(tmnDashboardFeature.selectDatePicker)
      ),
      exhaustMap(([, datePicker]) =>
        this.dashboardService.tmnDashboardControllerGetDashboardData({
          date: datePicker.selectedDate.toISOString(),
        })
      ),
      map((data) => {
        if (!data) {
          data = new TmnDashboardChartData();
        } else if (!data.tbj) {
          data.tbj = new TmnDashboardTbjData();
        }

        return data;
      }),
      exhaustMap((data) =>
        of(TmnDashboardActions.loadTmnDashboardDataSuccess({ data }))
      ),
      catchError((error) => {
        console.error('Error', error);
        return of(TmnDashboardActions.loadTmnDashboardDataFailure({ error }));
      })
    )
  );

  loadDashboardDataAfterDateChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TmnDashboardActions.setDate),
      exhaustMap(() => of(TmnDashboardActions.loadTmnDashboardDataRequested()))
    )
  );
}
