import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAuth from '../auth';
import * as fromFeatureFlags from '../feature-flags';
import { NavigateService } from './navigate.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private readonly coreAuthService = inject(fromAuth.CoreAuthService);
  private readonly storeRepository = inject(fromAuth.AuthStore.StoreRepository);
  private readonly navigateService = inject(NavigateService);
  private readonly featureFlagStore = inject(
    Store<fromFeatureFlags.FeatureFlagsState>
  );

  async goToHome() {
    this.navigateService.navigateTo('/home');
  }

  async signOut() {
    await this.coreAuthService.signOut();
    this.storeRepository.reset();
    this.featureFlagStore.dispatch(
      fromFeatureFlags.FeatureFlagsActions.identifyReset()
    );
    this.navigateService.navigateTo('/auth');
  }
}
