import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';

export const TmnDashboardActions = createActionGroup({
  source: 'TmnDashboard Actions',
  events: {
    'Load TmnDashboard Data Requested': emptyProps(),
    'Load TmnDashboard Data Success': props<{
      data: fromGenerated.TmnDashboardChartDataDto;
    }>(),
    'Load TmnDashboard Data Failure': props<{ error: any }>(),
    'Set Date': props<{ date: Date }>(),
  },
});
