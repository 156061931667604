import { formatDate } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import * as fromAuth from '../../auth';

@Pipe({
  name: 'chDateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  private storeRepo = inject(fromAuth.AuthStore.StoreRepository);

  dateFormat: fromAuth.AuthStore.DateFormat = {
    dateFormat: 'mediumDate',
    timezone: 'America/Toronto',
    locale: 'fr_CA',
  };

  constructor() {
    this.storeRepo.dateFormat$
      .pipe(
        takeUntilDestroyed(),
        tap((dateFormat) => {
          if (dateFormat) {
            this.dateFormat = dateFormat;
          }
        })
      )
      .subscribe();
  }

  transform(
    value: string | number | Date,
    forceFormat: string | null = null
  ): string {
    const date = new Date(value);
    return formatDate(
      date,
      forceFormat ?? this.dateFormat.dateFormat,
      this.dateFormat.locale,
      this.dateFormat.timezone
    );
  }
}
