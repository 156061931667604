import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, filter, map, mergeMap, of } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  baseUrl = '';
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = this.configService.getConfig().charlyTts.baseUrl;
  }

  speakNow(input: string | undefined) {
    const canSpeak: boolean =
      input !== undefined && input.length > 0 && this.baseUrl !== '';

    const data = {
      text: input,
      voice: 'Gabrielle',
      language: 'fr-CA',
    };

    // If the input is empty or the base URL is not set, we return an observable of null
    return of(canSpeak).pipe(
      filter((speak) => speak),
      mergeMap(() => this.speak(data)),
      map((result: any) => this.playAudio(result.url))
    );
  }

  private speak(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(
      this.configService.getConfig().charlyTts.baseUrl,
      data,
      {
        headers,
      }
    );
  }

  private playAudio(url: string) {
    const audio = new Audio();
    audio.src = url;
    audio.load();
    audio.play();
  }
}
