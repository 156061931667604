import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  of,
  skipWhile,
  switchMap,
  take,
} from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromServices from '../../../services';
import { EquipmentHealthActions } from './equipment-health.actions';

@Injectable()
export class EquipmentHealthEffects {
  private readonly actions$ = inject(Actions);
  private readonly wsService = inject(fromServices.WebsocketService);
  private readonly equipmentHealthService = inject(
    fromGenerated.EquipmentHealthService
  );

  // On init, subscribe to the websocket topic but wait until the connection is established
  wsSubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentHealthActions.init),
      switchMap(() =>
        this.wsService.connectionStatus$.pipe(
          skipWhile((connected) => !connected),
          take(1)
        )
      ),
      exhaustMap(() => {
        return this.wsService.websocketSubscribe('equipment_diagnostic').pipe(
          map((message: fromServices.WsTopicData) => {
            return EquipmentHealthActions.upsertDiagnostic({
              diagnostic: message.data,
            });
          })
        );
      })
    )
  );

  // Subscribe to the MEG topic to get the simulation date
  wsSubscribeMeg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentHealthActions.init),
      switchMap(() =>
        this.wsService.connectionStatus$.pipe(
          skipWhile((connected) => !connected),
          take(1)
        )
      ),
      exhaustMap(() => {
        return this.wsService.websocketSubscribe('meg').pipe(
          map((message: fromServices.WsTopicData) =>
            EquipmentHealthActions.updateMegSimulatedDate({
              execution: message.data,
            })
          )
        );
      })
    )
  );

  onInitLoadDiagnostics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentHealthActions.init),
      map(() => EquipmentHealthActions.loadDiagnostics())
    )
  );

  onLoadDiagnostics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentHealthActions.loadDiagnostics),
      switchMap(() =>
        this.equipmentHealthService
          .equipmentHealthControllerGetEquipmentHealthDiagnostics()
          .pipe(
            map((diagnostics) =>
              EquipmentHealthActions.loadDiagnosticsSuccess(diagnostics)
            ),
            catchError((error) =>
              of(EquipmentHealthActions.loadDiagnosticsFailure({ error }))
            )
          )
      )
    )
  );

  onUpdateDiagnosticStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentHealthActions.updateDiagnosticStatus),
      switchMap(({ id, changes }) =>
        this.equipmentHealthService
          .equipmentHealthControllerUpdateEquipmentHealthDiagnosticStatus(id, {
            status: changes.status,
          })
          .pipe(
            map(() => EquipmentHealthActions.updateDiagnosticStatusSuccess()),
            catchError((error) =>
              of(
                EquipmentHealthActions.updateDiagnosticStatusFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );
}
