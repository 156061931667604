import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import * as fromDesignSystem from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as classNames from 'classnames';
import { ChatbotFabComponent } from '../chatbot-fab.component';
import { ChatbotInputComponent } from '../chatbot-input/chatbot-input.component';
import { ChatbotMessageListComponent } from '../chatbot-message-list/chatbot-message-list.component';
import { ChatbotMessageComponent } from '../chatbot-message/chatbot-message.component';
import {
  ChatbotActions,
  ChatbotConversationsApiActions,
  ChatbotState,
  chatbotFeature,
} from '../store';

@Component({
  standalone: true,
  selector: 'ch-chatbot-win',
  template: `
    <form class="chatbot-container" [formGroup]="chatForm">
      <div class="chatbot-header">
        <div class="chatbot-header-main">
          <img class="img" src="assets/img/glyph.svg" />
          <div class="info">
            <ch-chatbot-fab></ch-chatbot-fab>

            <div class="info-2">
              <div class="text-wrapper text-p2--semibold">
                {{ 'NIC' | translate }}
              </div>
              <div class="statut">
                <div class="ellipse"></div>
                <div class="text-caption">
                  {{ 'CHATBOT.TITLE' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chatbot-header-right">
          <ad-tooltip
            [text]="'CHATBOT.RESET' | translate"
            [position]="'bottom'"
          >
            <ad-icon
              class="chatbot-action-icon"
              glyph="restart"
              fillColor="neutral-500"
              hoverFillColor="shades-0"
              (click)="clearConversation()"
            ></ad-icon>
          </ad-tooltip>
          <ad-icon
            class="chatbot-action-icon"
            glyph="close"
            fillColor="neutral-500"
            hoverFillColor="shades-0"
            (click)="closeChatbot()"
          ></ad-icon>
        </div>
      </div>

      <ch-chatbot-message-list></ch-chatbot-message-list>

      <form [formGroup]="chatForm" class="prompt-box-container">
        <ch-chatbot-input
          formControlName="charlyPrompt"
          [isDisabled]="flags.isWaiting"
          (newQuestion)="askChatbot()"
        ></ch-chatbot-input>
        <div class="ch-prompt-button-panel">
          <ad-icon
            glyph="graphic_eq"
            [fillColor]="audioIconColor"
            hoverFillColor="shades-0"
            (click)="toggleAudio()"
          ></ad-icon>

          <ad-icon
            [ngClass]="{ 'ch-submit-disabled': flags.isWaiting }"
            glyph="send"
            [fillColor]="sendIconColor"
            [hoverFillColor]="sendIconHoverColor"
            (click)="askChatbot()"
          ></ad-icon>
        </div>
      </form>
      <div class="mobile-spacer"></div>
    </form>
  `,
  styleUrls: ['./chatbot-win.component.scss'],
  imports: [
    fromDesignSystem.CommonNgDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    TranslateModule,
    ChatbotMessageListComponent,
    ChatbotMessageComponent,
    ChatbotInputComponent,
    ChatbotFabComponent,
  ],
})
export class ChatbotWinComponent implements AfterViewInit {
  private readonly store = inject<Store<ChatbotState>>(Store);

  _contextDate: Date = new Date();
  @Input()
  set contextDate(value: Date) {
    this._contextDate = value;
  }

  flags$ = this.store.select(chatbotFeature.selectFlags);
  flags = { isWaiting: false };

  transcription$ = this.store.select(chatbotFeature.selectStt);

  chatForm: FormGroup;
  currentTranscription = '';

  sendIconColor: keyof typeof fromDesignSystem.colors = 'neutral-500';
  sendIconHoverColor: keyof typeof fromDesignSystem.colors = 'neutral-500';
  audioAwsIconColor: keyof typeof fromDesignSystem.colors = 'neutral-500';
  audioIconColor: keyof typeof fromDesignSystem.colors = 'neutral-500';

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    this.chatForm = this.formBuilder.group({
      charlyPrompt: [''],
    });

    this.chatForm.get('charlyPrompt')?.valueChanges.subscribe(() => {
      this.setSendColor();
    });

    this.flags$.pipe(takeUntilDestroyed()).subscribe((flags) => {
      this.flags = flags;

      this.setSendColor();
    });

    this.transcription$
      .pipe(takeUntilDestroyed())
      .subscribe((stt) => this.onSpeechToText(stt));
  }

  ngAfterViewInit() {
    this.store.dispatch(ChatbotConversationsApiActions.loadConversation());
  }

  closeChatbot() {
    this.store.dispatch(ChatbotActions.toggleChatDisplay());
  }

  onSpeechToText(stt: {
    transcription: string;
    isFinal: boolean;
    isRecording: boolean;
  }) {
    this.setAudioContext(stt.isRecording);

    if (stt.isRecording) {
      if (stt.isFinal) {
        this.currentTranscription =
          this.currentTranscription + ' ' + stt.transcription;

        this.chatForm.get('charlyPrompt')?.setValue(this.currentTranscription);
      } else {
        this.chatForm
          .get('charlyPrompt')
          ?.setValue(this.currentTranscription + stt.transcription);
      }
    } else {
      this.currentTranscription = '';
      this.chatForm.get('charlyPrompt')?.setValue(this.currentTranscription);
    }
    this.cdr.markForCheck();
  }

  toggleAudio() {
    this.store.dispatch(ChatbotActions.speechToTextToggleRequested());
  }

  async clearConversation() {
    this.store.dispatch(ChatbotActions.resetConversationRequested());
  }

  askChatbot() {
    if (this.flags?.isWaiting) {
      return;
    }

    this.currentTranscription = '';

    const newQuestion = this.chatForm.get('charlyPrompt')?.value;
    if (newQuestion && (newQuestion?.trim() ?? '').length > 0) {
      this.store.dispatch(
        ChatbotActions.questionAsked({
          question: newQuestion,
          contextDate: this._contextDate,
        })
      );
    }

    this.chatForm.get('charlyPrompt')?.reset();
  }

  private setAudioContext(isRecording: boolean) {
    this.audioIconColor = classNames(
      isRecording ? 'shades-0' : 'neutral-500'
    ) as keyof typeof fromDesignSystem.colors;
  }

  private setSendColor() {
    const v = this.chatForm.get('charlyPrompt')?.value;

    this.sendIconColor = classNames(
      v && v.trim().length > 0 && !this.flags.isWaiting
        ? 'shades-0'
        : 'neutral-500'
    ) as keyof typeof fromDesignSystem.colors;

    this.sendIconHoverColor = classNames(
      this.flags.isWaiting ? 'neutral-500' : 'shades-0'
    ) as keyof typeof fromDesignSystem.colors;
  }
}
