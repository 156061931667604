import { PathLocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  constructor(
    private locationStrategy: PathLocationStrategy,
    private router: Router
  ) {}

  async navigateTo(path: string, parameters?: any[]) {
    if (parameters) {
      await this.router.navigate([path, ...parameters]);
    } else {
      await this.router.navigate([path]);
    }
  }

  async replaceBy(path: string, parameters?: string) {
    await this.router.navigateByUrl(path + parameters, { replaceUrl: true });
  }

  // setRoot(path: string) {
  //   this.router.navigate([path]);
  // }

  back() {
    this.locationStrategy.back();
  }

  // async pop() {
  //   await this.router.pop();
  // }
}
