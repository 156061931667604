import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, from, map, of, tap } from 'rxjs';
import * as fromServices from '../../../services';
import {
  ChatbotActions,
  ChatbotConversationsApiActions,
} from './chatbot.actions';
import { chatbotFeature } from './chatbot.reducer';

@Injectable()
export class ConversationEffects {
  private readonly actions$ = inject(Actions);
  private readonly chatbotService = inject(fromServices.CharlyGptService);
  private readonly sttService = inject(fromServices.SpeechToTextService);
  private readonly ttsService = inject(fromServices.TextToSpeechService);
  private readonly store = inject(Store);

  loadConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.setConversationType),
      concatLatestFrom(() =>
        this.store.select(chatbotFeature.selectConversationType)
      ),
      exhaustMap(([, conversationType]) =>
        this.chatbotService.getCurrentConversation(conversationType).pipe(
          map((conversation) =>
            ChatbotConversationsApiActions.loadConversationSuccess({
              conversation,
            })
          ),
          catchError((error: { message: string }) =>
            of(
              ChatbotConversationsApiActions.conversationFailure({
                errorMsg: error.message,
              })
            )
          )
        )
      )
    )
  );

  createNewConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.resetConversationRequested),
      concatLatestFrom(() =>
        this.store.select(chatbotFeature.selectConversationType)
      ),
      exhaustMap(([, conversationType]) =>
        this.chatbotService.createNewConversation(conversationType).pipe(
          map((conversation) =>
            ChatbotConversationsApiActions.newConversationCreated({
              conversation,
            })
          ),
          catchError((error: { message: string }) =>
            of(
              ChatbotConversationsApiActions.conversationFailure({
                errorMsg: error.message,
              })
            )
          )
        )
      )
    )
  );

  createNewExchange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.questionAsked),
      exhaustMap(({ question, contextDate }) =>
        this.chatbotService.createNewExchange(question, contextDate).pipe(
          map((answer) =>
            ChatbotActions.questionCreatedSuccess({ newExchange: answer })
          ),
          catchError((error: { message: string }) =>
            of(
              ChatbotConversationsApiActions.conversationFailure({
                errorMsg: error.message,
              })
            )
          )
        )
      )
    )
  );

  getAiAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.questionCreatedSuccess),
      concatLatestFrom(() => [
        this.store.select(chatbotFeature.selectConversationType),
        this.store.select(chatbotFeature.selectChatbotExchangeDtoList),
      ]),
      exhaustMap(([{ newExchange }, conversationType, exchanges]) =>
        this.chatbotService
          .getAiAnswer(conversationType, newExchange, exchanges)
          .pipe(
            map((exchange) => {
              return ChatbotActions.questionAnsweredSuccess({ exchange });
            }),
            catchError((error: { message: string }) =>
              of(
                ChatbotConversationsApiActions.conversationFailure({
                  errorMsg: error.message,
                })
              )
            )
          )
      )
    )
  );

  saveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.questionAnsweredSuccess),
      concatLatestFrom(() => [
        this.store.select(chatbotFeature.selectConversationSession),
        this.store.select(chatbotFeature.selectChatbotExchangeDtoList),
      ]),
      exhaustMap(([, conversationSession, exchanges]) =>
        this.chatbotService
          .saveConversation(conversationSession, exchanges)
          .pipe(
            map((conversation) => {
              return ChatbotConversationsApiActions.conversationSaveSuccess({
                conversation,
              });
            }),
            catchError((error: { message: string }) =>
              of(
                ChatbotConversationsApiActions.conversationFailure({
                  errorMsg: error.message,
                })
              )
            )
          )
      )
    )
  );

  /**
   * Text To Speech Effect
   *
   * Speak the AI answer
   * This effect does not dispatch any actions
   */
  speakNow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatbotActions.questionAnsweredSuccess),
        concatLatestFrom(() => this.store.select(chatbotFeature.selectFlags)),
        exhaustMap(([{ exchange }, flags]) => {
          if (flags.useTextToSpeech) {
            return this.ttsService.speakNow(exchange.ai?.text).pipe(
              catchError((error: { message: string }) =>
                of(
                  ChatbotConversationsApiActions.textToSpeechFailure({
                    errorMsg: error.message,
                  })
                )
              )
            );
          } else {
            return of(null);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Speech To Text Effect
   *
   */
  speechToTextToggle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbotActions.speechToTextToggleRequested),
      concatLatestFrom(() => this.store.select(chatbotFeature.selectStt)),
      exhaustMap(([, stt]) => {
        if (stt.isRecording) {
          return from(this.sttService.stopRecording()).pipe(
            map((isRecording) => {
              return ChatbotConversationsApiActions.speechToTextRecording({
                isRecording,
              });
            }),
            catchError((error: { message: string }) =>
              of(
                ChatbotConversationsApiActions.speechToTextFailure({
                  errorMsg: error.message,
                })
              )
            )
          );
        } else {
          return from(this.sttService.startRecording('fr-CA')).pipe(
            map((isRecording) => {
              return ChatbotConversationsApiActions.speechToTextRecording({
                isRecording,
              });
            }),
            catchError((error: { message: string }) =>
              of(
                ChatbotConversationsApiActions.speechToTextFailure({
                  errorMsg: error.message,
                })
              )
            )
          );
        }
      })
    )
  );

  handleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChatbotConversationsApiActions.conversationFailure,
          ChatbotConversationsApiActions.speechToTextFailure,
          ChatbotConversationsApiActions.textToSpeechFailure
        ),
        tap(({ errorMsg }) => {
          console.error('Error', errorMsg);
          alert(errorMsg);
        })
      ),
    { dispatch: false }
  );
}
