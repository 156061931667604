import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FeatureFlag } from './feature-flags.state';

export const FeatureFlagsActions = createActionGroup({
  source: 'Feature Flags Actions',
  events: {
    /**
     * Initial Load
     */
    'Initial Load Requested': emptyProps(),
    'Initial Load Success': props<{ featureFlags: FeatureFlag[] }>(),
    /**
     * Identify User
     */
    'Identify User Requested': emptyProps(),
    'Identify Reset': emptyProps(),
    /**
     * On Change Sync
     */
    'On Change Sync Requested': emptyProps(),
    'On Change Sync Success': props<{ featureFlags: FeatureFlag[] }>(),
  },
});
