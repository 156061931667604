import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as fromDesignSystem from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable, interval, map, tap } from 'rxjs';
import { MegActions, MegState, MegUiState, megFeature } from './store';

@Component({
  selector: 'ch-meg-status',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    CommonModule,
    fromDesignSystem.CommonNgDesignSystemModule,
  ],
  styles: [
    `
      .reset-button .ds-button {
        color: theme('colors.primary.800');
      }
    `,
  ],

  template: `
    <div
      class="grid grid-rows-[30px_30px_30px_30px_90px] h-full"
      *ngIf="uiState$ | async as uiState"
    >
      <div class="flex gap-2 text-lg font-semibold">
        <span>{{ 'MEG.SCENARIO' | translate }}:</span>
        <span class="text-neutral-400">{{ uiState.execution.scenario }}</span>
      </div>
      <div class="flex gap-2 text-lg font-semibold">
        <span>{{ 'MEG.STATUS.STATUS' | translate }}:</span>
        <span class="text-neutral-400">{{
          'MEG.STATUS.STATUS_TYPE.' + uiState.combinedState | translate
        }}</span>
      </div>
      <div class="flex gap-2 text-lg font-semibold">
        <span>{{ 'MEG.STATUS.NEXT_EVENT_ID' | translate }}:</span>
        <span
          *ngIf="uiState.execution.runningState !== 'completed'"
          class="text-neutral-400"
          >{{ uiState.execution.nextEventId }}</span
        >
      </div>
      <div class="flex gap-2 text-lg font-semibold">
        <span>{{ 'MEG.STATUS.NEXT_EVENT_DELAY' | translate }}:</span>
        <span
          *ngIf="uiState.execution.runningState !== 'completed'"
          class="text-neutral-400"
          >{{ timeLeft$ | async }}</span
        >
      </div>
      <div class="grid grid-cols-2 ">
        <div class="flex gap-2 pt-10">
          <button
            ds-button
            kind="filled"
            color="primary"
            size="md"
            type="submit"
            theme="dark"
            [disabled]="uiState.playPauseButtonDisabled"
            (click)="pauseOrPlay(uiState)"
          >
            <div>
              <span class="ds-button-text">{{
                (['none', 'completed'].includes(uiState.execution.runningState)
                  ? 'MEG.START'
                  : uiState.execution.runningState === 'paused'
                  ? 'MEG.PLAY'
                  : 'MEG.PAUSE'
                ) | translate
              }}</span>
            </div>
          </button>
        </div>
        <div class="reset-button flex gap-2 pt-10 justify-end">
          <button
            ds-button
            kind="outlined"
            color="danger"
            size="md"
            type="submit"
            theme="dark"
            (click)="clearDatabase()"
          >
            <div>
              <span class="ds-button-text">{{
                'MEG.CLEAR_DB' | translate
              }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegStatusComponent {
  private readonly store = inject<Store<MegState>>(Store);

  readonly uiState$ = this.store.select(megFeature.selectUiState);
  readonly execution$ = this.store.select(megFeature.selectExecution);

  private timeLeftSubject$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  timeLeft$: Observable<number> = new Observable();

  constructor(private cdr: ChangeDetectorRef) {
    this.timeLeft$ = this.timeLeftSubject$.asObservable();

    // Run for every second forever :) until the component is destroyed.
    interval(1000)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.timeLeftSubject$.value > 0) {
          this.timeLeftSubject$.next(this.timeLeftSubject$.value - 1);
        }
      });

    // Update the time left every time the execution changes.
    this.execution$
      .pipe(
        takeUntilDestroyed(),
        map((execution) => {
          if (
            execution.runningState === 'waiting' &&
            execution.requestedState === 'running'
          ) {
            return execution.nextEventDelay;
          }
          return -1;
        }),
        tap((timeLeft) => {
          if (timeLeft > 0) {
            this.timeLeftSubject$.next(timeLeft);
          }
        })
      )
      .subscribe();
  }

  pauseOrPlay(uiState: MegUiState) {
    const { execution, scenarioFilename } = uiState;

    if (['none', 'completed'].includes(execution.runningState)) {
      this.store.dispatch(
        MegActions.startScenario({ scenarioFilename: scenarioFilename })
      );
    } else {
      this.store.dispatch(
        MegActions.updateRequestedState({
          scenario: execution.scenario,
          executionId: execution.executionId,
          changes: {
            requestedState:
              execution.runningState === 'paused' ? 'running' : 'paused',
          },
        })
      );
    }
  }

  clearDatabase() {
    this.store.dispatch(MegActions.clearDbRequested());
  }
}
