import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';

export const ChatbotActions = createActionGroup({
  source: 'Chatbot Actions',
  events: {
    'Set Conversation Type': props<{
      conversationType: fromGenerated.ChatbotConversationType;
    }>(),

    'Reset Conversation Requested': emptyProps(),

    'Question Asked': props<{
      question: Readonly<string>;
      contextDate: Date;
    }>(),

    'Question Created Success': props<{
      newExchange: fromGenerated.ChatbotConversationExchangeDto;
    }>(),

    'Question Answered Success': props<{
      exchange: fromGenerated.ChatbotConversationExchangeDto;
    }>(),

    'Speech To Text Toggle Requested': emptyProps(),
    'Toggle Chat Display': emptyProps(),
    'Enable Chat': emptyProps(),
    'Disable Chat': emptyProps(),
  },
});

export const ChatbotConversationsApiActions = createActionGroup({
  source: 'Chatbot Conversations API',
  events: {
    'Load Conversation': emptyProps(),
    'Load Conversation Success': props<{
      conversation: Readonly<fromGenerated.ChatbotConversationDto>;
    }>(),

    'New Conversation Created': props<{
      conversation: Readonly<fromGenerated.ChatbotConversationDto>;
    }>(),

    'Conversation Save Requested': props<{
      conversation: Readonly<fromGenerated.ChatbotConversationDto>;
    }>(),

    'Conversation Save Success': props<{
      conversation: Readonly<fromGenerated.ChatbotConversationDto>;
    }>(),

    'Conversation Failure': props<{
      errorMsg: Readonly<string>;
    }>(),

    'Text To Speech Failure': props<{
      errorMsg: Readonly<string>;
    }>(),

    'Speech To Text Recording': props<{
      isRecording: Readonly<boolean>;
    }>(),

    'Speech To Text Data': props<{
      transcription: Readonly<string>;
      isFinal: Readonly<boolean>;
    }>(),

    'Speech To Text Failure': props<{
      errorMsg: Readonly<string>;
    }>(),
  },
});
