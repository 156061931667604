// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TmnDashboardTbjDataDto { 
    mrfTotalTransportQty: number;
    mrfTotalProdCrusherQty: number;
    actualShovelDispoCount: number;
    actualTruckDispoCount: number;
    actualTotalTransportQty: number;
    actualTotalTransportQtyTrend: number;
    actualTruckMeanCycle: number;
    actualTruckMeanCycleTrend: number;
    actualTruckUsagePercent: number;
    actualTruckUsagePercentTrend: number;
}

