import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DropdownItem } from '../../interfaces/dropdown-item';

@Component({
  selector: 'ad-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownOrganism,
    },
  ],
})
export class DropdownOrganism
  implements ControlValueAccessor, OnInit, OnChanges
{
  itemsDropdownIsShown = false;

  @Input()
  value = '';

  @Input()
  items: DropdownItem[] = [];

  @Input()
  placeholder: string | null = null;

  @Input()
  errorMessage: string | null = null;

  @Input()
  hideLabel = false;

  @Input()
  error = false;

  @Input()
  disabled = false;

  @Output()
  dropdownElementChange: EventEmitter<DropdownItem> = new EventEmitter();

  selectedElement: DropdownItem | null = null;

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (dropdownValue: string) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touchedValue = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.manualSelectedItemChange(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.manualSelectedItemChange(changes['value'] as unknown as string);
    }
  }

  /**
   * This method is handled the click on the dropdown button.
   */
  onDropdownClick() {
    this.itemsDropdownIsShown = !this.itemsDropdownIsShown;
  }

  /**
   * This method is handled the click on the dropdown item.
   * @param {DropdownItem} item The dropdown item.
   */
  onMenuItemClick(item: DropdownItem) {
    this.selectedElement = item;
    this.onChange(item.id);
    this.markAsTouched();
    this.dropdownElementChange.emit(item);
    this.closeDropdown();
  }

  manualSelectedItemChange(id: string) {
    this.selectedElement = this.items.filter((item) => item.id === id)[0];
  }

  /**
   * This method is handled the click on the dropdown item.
   */
  closeDropdown() {
    this.itemsDropdownIsShown = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  writeValue(currValue: string) {
    this.value = currValue;
    this.manualSelectedItemChange(this.value);
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
  }

  setDisabledState(disabledValue: boolean) {
    this.disabled = disabledValue;
  }
}
