import { Component, HostBinding, Input } from '@angular/core';

import { IconAtomGlyphEnum, IconAtomType } from '../../../atoms';
import { colors } from '../../../common-ng-design-system.colors';

@Component({
  selector: 'ad-label',
  template: `
    <ng-container *ngIf="iconLeft">
      <ad-icon
        [glyph]="iconLeft"
        [color]="disabled ? iconLeftFillDisabled : iconLeftFill"
      >
      </ad-icon>
    </ng-container>
    <label disabled [for]="id">{{ label }}</label>
    <ng-container *ngIf="iconRight">
      <ad-icon
        [glyph]="iconRight"
        [color]="disabled ? iconRightFillDisabled : iconRightFill"
      >
      </ad-icon>
    </ng-container>
  `,
  styleUrls: ['./label.component.scss'],
})
export class LabelMolecule {
  icons = IconAtomGlyphEnum;
  @Input() id?: string;
  @Input() label?: string;
  @Input() disabled = false;

  @Input() iconLeft?: IconAtomType;
  @Input() iconLeftFill: keyof typeof colors = 'neutral-700';
  @Input() iconLeftFillDisabled: keyof typeof colors = 'neutral-400';

  @Input() iconRight?: IconAtomType;
  @Input() iconRightFill: keyof typeof colors = 'neutral-700';
  @Input() iconRightFillDisabled: keyof typeof colors = 'neutral-400';

  @HostBinding('class') class = 'text-caption';
}
