import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { ColorType } from '../../common-ng-design-system.colors';

import { TooltipKind } from './tooltip';

@Component({
  selector: 'ad-tooltip',
  template: `
    <ng-content></ng-content>
    <div [class]="'tooltip-container'">
      <div
        [class]="
          'tooltip-content text-caption' +
          (fontWeight === 'regular' ? '' : '--' + fontWeight)
        "
      >
        <ng-content select="[icon]"></ng-content>
        <div [innerHTML]="text"></div>
      </div>
    </div>
  `,
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipMolecule implements OnInit {
  @Input()
  text?: string;

  @Input()
  color: ColorType = 'primary';

  @Input()
  position: TooltipKind = 'top';

  @Input()
  show = false;

  @Input()
  fontWeight: 'regular' | 'semibold' = 'semibold';

  @HostBinding('class') classList =
    'position-' +
    this.position +
    ' color-' +
    this.color +
    ' ' +
    (this.show ? 'show' : '');

  ngOnInit(): void {
    this.classList =
      'position-' +
      this.position +
      ' color-' +
      this.color +
      ' ' +
      (this.show ? 'show' : '');
  }
}
