import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import * as fromGenerated from '../../../_generated';
import { AppActions } from '../../../store';
import { EquipmentHealthActions } from './equipment-health.actions';

export interface EquipmentHealthState
  extends EntityState<fromGenerated.EquipmentHealthDiagnosticView> {
  date: DateTime;
  wss: {
    connectionStatus: 'live' | 'offline' | 'timetravel';
  };
  error: string | null;
}

const entityAdapter: EntityAdapter<fromGenerated.EquipmentHealthDiagnosticView> =
  createEntityAdapter<fromGenerated.EquipmentHealthDiagnosticView>({
    selectId: (view) => view.equipment_diagnostic_id,
    sortComparer: (a, b) => b.timestamp.localeCompare(a.timestamp),
  });

const { selectAll, selectTotal } = entityAdapter.getSelectors();

const initialEquipmentHealthState: EquipmentHealthState =
  entityAdapter.getInitialState({
    date: DateTime.now(),
    wss: {
      connectionStatus: 'offline',
    },
    error: null,
  });

export const equipmentHealthFeature = createFeature({
  name: 'equipment-health',
  reducer: createReducer(
    initialEquipmentHealthState,
    on(EquipmentHealthActions.setDate, (state, { date }) => {
      // while time traveling, the date is set by the MEG status
      if (state.wss.connectionStatus === 'timetravel') {
        return state;
      }

      return {
        ...state,
        date,
      };
    }),
    on(
      EquipmentHealthActions.updateMegSimulatedDate,
      (state, { execution }) => {
        if (execution.simulationDate && execution.simulationDate.length > 0) {
          return {
            ...state,
            date: DateTime.fromISO(execution.simulationDate),
            wss: {
              ...state.wss,
              connectionStatus: 'timetravel',
            } satisfies EquipmentHealthState['wss'],
          };
        } else if (state.wss.connectionStatus === 'timetravel') {
          return {
            ...state,
            date: DateTime.now(),
            wss: {
              ...state.wss,
              connectionStatus: 'live',
            } satisfies EquipmentHealthState['wss'],
          };
        }
        // No changes
        return state;
      }
    ),
    on(AppActions.setWSSLive, (state) => ({
      ...state,
      wss: {
        ...state.wss,
        connectionStatus: 'live',
      } satisfies EquipmentHealthState['wss'],
    })),
    on(AppActions.setWSSOffline, (state) => ({
      ...state,
      wss: {
        ...state.wss,
        connectionStatus: 'offline',
      } satisfies EquipmentHealthState['wss'],
    })),
    on(EquipmentHealthActions.loadDiagnostics, (state) => ({
      ...state,
      error: null,
    })),
    on(
      EquipmentHealthActions.loadDiagnosticsSuccess,
      (state, { diagnostics }) => entityAdapter.setAll(diagnostics, state)
    ),
    on(EquipmentHealthActions.upsertDiagnostic, (state, { diagnostic }) =>
      entityAdapter.upsertOne(diagnostic, state)
    ),
    on(EquipmentHealthActions.updateDiagnosticStatus, (state, updates) =>
      entityAdapter.updateOne(updates, state)
    ),
    on(EquipmentHealthActions.loadDiagnosticsFailure, (state, { error }) => ({
      ...state,
      error,
    }))
  ),
  extraSelectors: ({
    selectWss,
    'selectEquipment-healthState': selectState,
  }) => ({
    selectWssConnectionStatus: createSelector(
      selectWss,
      (wss) => wss.connectionStatus
    ),
    selectDiagnosticsTotal: createSelector(selectState, (state) =>
      selectTotal(state)
    ),
    selectDiagnosticsByStatus: (
      status: fromGenerated.EquipmentHealthDiagnosticView['status']
    ) =>
      createSelector(selectState, (state) =>
        selectAll(state).filter((diagnostic) => status === diagnostic.status)
      ),
  }),
});
