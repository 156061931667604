import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { MegExecution, MegRunningStateEnum } from '../../../_generated';
import { MegActions } from './meg.actions';

export const MegRunningStateExtraEnum = {
  /**
   * A pause has been requested by the user
   */
  PauseRequested: 'pause_requested',
};
export type MegRunningStateExtraEnum = keyof typeof MegRunningStateExtraEnum;

export type MegRunningStateCombinedEnum =
  | MegRunningStateEnum
  | MegRunningStateExtraEnum;

export interface MegState {
  execution: MegExecution;
  waitingForRequestResult: boolean;
  scenarioFilename: string;
}

export interface MegUiState extends MegState {
  combinedState: MegRunningStateCombinedEnum;
  playPauseButtonDisabled: boolean;
}

const initialMegState: MegState = {
  execution: {
    scenario: '',
    executionId: '',
    nextEventId: '',
    nextEventDelay: 0,
    remainingExecutionCount: 0,
    requestedState: 'running',
    runningState: 'none',
    simulationDate: undefined,
  },
  waitingForRequestResult: false,
  scenarioFilename: '',
};

// Combine the requested state and the running state to determine
// the actual state we want to display in the UI.
const getCombinedState = (
  execution: MegExecution
): MegRunningStateCombinedEnum => {
  if (
    execution.requestedState === 'paused' &&
    execution.runningState !== 'paused'
  ) {
    return MegRunningStateExtraEnum.PauseRequested as MegRunningStateCombinedEnum;
  }
  return execution.runningState;
};

const getPlayPauseButtonDisabled = (state: MegState) => {
  const { execution, scenarioFilename, waitingForRequestResult } = state;

  return (
    !scenarioFilename ||
    scenarioFilename === '' ||
    [MegRunningStateExtraEnum.PauseRequested].includes(
      getCombinedState(execution)
    ) ||
    waitingForRequestResult
  );
};

export const megFeature = createFeature({
  name: 'meg',
  reducer: createReducer(
    initialMegState,
    // reset the state when a new scenario is selected
    on(MegActions.selectScenario, (state, { scenarioFilename }) => ({
      ...initialMegState,
      scenarioFilename: scenarioFilename ?? '',
    })),
    on(MegActions.startScenario, (state) => ({
      ...state,
      waitingForRequestResult: true,
    })),
    on(MegActions.updateRequestedState, (state) => ({
      ...state,
      waitingForRequestResult: true,
    })),
    on(MegActions.updateStatus, (state, { execution }) => ({
      ...state,
      execution,
      waitingForRequestResult: false,
    })),
    on(MegActions.updateRequestedState, (state) => ({
      ...state,
      waitingForRequestResult: true,
    }))
  ),
  extraSelectors: ({ selectMegState }) => ({
    selectUiState: createSelector(selectMegState, (state) => {
      return {
        ...state,
        combinedState: getCombinedState(state.execution),
        playPauseButtonDisabled: getPlayPauseButtonDisabled(state),
      } satisfies MegUiState;
    }),
  }),
});
