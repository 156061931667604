import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ch-chatbot-fab',
  template: ` <img src="assets/img/charly-fab.svg" /> `,
  styles: [
    `
      :host {
        width: 3rem;
        height: 3rem;
        padding: 0.75rem;
        border-radius: 0.5rem;
        background: rgb(var(--color-primary-500));
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
          0px 2px 6px 2px rgba(0, 0, 0, 0.15);

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    `,
  ],
})
export class ChatbotFabComponent {}
