import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fromDesignSystem from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import * as fromFeatureFlags from '../../feature-flags';
import * as fromServices from '../../services';
import { CoreAuthError } from '../interfaces';
import { CoreAuthRepository } from '../repositories';
import { CoreAuthService } from '../services';
import { StoreService } from '../store';

@Component({
  selector: 'ch-charly-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SignInPage implements OnInit, OnDestroy {
  form: FormGroup;
  headerCustomClass = '';
  invalidPassword = false;
  loadingSubscription?: Subscription;
  loadingStatus = false;
  loginEmail = false;
  showPassword = false;
  showSpinner = false;
  returnUrl = '';
  rememberMe$: Observable<boolean | undefined>;

  passwordIcon: fromDesignSystem.IconAtomGlyphType = 'password-show';

  emailBlurred = false;
  emailErrorMessage = {
    email: '',
    required: '',
  };

  passwordErrorMessage = {
    invalid_credentials: '',
    required: '',
  };

  static ValidateCredentials(page: SignInPage) {
    return (): { [key: string]: any } | null => {
      if (page.invalidPassword) {
        return { invalid_credentials: true };
      }
      return null;
    };
  }

  constructor(
    private readonly authService: CoreAuthService,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly userService: fromServices.UserService,
    private readonly globalService: fromServices.GlobalService,
    private readonly navigateService: fromServices.NavigateService,
    private readonly route: ActivatedRoute,
    private readonly storeService: StoreService,
    private readonly translateService: TranslateService,
    private readonly featureFlagsStore: Store<fromFeatureFlags.FeatureFlagsState>
  ) {
    this.rememberMe$ = this.coreAuthRepository.isRemembered;

    this.emailErrorMessage = {
      email: this.translateService.instant('ERRORS.INVALID_EMAIL'),
      required: this.translateService.instant('ERRORS.EMAIL_REQUIRED'),
    };
    this.passwordErrorMessage = {
      required: this.translateService.instant('ERRORS.PASSWORD_REQUIRED'),
      invalid_credentials: this.translateService.instant(
        'ERRORS.INVALID_CREDENTIALS'
      ),
    };

    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        SignInPage.ValidateCredentials(this),
      ]),
      password: new FormControl('', [
        Validators.required,
        SignInPage.ValidateCredentials(this),
      ]),
    });

    this.form.statusChanges.subscribe(() => {
      if (this.invalidPassword) {
        this.invalidPassword = false;

        this.form.get('email')?.markAsPristine();
        this.form.get('email')?.markAsUntouched();
        this.form.get('email')?.updateValueAndValidity();
        this.form.get('password')?.markAsPristine();
        this.form.get('password')?.markAsUntouched();
        this.form.get('password')?.updateValueAndValidity();
      }
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  async ionViewWillEnter() {
    this.storeService.clearAuthCodeValidationData();
  }

  /**
   * SignIn the user with Email
   */
  async signIn() {
    try {
      if (!this.form.valid) {
        return;
      }

      this.invalidPassword = false;
      this.showSpinner = true;

      await this.authService.signIn({
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
      });

      const user = await firstValueFrom(this.userService.userControllerGetMe());
      this.featureFlagsStore.dispatch(
        fromFeatureFlags.FeatureFlagsActions.identifyUserRequested()
      );

      this.showSpinner = false;

      if (user) {
        if (this.returnUrl) {
          this.navigateService.navigateTo(this.returnUrl);
        } else {
          this.globalService.goToHome();
        }
      } else {
        this.navigateService.navigateTo('/auth/signin');
      }
    } catch (error: any) {
      if (error instanceof CoreAuthError) {
        console.error('SignInPage CoreAuthError', error);

        this.form.get('email')?.setErrors({ invalid_credentials: true });
        this.form.get('password')?.setErrors({ invalid_credentials: true });

        setTimeout(() => {
          this.invalidPassword = true;
        });
      }

      this.showSpinner = false;
    }
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

  public onRememberMeChanged(event: any): void {
    this.coreAuthRepository.setIsRemembered(event.target.checked);
  }

  resetPassword() {
    this.navigateService.navigateTo('/auth/reset-password');
  }

  signOut() {
    this.globalService.signOut();
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.passwordIcon = this.showPassword ? 'password-hide' : 'password-show';
  }

  onBlurPassword() {
    this.form.get('password')?.markAsTouched();
  }

  onBlurEmail() {
    this.emailBlurred = true;
    this.form.get('email')?.markAsTouched();
  }
}
