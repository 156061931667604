import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { ColorType, StyleType } from '../../common-ng-design-system.colors';

import { CheckboxSizes, CheckboxType } from './checkbox.enum';
import * as classNames from 'classnames';

@Component({
  selector: 'ad-checkbox',
  template: `
    <div
      *ngIf="type === 'square'"
      [class]="'container size-' + size + ' color-' + color + ' theme-' + theme"
      [ngClass]="{
        'is-selected': selected,
        'is-indeterminate': indeterminate,
        'is-disabled': disabled
      }"
    >
      <input
        #checkboxRef
        [id]="'ad-checkbox-component-' + id"
        type="checkbox"
        [value]="value"
        [disabled]="disabled"
        [indeterminate]="indeterminate"
        (click)="onInputChange()"
        [name]="id"
        [checked]="selected"
      />
      <ad-icon
        *ngIf="selected && !indeterminate"
        class="icon"
        glyph="checkbox-check"
        color="shades-0"
        [defaultWidth]="size === 'sm' ? 12 : 16"
      ></ad-icon>
      <ad-icon
        *ngIf="indeterminate && !selected"
        class="icon"
        glyph="minus"
        color="shades-0"
      ></ad-icon>
    </div>
    <input
      [id]="'ad-checkbox-component-' + id"
      *ngIf="type === 'circle'"
      type="radio"
      [value]="value"
      [class]="'circle size-' + size + ' color-' + color"
      [disabled]="disabled"
      [attr.checked]="selected"
      [name]="name"
      (click)="onInputChange()"
    />
    <label
      (click)="handleInputChange()"
      [ngClass]="{
        'text-caption': size === 'sm',
        'text-p3': size === 'md',
        'is-disabled': disabled
      }"
      >{{ text }}</label
    >
  `,
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxMolecule {
  @ViewChild('checkboxRef') checkboxRef:
    | ElementRef<HTMLInputElement>
    | undefined;

  @Input()
  text = 'A multi-select checkbox';

  @Input()
  selected = false;

  @Input()
  disabled = false;

  @Input()
  indeterminate = false;

  @Input()
  id = 'default';

  @Input()
  name = 'default';

  @Input()
  value = 'value';

  @Input()
  size: CheckboxSizes = 'md';

  @Input()
  color: ColorType = 'primary';

  @Input()
  type: CheckboxType = 'square';

  @Input()
  theme: StyleType = 'dark';

  @Output() checkboxEvent = new EventEmitter<boolean>();

  @HostBinding('class')
  public get classes() {
    // const type: ButtonStateType = `${this.kind}-${this.color}${
    //   this.disabled ? '-disabled' : ''
    // }${this.pressed ? '-pressed' : ''}`;

    const typeStyle =
      this.theme === 'dark' ? `theme-${this.theme}` : `theme-${this.theme}`;

    return classNames(`ad-checkbox size-${this.size} ${typeStyle}`, {
      'text-caption': this.size === 'sm',
      'text-p3': this.size === 'md',
    });
  }
  /**
   * This function is called when the user changes the value of the input.
   * It emits the new value of the input.
   */
  onInputChange(): void {
    this.selected = !this.selected;
    this.checkboxEvent.emit(this.selected);
  }

  /**
   * This function is called when the user clicks on the label. It prevents the default behavior of the click event
   * and calls the onInputChange function.
   */
  handleInputChange(): void {
    if (this.type === 'square') {
      if (this.checkboxRef) {
        this.checkboxRef.nativeElement.indeterminate = false;
      }
      this.indeterminate = false;
      this.checkboxEvent.emit(this.indeterminate);
    }
    this.onInputChange();
  }
}
