import { Component, EventEmitter, Input, Output } from '@angular/core';
import classNames from 'classnames';
import { ColorType, StyleType } from '../../common-ng-design-system.colors';
import { SwitchSizeType } from './switch.enum';

@Component({
  selector: 'ad-switch',
  template: `
    <label class="ad-switch flex items-center relative" [ngClass]="sizeClasses">
      <input
        type="checkbox"
        class="hidden"
        [disabled]="disabled"
        [checked]="checked"
        (change)="onToggle()"
      />
      <div class="ad-switch-container" [ngClass]="containerClasses">
        <div class="ad-switch-button" [ngClass]="buttonClasses"></div>
      </div>
    </label>
  `,
  styleUrls: ['./switch.component.scss'],
})
export class SwitchMolecule {
  @Input() checked: boolean | null | undefined = false;

  @Input() color: ColorType = 'primary';

  @Input() disabled = false;

  @Input() size: SwitchSizeType = 'md';

  @Input()
  theme: StyleType = 'dark';

  @Output() checkedChange = new EventEmitter<boolean>();

  /**
   * Toggle the checked state of the checkbox.
   */
  onToggle() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.checkedChange.emit(this.checked);
    }
  }

  public get containerClasses() {
    const typeStyle =
      this.theme === 'dark' ? `theme-${this.theme}` : `theme-${this.theme}`;

    return classNames(
      `${typeStyle} color-${this.color}`,
      {
        'is-disabled': this.disabled,
        'is-selected': this.checked,
      },
      /**
       * size for the button
       */
      {
        md: this.size === 'md',
        sm: this.size === 'sm',
      }
    );
  }

  public get buttonClasses() {
    const typeStyle =
      this.theme === 'dark' ? `theme-${this.theme}` : `theme-${this.theme}`;

    return classNames(
      `${typeStyle} color-${this.color}`,
      {
        'is-disabled': this.disabled,
        'is-selected': this.checked,
      },
      /**
       * size for the button
       */
      {
        md: this.size === 'md',
        sm: this.size === 'sm',
      },
      /**
       * translate on checked
       */
      {
        'translate-x-5': this.checked && this.size === 'md',
        'translate-x-35': this.checked && this.size === 'sm',
        'translate-x-0': !this.checked,
      }
    );
  }

  public get sizeClasses() {
    return classNames({
      md: this.size === 'md',
      sm: this.size === 'sm',
    });
  }
}
