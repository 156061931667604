import { InjectionToken } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { withRequestsStatus } from '@ngneat/elf-requests';

export enum AuthRequest {
  SIGNIN = 'signin',
  SIGNOUT = 'signout',
  RESET_PASSWORD = 'reset-password',
  SUBMIT_RESET_PASSWORD = 'submit-reset-password',
  IS_LOGGED_IN = 'is-logged-in',
}

export interface AuthProps {
  isRemembered: boolean | undefined;
}

const name = 'charly-ui-auth';
const { state, config } = createState(
  withProps<AuthProps>({
    isRemembered: true,
  }),
  withRequestsStatus<AuthRequest.SIGNIN>(),
  withRequestsStatus<AuthRequest.SIGNOUT>(),
  withRequestsStatus<AuthRequest.RESET_PASSWORD>(),
  withRequestsStatus<AuthRequest.SUBMIT_RESET_PASSWORD>(),
  withRequestsStatus<AuthRequest.IS_LOGGED_IN>()
);

const authStore = new Store({ name, state, config });

export type providerType = typeof authStore;
export const providerToken = new InjectionToken<providerType>(
  'CORE_AUTH_STORE',
  {
    providedIn: 'root',
    factory: () => authStore,
  }
);

export default {
  provide: providerToken,
  useValue: authStore,
};
