// @ts-nocheck
export * from './chatbot.service';
import { ChatbotService } from './chatbot.service';
export * from './configuration.service';
import { ConfigurationService } from './configuration.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './equipmentHealth.service';
import { EquipmentHealthService } from './equipmentHealth.service';
export * from './meg.service';
import { MegService } from './meg.service';
export * from './tmnDashboard.service';
import { TmnDashboardService } from './tmnDashboard.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ChatbotService, ConfigurationService, DefaultService, EquipmentHealthService, MegService, TmnDashboardService, UserService];
