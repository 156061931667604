import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import * as fromGenerated from '../_generated';
import * as fromAuth from '../auth';

@Injectable({
  providedIn: 'root',
})
export class UserService extends fromGenerated.UserService {
  private readonly _storeRepository = inject(
    fromAuth.AuthStore.StoreRepository
  );

  public constructor(
    private readonly _httpClient: HttpClient,
    @Optional() @Inject(fromGenerated.BASE_PATH) private _basePath: string,
    @Optional() private _configuration: fromGenerated.Configuration
  ) {
    super(_httpClient, _basePath, _configuration);
  }

  /**
   * Overriding the generated `userControllerGetMe` to automatically
   * update the store `user` property with the return.
   */
  override userControllerGetMe(): Observable<any> {
    return super.userControllerGetMe().pipe(
      tap((user) => {
        this._storeRepository.store.update((store) => ({
          ...store,
          user: {
            ...store?.user,
            ...user,
          } as any,
        }));
      })
    );
  }
}
