import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CharlyGptService, ConfigService } from '../../services';

@Component({
  standalone: true,
  selector: 'ch-sidebar-logo',
  styles: [
    `
      .logo-am-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          width: 56px;
          height: 56px;
        }

        .logo-am {
          width: 50px;
          height: 20px;
          left: 0.925rem;
        }

        .version-container {
          opacity: 0;
          transition: opacity 0.5s ease-out;
          cursor: pointer;
          pointer-events: none;

          &.text-caption {
            font-size: 0.5rem;
          }

          &--open {
            opacity: 1;
            pointer-events: all;
          }

          .inner {
            overflow: hidden;
          }
        }
      }

      .version-tooltip-wrapper {
        position: relative;
        width: fit-content;
        height: 2rem;
        display: flex;
        align-items: center;
      }

      .tooltip-container {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        z-index: 10;
        display: flex;
        align-items: center;

        padding-left: 0.7rem;
        left: 100%;
        top: 50%;
        min-width: var(--ad-tooltip-width);

        transform: translate(-200%, -50%);

        transition: transform var(--spring-duration) var(--spring-easing);

        &--open {
          opacity: 1;
          transform: translate(0, -50%);
          transition-delay: 500ms;
        }

        div::after {
          border: solid 5px transparent;
          content: ' ';
          position: absolute;

          border-right-color: rgb(var(--color-neutral-700));
          bottom: auto;
          left: auto;
          margin-left: auto;
          margin-top: -5px;
          right: 100%;
          top: 50%;
        }
      }

      .tooltip-content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: max-content;
        border-radius: 4px;
        color: rgb(var(--color-shades-0));
        padding: 4px 8px;
        background-color: rgb(var(--color-neutral-700));
        gap: 4px;

        .emoji {
          transform: translateY(-2px);
        }
      }
    `,
  ],
  template: `
    <div class="logo-am-wrapper">
      <div class="logo-box" (click)="onLogoClick()">
        <img class="logo-am desktop" src="assets/img/logo.svg" />
      </div>
      <div class="version-tooltip-wrapper">
        <div
          class="version-container text-caption"
          [ngClass]="{ 'version-container--open': isVersionVisible }"
          (click)="hideVersion()"
        >
          UI | v{{ appVersion }}<br />
          AI | v{{ aiVersion }}
        </div>

        <div
          [class]="'tooltip-container'"
          [ngClass]="{
            'tooltip-container--open': isVersionTooltipVisible$ | async,
          }"
        >
          <div class="tooltip-content">
            <span class="text-caption emoji">🎉</span>
            <span class="text-caption">{{ 'VERSION' | translate }}</span>
            <span class="text-caption--semibold"
              >Version UI v{{ appVersion }} | AI v{{ aiVersion }}</span
            >
          </div>
        </div>
      </div>
    </div>
  `,
  imports: [NgClass, TranslateModule, AsyncPipe],
})
export class SidebarLogoComponent implements OnInit {
  private readonly configService = inject(ConfigService);
  private readonly aiService = inject(CharlyGptService);

  versionClickCount = 0;
  versionClickHandler: any;
  isVersionVisible = false;
  isVersionTooltipVisibleSubject = new BehaviorSubject(false);
  isVersionTooltipVisible$ = this.isVersionTooltipVisibleSubject.asObservable();
  appVersion = this.configService.getConfig().version;
  aiVersion = '';

  ngOnInit() {
    this.updateAiVersion();
  }

  // When the user clicks on the version logo 3 times in 2 seconds, the version number is displayed
  async onLogoClick() {
    this.versionClickCount++;
    if (this.versionClickCount === 3) {
      this.isVersionVisible = true;
      this.isVersionTooltipVisibleSubject.next(true);
      this.versionClickCount = 0;
      this.updateAiVersion();
      this.automaticallyCloseVersionTooltip();
    } else if (!this.versionClickHandler) {
      this.versionClickHandler = setTimeout(() => {
        this.versionClickCount = 0;

        this.versionClickHandler = null;
      }, 2000);
    }
  }

  automaticallyCloseVersionTooltip() {
    setTimeout(() => {
      this.versionClickCount = 0;
      this.isVersionTooltipVisibleSubject.next(false);
    }, 4000);
  }

  hideVersion() {
    this.isVersionVisible = false;
    this.isVersionTooltipVisibleSubject.next(false);
  }

  private async updateAiVersion() {
    this.aiVersion = await firstValueFrom(this.aiService.getAiVersion());
  }
}
