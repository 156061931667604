// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of the exchange.
 */
export type ChatbotExchangeType = 'error' | 'normal' | 'presentation' | 'waiting';

export const ChatbotExchangeType = {
    error: 'error' as ChatbotExchangeType,
    normal: 'normal' as ChatbotExchangeType,
    presentation: 'presentation' as ChatbotExchangeType,
    waiting: 'waiting' as ChatbotExchangeType
};

