import { NgClass, registerLocaleData } from '@angular/common';
import localeFrCA from '@angular/common/locales/fr-CA';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as fromDesignSystem from '@common/ng-design-system';
import * as classNames from 'classnames';
import { DateFormatPipe } from '../../../pipes';

registerLocaleData(localeFrCA);

@Component({
  standalone: true,
  selector: 'ch-tmn-dashboard-datepicker',
  templateUrl: './tmn-dashboard-datepicker.component.html',
  styleUrls: ['./tmn-dashboard-datepicker.component.scss'],
  imports: [
    NgClass,
    fromDesignSystem.CommonNgDesignSystemModule,
    DateFormatPipe,
  ],
})
export class TmnDashboardDatePickerComponent {
  private _date = new Date();

  @Input()
  public get date() {
    return this._date;
  }
  public set date(value) {
    this._date = value;
  }

  newDate = new Date();

  @Input()
  minDate: Date = new Date(-8640000000000000);

  @Input()
  maxDate: Date = new Date(8640000000000000);

  @Input()
  titleDateFormat = 'longDate';

  @Input()
  calendarIcon: fromDesignSystem.IconAtomGlyphType = 'chevron_down';
  color: fromDesignSystem.ColorType = 'primary';

  @Input()
  displayType: 'mobile' | 'desktop' = 'desktop';

  @Output() closed: EventEmitter<any> = new EventEmitter();

  datepickerIsShown = false;

  constructor(private elementRef: ElementRef) {}

  closeDropdown() {
    if (this.datepickerIsShown) {
      this.datepickerIsShown = false;
      this.closed.emit(this.newDate);
    }
  }

  iconColor() {
    return classNames(
      'color-neutral-300'
    ) as keyof typeof fromDesignSystem.colors;
  }

  iconFillColor() {
    if (this.calendarIcon === 'chevron_down') {
      return classNames('neutral-50') as keyof typeof fromDesignSystem.colors;
    }
    return classNames('shades-100') as keyof typeof fromDesignSystem.colors;
  }

  dateClick() {
    this.datepickerIsShown = !this.datepickerIsShown;
  }

  onDateChanged(event: any) {
    this.newDate = new Date(new Date(event).toDateString());

    this.closeDropdown();
  }
}
