/**
 * Asserts that a value is defined (not undefined or null).
 * Throws an error if the value is undefined or null.
 *
 * @template T - The type of the value being asserted.
 * @param value - The value to be asserted.
 * @throws {Error} - If the value is undefined or null.
 */
export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${value} is not defined`);
  }
}
