import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonNgDesignSystemModule } from '@common/ng-design-system';
import { TranslateModule } from '@ngx-translate/core';
import * as fromGenerated from '../../_generated';
import { EquipmentHealthKanbanColumnCardKpiComponent } from './equipment-health-kanban-column-card-kpi.component';
import { TimestampPipe } from './equipment-health-timestamp.pipe';
import { TrendPipe } from './equipment-health-trend.pipe';

@Component({
  selector: 'ch-equipment-health-kanban-column-card',
  standalone: true,
  imports: [
    CommonNgDesignSystemModule,
    TranslateModule,
    EquipmentHealthKanbanColumnCardKpiComponent,
    TimestampPipe,
    TrendPipe,
  ],
  styles: [
    `
      :host {
        @apply hover:scale-[1.005] transition-transform duration-200 ease-in-out hover:shadow-lg;
      }
    `,
  ],
  animations: [
    trigger('newCardAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', height: '0' }),
        animate(
          '450ms ease-out',
          style({ transform: 'scale(1)', height: '255px' })
        ),
      ]),
    ]),
  ],
  template: `
    <div
      @newCardAnimation
      [@.disabled]="diagnostic().status !== 'todo'"
      class="flex flex-col gap-6 text-sm rounded-lg bg-neutral-900 p-4 hover:bg-shades-0/[0.09]"
    >
      <div class="flex gap-4 items-center">
        <div
          class="flex items-center  justify-center h-10 w-10 rounded-full bg-shades-0/[0.04]"
        >
          <ad-icon
            class="pointer-events-none size-5 shrink-0 text-primary-500"
            glyph="haul-truck"
            fillColor="primary-500"
            [defaultWidth]="20"
          ></ad-icon>
        </div>
        <div class="flex flex-col text-neutral-50">
          <span class="font-semibold text-base"
            >{{ diagnostic().equipment_type }}
            {{ diagnostic().equipment_id }}</span
          >
          <span class="text-neutral-300">{{ diagnostic().name }}</span>
        </div>
        <div class="flex flex-1 self-baseline justify-end">
          <span class="text-neutral-400">{{
            diagnostic().timestamp | chTimestamp
          }}</span>
        </div>
      </div>

      <hr class="h-px  bg-neutral-700 border-0" />

      <div>
        {{ 'EQUIPMENT_HEALTH.KANBAN.CARD.INFO_PART_1' | translate }}
        <span class="font-semibold text-primary-400">
          {{ diagnostic().description }}
        </span>
        {{ 'EQUIPMENT_HEALTH.KANBAN.CARD.INFO_PART_2' | translate }}
      </div>

      <div class="flex justify-around items-center">
        <ch-equipment-health-kanban-column-card-kpi
          [value]="diagnostic().occurrences"
          [alarmName]="diagnostic().alarm_name"
          range="{{ 'EQUIPMENT_HEALTH.KANBAN.CARD.LAST_6_MONTHS' | translate }}"
        ></ch-equipment-health-kanban-column-card-kpi>

        <hr class="h-px w-10 pl-4 pr-4  bg-neutral-700 border-0 rotate-90" />

        <ch-equipment-health-kanban-column-card-kpi
          [value]="diagnostic().trend | chTrend"
          [alarmName]="diagnostic().alarm_name"
          range="{{ 'EQUIPMENT_HEALTH.KANBAN.CARD.LAST_7_DAYS' | translate }}"
        ></ch-equipment-health-kanban-column-card-kpi>

        <hr class="h-px w-10 pl-4 pr-4  bg-neutral-700 border-0 rotate-90" />

        <ch-equipment-health-kanban-column-card-kpi
          [value]="diagnostic().occurrences_ld"
          alarmName="{{
            'EQUIPMENT_HEALTH.KANBAN.CARD.ENGINE_OIL_LOW_MARK' | translate
          }}"
          range="{{ 'EQUIPMENT_HEALTH.KANBAN.CARD.LAST_24_HOURS' | translate }}"
        ></ch-equipment-health-kanban-column-card-kpi>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthKanbanColumnCardComponent {
  readonly diagnostic =
    input.required<fromGenerated.EquipmentHealthDiagnosticView>();
}
