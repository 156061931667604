import { NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as fromDesignSystem from '@common/ng-design-system';
import { TranslateModule } from '@ngx-translate/core';
import { Chart, ChartConfiguration, ChartOptions, Plugin } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { NgChartsModule } from 'ng2-charts';
import { TmnDashboardChartDataDto } from '../../../_generated';

const labelValues = {
  '00:00': '00:00',
  '02:00': '02:00',
  '04:00': '04:00',
  '06:00': '06:00',
  '08:00': '08:00',
  '10:00': '10:00',
  '12:00': '12:00',
  '14:00': '14:00',
  '16:00': '16:00',
  '18:00': '18:00',
  '20:00': '20:00',
  '22:00': '22:00',
};

const labelValuesMobile = {
  '02:00': '02:00',
  '08:00': '08:00',
  '14:00': '14:00',
  '20:00': '20:00',
};

const labelsDesktop = new Map(Object.entries(labelValues));
const labelsMobile = new Map(Object.entries(labelValuesMobile));

let LABELS = labelsDesktop;

@Component({
  standalone: true,
  selector: 'ch-tmn-dashboard-chart',
  templateUrl: './tmn-dashboard-chart.component.html',
  styleUrls: ['./tmn-dashboard-chart.component.scss'],
  imports: [
    TranslateModule,
    NgStyle,
    fromDesignSystem.CommonNgDesignSystemModule,
    NgChartsModule,
  ],
})
export class TmnDashboardChartComponent implements OnInit {
  forceRefreshCanvas = false;
  showConcasseur = true;

  _displayType: 'mobile' | 'desktop' = 'desktop';
  @Input()
  set displayType(value: 'mobile' | 'desktop') {
    this._displayType = value;
    this.setLineChartOptions();
  }

  _loading = false;
  @Input()
  set loading(value: boolean) {
    this._loading = value;
  }

  _data = <TmnDashboardChartDataDto>{};
  @Input()
  set data(value: TmnDashboardChartDataDto | null) {
    if (value) {
      this._data = value;
    }

    if (this._displayType === 'mobile') {
      LABELS = labelsMobile;
    } else {
      LABELS = labelsDesktop;
    }

    if (!value || !value.tnmTotal) {
      return;
    }

    this.lineChartData.labels = value.tnmTotal.map((item) => item.datetime);
    this.lineChartData.datasets[1].data = value.tnmTotal.map(
      (item) => item.tons
    );

    this.lineChartData.datasets[0].data = value.tnmConcasseur.map(
      (item) => item.tons
    );
    this.lineChartData.datasets[0].hidden = !this.showConcasseur;

    // Keep this for now
    // console.log('concasseur data', value.tnmConcasseur);

    if (this.chart) {
      // Hack to clear the chart background artifacts when updating
      this.forceRefreshCanvas = false;

      this.chart.update();

      // Hack to clear the chart background artifacts when updating
      setTimeout(() => {
        this.forceRefreshCanvas = true;
      }, 600);
    }
  }

  static icon_hours_strings = ['07:30', '19:30'];

  static all_24_hours_strings = [...Array(26).keys()].map((n) => {
    return String(n).padStart(2, '0') + ':00';
  });

  static moonImg = new Image();
  static sunImg = new Image();

  chart: any;

  public lineChartOptions: ChartOptions<'line'> = {};

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      // Concasseur
      {
        data: [],

        fill: false,
        tension: 0.5,
        borderColor: '#F94144',
        pointRadius: 0,
        hidden: false,
      },
      // Total
      {
        data: [],

        fill: true,
        tension: 0.5,
        borderColor: 'transparent',
        pointRadius: 0,
        hidden: false,
      },
    ],
  };

  public lineChartLegend = false;

  public lineChartPlugins: Plugin[] = [
    {
      id: 'drawTicksImages',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;
        xAxis.ticks.forEach((value: any, index: number) => {
          // For 08:00 and 20:00, draw the sun and moon icons
          if (['08:00', '20:00'].includes(value.label) && index < 20) {
            const x = xAxis.getPixelForTick(index);
            ctx.fillStyle = '#555658';
            ctx.strokeStyle = '#555658';

            if (value.label === '08:00') {
              ctx.drawImage(
                TmnDashboardChartComponent.sunImg,
                x - 21,
                yAxis.bottom + 17
              );
            } else {
              ctx.drawImage(
                TmnDashboardChartComponent.moonImg,
                x - 21,
                yAxis.bottom + 19
              );
            }

            TmnDashboardChartComponent.moonImg;
          } else if (index < 27) {
            const x = xAxis.getPixelForTick(index);
            ctx.fillStyle = '#555658';

            ctx.font = '0.75rem "Inter"';
            ctx.fillText(value.label, x - 15, yAxis.bottom + 30);
          }
        });
      },
    },
    {
      id: 'initGradients',
      afterInit: (chart: any) => {
        this.chart = chart;
        const ctx = chart.ctx;

        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(132, 13, 129,0.8)');
        gradient.addColorStop(1, 'rgba(132, 13, 129,0.2)');

        this.lineChartData.datasets[1].backgroundColor = gradient;
      },
    },
  ];

  constructor() {
    TmnDashboardChartComponent.moonImg.src = 'assets/img/moon.svg';
    TmnDashboardChartComponent.sunImg.src = 'assets/img/sun.svg';
  }

  ngOnInit() {
    Chart.register(Annotation);
  }

  private setLineChartOptions() {
    this.lineChartOptions = {
      // plugins: {
      //   annotation: {
      //     annotations: {
      //       tnmMrfTotal: {
      //         id: 'tnmMrfTotal',
      //         type: 'line',
      //         borderColor: '#bc12b6',
      //         borderWidth: 1,
      //         scaleID: 'y',
      //         value: () => {
      //           return this._data.tbj.mrfTotalTransportQty / 24;
      //         },
      //         borderDash: [5, 5],
      //         // TODO: To make the labels
      //         label: {
      //           backgroundColor: '#bc12b6',
      //           font: {
      //             size: 12,
      //           },
      //           content: ['MRF'],
      //           position: 'center',
      //           height: 20,
      //           width: 30,
      //           drawTime: 'afterDatasetsDraw',
      //           display: false,
      //         },
      //       },
      //       tnmMrfConcasseur: {
      //         id: 'tnmMrfConcasseur',
      //         type: 'line',
      //         borderColor: '#FF3700',
      //         borderWidth: 1,
      //         scaleID: 'y',
      //         value: () => {
      //           return this._data.tbj.mrfTotalProdCrusherQty / 24;
      //         },
      //         borderDash: [5, 5],
      //         display: () => this.showConcasseur,
      //       },
      //     },
      //   },
      // },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 30,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: 20000,
          grid: {
            display: true,
            color: '#1C1C1C',
          },
          ticks: {
            display: this._displayType === 'desktop',
            stepSize: 10000,
            callback: function (value) {
              return (value as number) / 1000 + 'K';
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
            callback: function (val) {
              const label = this.getLabelForValue(val as number);

              const hours_minutes = label.substring(11, 16);
              return TmnDashboardChartComponent.icon_hours_strings.includes(
                hours_minutes
              )
                ? hours_minutes
                : LABELS.get(hours_minutes) || '';
            },
            color: '#00000000',
            align: 'center',
            autoSkip: false,
            maxRotation: 0,
          },
        },
      },
    };
  }

  toggleConcasseur() {
    this.showConcasseur = !this.showConcasseur;
    this.lineChartData.datasets[0].hidden = !this.showConcasseur;

    this.chart.update();
  }
}
