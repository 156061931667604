// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MegRunningStateEnum } from './megRunningStateEnum';
import { MegRequestedStateEnum } from './megRequestedStateEnum';


export interface MegExecution { 
    /**
     * The name of the scenario
     */
    scenario: string;
    /**
     * Sort key identifier of the execution. It is used to pause the execution. First part is the timestamp of the execution, second part is the ARN of the execution.
     */
    executionId: string;
    /**
     * Identifier of the next event
     */
    nextEventId: string;
    /**
     * Delay, in seconds, before the next event will be triggered.
     */
    nextEventDelay: number;
    /**
     * Number of remaining executions
     */
    remainingExecutionCount: number;
    requestedState: MegRequestedStateEnum;
    runningState: MegRunningStateEnum;
    /**
     * The (very long) token of the task currently waiting for a resume of the execution after a pause was requested. This is provided by the Step Function task.
     */
    taskToken?: string;
    /**
     * The current date used in the simulation
     */
    simulationDate?: string;
}
export namespace MegExecution {
}


