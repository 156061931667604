import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';

import {
  AdInputDirective,
  ProgressBarNavigationDirective,
  SkeletonDirective,
  TableStickyColumnsDirective,
} from './directives';

import { BadgeAtom, IconAtom } from './atoms';
import {
  ButtonMolecule,
  CheckboxMolecule,
  ChipMolecule,
  InputMolecule,
  LabelMolecule,
  RadioMolecule,
  SkeletonMolecule,
  SwitchMolecule,
  TooltipMolecule,
} from './molecules';
import { CalendarOrganism, DropdownOrganism } from './organisms';

@NgModule({
  imports: [CommonModule, CalendarModule, FormsModule, ReactiveFormsModule],
  declarations: [
    AdInputDirective,
    ProgressBarNavigationDirective,
    SkeletonDirective,
    TableStickyColumnsDirective,
    BadgeAtom,
    IconAtom,
    ButtonMolecule,
    CheckboxMolecule,
    ChipMolecule,
    InputMolecule,
    LabelMolecule,
    RadioMolecule,
    SkeletonMolecule,
    SwitchMolecule,
    TooltipMolecule,
    CalendarOrganism,
    DropdownOrganism,
  ],
  exports: [
    AdInputDirective,
    ProgressBarNavigationDirective,
    SkeletonDirective,
    TableStickyColumnsDirective,
    BadgeAtom,
    IconAtom,
    ButtonMolecule,
    CheckboxMolecule,
    ChipMolecule,
    InputMolecule,
    LabelMolecule,
    RadioMolecule,
    SkeletonMolecule,
    SwitchMolecule,
    TooltipMolecule,
    CalendarOrganism,
    DropdownOrganism,
  ],
})
export class CommonNgDesignSystemModule {}
