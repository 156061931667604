import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as fromAuth from './auth';
import * as fromEquipmentHealth from './home/equipment-health';
import * as fromMeg from './home/meg';
import * as fromTmnDashboard from './home/tmn-dashboard';

export enum Route {
  tmnDashboard = 'tmn-dashboard',
  equipmentHealth = 'equipment-health',
  meg = 'meg',
}

export type RouteType = `${Route}`;

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'signin',
        component: fromAuth.SignInPage,
        data: { animation: true },
        canActivate: [fromAuth.GuestGuard],
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: '/auth/signin',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
    canActivate: [fromAuth.CoreAuthCognitoGuard, fromAuth.UserGuard],
    data: {
      redirectError: 'auth',
    },
    children: [
      {
        path: Route.tmnDashboard,
        component: fromTmnDashboard.TmnDashboardComponent,
        providers: [
          provideState(fromTmnDashboard.tmnDashboardFeature),
          provideEffects(fromTmnDashboard.TmnDashboardEffects),
        ],
      },
      {
        path: Route.equipmentHealth,
        loadComponent: () =>
          import('./home/equipment-health/equipment-health.component').then(
            (m) => m.EquipmentHealthComponent
          ),
        providers: [
          provideState(fromEquipmentHealth.equipmentHealthFeature),
          provideEffects(fromEquipmentHealth.EquipmentHealthEffects),
        ],
      },
      {
        path: Route.meg,
        loadComponent: () =>
          import('./home/meg/meg.component').then((m) => m.MegComponent),
        providers: [
          provideEffects(fromMeg.MegEffects),
          provideState(fromMeg.megFeature),
        ],
      },
      {
        path: '',
        redirectTo: Route.equipmentHealth,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./not-found.component').then((m) => m.NotFoundComponent),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
