<div
  class="dropdown-input"
  [ngClass]="{
    'dropdown-input--has-value':
      selectedElement && selectedElement.value.length > 0 && !disabled,
    'dropdown-input--has-dropdown-open': itemsDropdownIsShown && !disabled,
    'dropdown-input--has-error': error && !disabled,
    'dropdown-input--is-disabled': disabled
  }"
>
  <!-- Input -->
  <div class="dropdown-item__container">
    <button class="dropdown-item__button" (click)="onDropdownClick()">
      <div class="dropdown-item__label-ctn" *ngIf="!hideLabel">
        <span class="text-caption dropdown-item__label">
          {{ placeholder }}
        </span>
        <span
          *ngIf="errorMessage && !selectedElement?.value"
          class="dropdown-item__error-message"
        >
          <span class="dropdown-item__error-message-inner">{{
            errorMessage
          }}</span>
        </span>
      </div>
      <div class="text-caption dropdown-item__selected-value">
        {{ selectedElement?.value ? selectedElement?.value : '' }}
      </div>
      <span
        class="dropdown-item__icon-container"
        [ngClass]="{
          'dropdown-item__icon-container--negative-rotation':
            !itemsDropdownIsShown
        }"
      >
        <span class="dropdown-item__icon-container_content-icon">
          <ng-content select="[icon]"></ng-content>
        </span>
        <ad-icon
          glyph="caret_up"
          class="dropdown-item__icon-container_default-icon"
        ></ad-icon>
      </span>
    </button>
  </div>

  <!-- Menu -->
  <div
    class="dropdown-menu"
    [ngClass]="{
      'dropdown-menu--open': itemsDropdownIsShown,
      'dropdown-menu--close': !itemsDropdownIsShown
    }"
  >
    <div class="dropdown-menu__inner">
      <div class="dropdown-menu__content">
        <ng-content></ng-content>
        <div class="dropdown-list">
          <div class="dropdown-list__content">
            <button
              class="dropdown-list__item text-caption"
              [ngClass]="{ 'item-selected': item.id === selectedElement?.id }"
              *ngFor="let item of items"
              (click)="onMenuItemClick(item)"
            >
              <span class="clamp">{{ item.value }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
