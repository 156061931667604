import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ch-equipment-health-kanban-column-up-to-date',
  standalone: true,
  imports: [TranslateModule],
  styles: [
    `
      :host {
        @apply h-full flex flex-col flex-grow gap-6 justify-center items-center;
      }
    `,
  ],
  template: `
    <img class="px-8" src="assets/img/undraw-no-data.svg" />
    <div class="flex flex-col gap-2">
      <span class="text-center text-xl text-heading text-neutral-50">{{
        'EQUIPMENT_HEALTH.KANBAN.NO_DATA.HEADER' | translate
      }}</span>
      <span class="text-center text-sm text-neutral-300">{{
        'EQUIPMENT_HEALTH.KANBAN.NO_DATA.SUBHEADER' | translate
      }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthKanbanColumnUpToDateComponent {}
