export const colors = {
  'neutral-900': 'rgb(var(--color-neutral-900) / 1)',
  'neutral-800': 'rgb(var(--color-neutral-800) / 1)',
  'neutral-700': 'rgb(var(--color-neutral-700) / 1)',
  'neutral-600': 'rgb(var(--color-neutral-600) / 1)',
  'neutral-500': 'rgb(var(--color-neutral-500) / 1)',
  'neutral-400': 'rgb(var(--color-neutral-400) / 1)',
  'neutral-300': 'rgb(var(--color-neutral-300) / 1)',
  'neutral-200': 'rgb(var(--color-neutral-200) / 1)',
  'neutral-100': 'rgb(var(--color-neutral-100) / 1)',
  'neutral-50': 'rgb(var(--color-neutral-50) / 1)',
  'primary-900': 'rgb(var(--color-primary-900) / 1)',
  'primary-800': 'rgb(var(--color-primary-800) / 1)',
  'primary-700': 'rgb(var(--color-primary-700) / 1)',
  'primary-600': 'rgb(var(--color-primary-600) / 1)',
  'primary-500': 'rgb(var(--color-primary-500) / 1)',
  'primary-400': 'rgb(var(--color-primary-400) / 1)',
  'primary-300': 'rgb(var(--color-primary-300) / 1)',
  'primary-200': 'rgb(var(--color-primary-200) / 1)',
  'primary-100': 'rgb(var(--color-primary-100) / 1)',
  'primary-50': 'rgb(var(--color-primary-50) / 1)',
  'secondary-900': 'rgb(var(--color-secondary-900) / 1)',
  'secondary-800': 'rgb(var(--color-secondary-800) / 1)',
  'secondary-700': 'rgb(var(--color-secondary-700) / 1)',
  'secondary-600': 'rgb(var(--color-secondary-600) / 1)',
  'secondary-500': 'rgb(var(--color-secondary-500) / 1)',
  'secondary-400': 'rgb(var(--color-secondary-400) / 1)',
  'secondary-300': 'rgb(var(--color-secondary-300) / 1)',
  'secondary-200': 'rgb(var(--color-secondary-200) / 1)',
  'secondary-100': 'rgb(var(--color-secondary-100) / 1)',
  'secondary-50': 'rgb(var(--color-secondary-50) / 1)',
  'success-900': 'rgb(var(--color-success-900) / 1)',
  'success-800': 'rgb(var(--color-success-800) / 1)',
  'success-700': 'rgb(var(--color-success-700) / 1)',
  'success-600': 'rgb(var(--color-success-600) / 1)',
  'success-500': 'rgb(var(--color-success-500) / 1)',
  'success-400': 'rgb(var(--color-success-400) / 1)',
  'success-300': 'rgb(var(--color-success-300) / 1)',
  'success-200': 'rgb(var(--color-success-200) / 1)',
  'success-100': 'rgb(var(--color-success-100) / 1)',
  'success-50': 'rgb(var(--color-success-50) / 1)',
  'warning-900': 'rgb(var(--color-warning-900) / 1)',
  'warning-800': 'rgb(var(--color-warning-800) / 1)',
  'warning-700': 'rgb(var(--color-warning-700) / 1)',
  'warning-600': 'rgb(var(--color-warning-600) / 1)',
  'warning-500': 'rgb(var(--color-warning-500) / 1)',
  'warning-400': 'rgb(var(--color-warning-400) / 1)',
  'warning-300': 'rgb(var(--color-warning-300) / 1)',
  'warning-200': 'rgb(var(--color-warning-200) / 1)',
  'warning-100': 'rgb(var(--color-warning-100) / 1)',
  'warning-50': 'rgb(var(--color-warning-50) / 1)',
  'danger-900': 'rgb(var(--color-danger-900) / 1)',
  'danger-800': 'rgb(var(--color-danger-800) / 1)',
  'danger-700': 'rgb(var(--color-danger-700) / 1)',
  'danger-600': 'rgb(var(--color-danger-600) / 1)',
  'danger-500': 'rgb(var(--color-danger-500) / 1)',
  'danger-400': 'rgb(var(--color-danger-400) / 1)',
  'danger-300': 'rgb(var(--color-danger-300) / 1)',
  'danger-200': 'rgb(var(--color-danger-200) / 1)',
  'danger-100': 'rgb(var(--color-danger-100) / 1)',
  'danger-50': 'rgb(var(--color-danger-50) / 1)',
  'shades-0': 'rgb(var(--color-shades-0) / 1)',
  'shades-100': 'rgb(var(--color-shades-100) / 1)',
  orange: 'rgb(var(--color-accent-orange-default) / 1)',
  'orange-default': 'rgb(var(--color-accent-orange-default) / 1)',
  'orange-light': 'rgb(var(--color-accent-orange-light) / 1)',
  'orange-dark': 'rgb(var(--color-accent-orange-dark) / 1)',
  ruby: 'rgb(var(--color-accent-ruby-default) / 1)',
  'ruby-default': 'rgb(var(--color-accent-ruby-default) / 1)',
  'ruby-light': 'rgb(var(--color-accent-ruby-light) / 1)',
  'ruby-dark': 'rgb(var(--color-accent-ruby-dark) / 1)',
  pink: 'rgb(var(--color-accent-pink-default) / 1)',
  'pink-default': 'rgb(var(--color-accent-pink-default) / 1)',
  'pink-light': 'rgb(var(--color-accent-pink-light) / 1)',
  'pink-dark': 'rgb(var(--color-accent-pink-dark) / 1)',
  violet: 'rgb(var(--color-accent-violet-default) / 1)',
  'violet-default': 'rgb(var(--color-accent-violet-default) / 1)',
  'violet-light': 'rgb(var(--color-accent-violet-light) / 1)',
  'violet-dark': 'rgb(var(--color-accent-violet-dark) / 1)',
  indigo: 'rgb(var(--color-accent-indigo-default) / 1)',
  'indigo-default': 'rgb(var(--color-accent-indigo-default) / 1)',
  'indigo-light': 'rgb(var(--color-accent-indigo-light) / 1)',
  'indigo-dark': 'rgb(var(--color-accent-indigo-dark) / 1)',
  navy: 'rgb(var(--color-accent-navy-default) / 1)',
  'navy-default': 'rgb(var(--color-accent-navy-default) / 1)',
  'navy-light': 'rgb(var(--color-accent-navy-light) / 1)',
  'navy-dark': 'rgb(var(--color-accent-navy-dark) / 1)',
  blue: 'rgb(var(--color-accent-blue-default) / 1)',
  'blue-default': 'rgb(var(--color-accent-blue-default) / 1)',
  'blue-light': 'rgb(var(--color-accent-blue-light) / 1)',
  'blue-dark': 'rgb(var(--color-accent-blue-dark) / 1)',
  lime: 'rgb(var(--color-accent-lime-default) / 1)',
  'lime-default': 'rgb(var(--color-accent-lime-default) / 1)',
  'lime-light': 'rgb(var(--color-accent-lime-light) / 1)',
  'lime-dark': 'rgb(var(--color-accent-lime-dark) / 1)',
  forest: 'rgb(var(--color-accent-forest-default) / 1)',
  'forest-default': 'rgb(var(--color-accent-forest-default) / 1)',
  'forest-light': 'rgb(var(--color-accent-forest-light) / 1)',
  'forest-dark': 'rgb(var(--color-accent-forest-dark) / 1)',
  transparent: 'transparent',
  main: 'rgb(var(--color-accent-main) / 1)',
};

export type StyleType = 'light' | 'dark';

export type ColorType =
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger';
export type ColorButtonType =
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'resolve';
export type ColorShadeType =
  | '900'
  | '800'
  | '700'
  | '600'
  | '500'
  | '400'
  | '300'
  | '200'
  | '100'
  | '50';
export type ColorAccentType =
  | 'orange'
  | 'ruby'
  | 'pink'
  | 'violet'
  | 'indigo'
  | 'navy'
  | 'blue'
  | 'lime'
  | 'forest';
export type ColorAccentShadeType = 'default' | 'light' | 'dark';
export type ColorAccentMainType = 'main';
