// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The requested state
 */
export type MegRequestedStateEnum = 'paused' | 'running' | 'cancelled';

export const MegRequestedStateEnum = {
    paused: 'paused' as MegRequestedStateEnum,
    running: 'running' as MegRequestedStateEnum,
    cancelled: 'cancelled' as MegRequestedStateEnum
};

