import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import * as fromGenerated from '../../_generated';
import { EquipmentHealthKanbanColumnCardComponent } from './equipment-health-kanban-column-card.component';
import { EquipmentHealthKanbanColumnUpToDateComponent } from './equipment-health-kanban-column-up-to-date.component';
import { DRAGULA_DIAGNOSTICS_KEY } from './equipment-health.constants';

@Component({
  selector: 'ch-equipment-health-kanban-column',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    EquipmentHealthKanbanColumnCardComponent,
    EquipmentHealthKanbanColumnUpToDateComponent,
    NgIf,
    NgFor,
    DragulaModule,
  ],
  styles: [
    `
      :host {
        overflow-y: auto;

        *::-webkit-scrollbar {
          width: 10px;
          border-radius: 0.5rem;
          background: theme('colors.neutral.900');
        }

        *::-webkit-scrollbar-track {
          border-radius: 0.5rem;
          background: theme('colors.neutral.900');
        }
        *::-webkit-scrollbar-thumb {
          border-radius: 0.5rem;
          background: theme('colors.neutral.800');
          &:hover {
            background: theme('colors.neutral.600');
          }
        }
      }
    `,
  ],
  template: `
    <div
      class="flex flex-col gap-4 p-4 bg-neutral-900/80 rounded-lg h-full overflow-y-auto"
    >
      <div class="flex gap-2 text-sm font-semibold">
        <span class="text-neutral-200">{{
          'EQUIPMENT_HEALTH.KANBAN.COLUMNS.' + status() | translate
        }}</span>
        <span class="text-neutral-500">{{ diagnostics().length }}</span>
      </div>

      <ng-container *ngIf="!diagnostics().length && this.status() === 'todo'">
        <ch-equipment-health-kanban-column-up-to-date>
        </ch-equipment-health-kanban-column-up-to-date>
      </ng-container>

      <div
        [id]="status()"
        [dragula]="DRAGULA_DIAGNOSTICS_KEY"
        class="flex flex-col gap-4 h-full"
      >
        <ng-container *ngFor="let diagnostic of diagnostics()">
          <!--
            The id will be used in EquipmentHealthKanbanComponent an update diagnostic
            with the new status when a dragula drop events occurs.
          -->
          <ch-equipment-health-kanban-column-card
            [id]="diagnostic.equipment_diagnostic_id"
            [diagnostic]="diagnostic"
          ></ch-equipment-health-kanban-column-card>
        </ng-container>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EquipmentHealthKanbanColumnComponent {
  readonly status =
    input.required<fromGenerated.EquipmentHealthDiagnosticView['status']>();
  readonly diagnostics =
    input.required<fromGenerated.EquipmentHealthDiagnosticView[]>();
  readonly DRAGULA_DIAGNOSTICS_KEY = DRAGULA_DIAGNOSTICS_KEY;
}
