import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';
import { PrimeNGConfig } from 'primeng/api';
import * as fromStore from './store';

Settings.defaultLocale = 'fr';

@Component({
  selector: 'ch-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);

  title = 'charly-ui';

  constructor(
    private translate: TranslateService,
    private PrimeNGConfig: PrimeNGConfig
  ) {
    translate.setDefaultLang('fr');
    // @TODO: add the dynamism here for the lang to use
    translate.use('fr');

    this.store.dispatch(fromStore.AppActions.init());
  }

  ngOnInit(): void {
    this.translate
      .get('primeng')
      .subscribe((res) => this.PrimeNGConfig.setTranslation(res));
  }
}
