import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';

import { Papa } from 'ngx-papaparse';

import * as fromDesignSystem from '@common/ng-design-system';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { TableModule } from 'primeng/table';
import { ChatbotConversationMessageAttachmentDto } from '../../../_generated';

@Component({
  standalone: true,
  selector: 'ch-chatbot-message-attachment',
  template: `
    <div [ngSwitch]="attachment().attachmentType">
      <ng-template [ngSwitchCase]="'image'">
        <p-image [src]="getImageUrl()" alt="Image" [preview]="true" />
      </ng-template>
      <ng-template [ngSwitchCase]="'csv'">
        <div class="csv-cta">
          <button
            ds-button
            kind="filled"
            color="primary"
            size="xs"
            theme="dark"
            (click)="showDialog()"
          >
            <div>
              <span class="ds-button-text">
                Voir les {{ csv().data ? csv().data.length : 0 }} entrées</span
              >
            </div>

            <ad-icon
              trailing-content
              class="password-eye"
              glyph="open_new"
              fillColor="neutral-50"
            ></ad-icon>
          </button>
        </div>

        <p-dialog
          header=""
          [resizable]="false"
          [modal]="true"
          [maximizable]="false"
          appendTo="body"
          [(visible)]="dialogVisible"
          [style]="{ width: '75vw' }"
          [contentStyle]="{ height: '50%' }"
        >
          <p-table
            [columns]="csv().meta.fields"
            [value]="csv().data"
            [paginator]="true"
            [resizableColumns]="true"
            showCurrentPageReport="true"
            sortMode="multiple"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            [rows]="15"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [tableStyle]="{ 'min-width': '50rem' }"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  *ngFor="let col of columns"
                  [pSortableColumn]="col"
                  pResizableColumn
                >
                  <div style="display: flex; justify-content: space-between;">
                    <label>{{ 'CSV_COLUMNS.' + col | translate }}</label>
                    <p-sortIcon [field]="col" />
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <ng-container *ngIf="!col.startsWith('is_')">{{
                    rowData[col]
                  }}</ng-container>
                  <ng-container *ngIf="col.startsWith('is_')">
                    <ad-icon
                      [glyph]="rowData[col] ? 'check' : 'checkbox-check'"
                      fillColor="neutral-50"
                    ></ad-icon>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-dialog>
      </ng-template>
    </div>
  `,
  styleUrls: [],
  animations: [],
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    ButtonModule,
    DialogModule,
    ImageModule,
    TableModule,
    TranslateModule,
    fromDesignSystem.CommonNgDesignSystemModule,
  ],
})
export class ChatbotMessageAttachmentComponent {
  dialogVisible = false;

  private readonly csvParser = inject(Papa);

  readonly attachment =
    input.required<ChatbotConversationMessageAttachmentDto>();

  csv = computed(() => {
    const r = this.csvParser.parse(this.attachment().attachment, {
      header: true,
      skipEmptyLines: true,
    });

    r.data.forEach((d: any) => {
      if ('duration' in d) {
        const seconds = d.duration * 60;
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const roundedSeconds = Math.ceil(seconds % 60); // Round up the seconds part
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${roundedSeconds.toString().padStart(2, '0')}`;
        d.duration = formattedTime;
      }

      Object.keys(d).forEach((key) => {
        if (key.endsWith('_time')) {
          d[key] = d[key].slice(0, -4); // Remove the last 4 characters (milliseconds)
        }
      });
    });

    return r;
  });

  getImageUrl() {
    return `data:image/png;base64, ${this.attachment().attachment}`;
  }

  showDialog() {
    this.dialogVisible = true;
  }
}
