import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import * as fromGenerated from '../../../_generated';
import { AppActions } from '../../../store/app.actions';
import { toMessageModel } from './chatbot-utils';
import {
  ChatbotActions,
  ChatbotConversationsApiActions,
} from './chatbot.actions';

export interface ChatbotState
  extends EntityState<fromGenerated.ChatbotConversationExchangeDto> {
  conversationType: fromGenerated.ChatbotConversationType;
  conversationSession: fromGenerated.ChatbotConversationDto;
  flags: {
    isWaiting: boolean;
    clearConversation: boolean;
    useTextToSpeech: boolean;
  };
  stt: {
    isRecording: boolean;
    isFinal: boolean;
    transcription: string;
  };
  ui: {
    isChatEnabled: boolean;
    isChatOpen: boolean;
  };
}

const entityAdapter: EntityAdapter<fromGenerated.ChatbotConversationExchangeDto> =
  createEntityAdapter<fromGenerated.ChatbotConversationExchangeDto>({
    selectId: (ex) => ex.id,
    sortComparer: false,
  });

const { selectAll } = entityAdapter.getSelectors();

const initialChatbotState: ChatbotState = entityAdapter.getInitialState({
  conversationType: 'fiabiliste',
  conversationSession: {} as fromGenerated.ChatbotConversationDto,
  flags: {
    isWaiting: false,
    clearConversation: false,
    useTextToSpeech: false,
  },
  stt: {
    isRecording: false,
    isFinal: false,
    transcription: '',
  },
  ui: {
    isChatOpen: false,
    isChatEnabled: true,
  },
});

export const chatbotFeature = createFeature({
  name: 'chatbot',
  reducer: createReducer(
    initialChatbotState,
    on(AppActions.signOutRequested, () => {
      return {
        ...initialChatbotState,
      };
    }),
    on(ChatbotActions.setConversationType, (state, { conversationType }) => {
      return {
        ...state,
        conversationType,
      };
    }),
    on(ChatbotConversationsApiActions.loadConversation, (state) => {
      return {
        ...state,
      };
    }),
    on(ChatbotActions.questionCreatedSuccess, (state, { newExchange }) => {
      return entityAdapter.addOne(newExchange, state);
    }),
    on(ChatbotActions.questionAnsweredSuccess, (state, { exchange }) => {
      return entityAdapter.updateOne(
        { id: exchange.id, changes: exchange },
        state
      );
    }),
    on(
      ChatbotConversationsApiActions.loadConversationSuccess,
      (state, { conversation }) => {
        const newState = entityAdapter.setAll(conversation.exchanges, state);

        return {
          ...newState,
          conversationSession: conversation,
        };
      }
    ),
    on(ChatbotActions.resetConversationRequested, (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          clearConversation: true,
        },
      };
    }),
    on(
      ChatbotConversationsApiActions.newConversationCreated,
      (state, { conversation }) => {
        const newState = entityAdapter.setAll(conversation.exchanges, state);

        return {
          ...newState,
          conversationSession: conversation,
          flags: {
            ...newState.flags,
            clearConversation: false,
          },
        };
      }
    ),
    on(
      ChatbotConversationsApiActions.conversationSaveSuccess,
      (state, { conversation }) => {
        return {
          ...state,
          conversationSession: conversation,
        };
      }
    ),

    on(ChatbotActions.questionCreatedSuccess, (state) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          isWaiting: true,
          useTextToSpeech: state.stt.isRecording,
        },
      };
    }),

    on(ChatbotActions.questionAnsweredSuccess, (state) => {
      const flags = {
        ...state.flags,
        isWaiting: false,
      };

      return {
        ...state,
        flags,
      };
    }),

    on(
      ChatbotConversationsApiActions.speechToTextRecording,
      (state, { isRecording }) => {
        const stt = {
          ...state.stt,
          isRecording,
        };

        if (!isRecording) {
          stt.transcription = '';
          stt.isFinal = false;
        }

        return {
          ...state,
          stt,
        };
      }
    ),
    on(
      ChatbotConversationsApiActions.speechToTextData,
      (state, { transcription, isFinal }) => {
        return {
          ...state,
          stt: {
            ...state.stt,
            transcription,
            isFinal,
          },
        };
      }
    ),
    on(ChatbotActions.toggleChatDisplay, (state) => ({
      ...state,
      ui: { ...state.ui, isChatOpen: !state.ui.isChatOpen },
    })),
    on(ChatbotActions.enableChat, (state) => ({
      ...state,
      ui: { ...state.ui, isChatEnabled: true },
    })),
    on(ChatbotActions.disableChat, (state) => ({
      ...state,
      ui: { ...state.ui, isChatEnabled: false },
    }))
  ),
  extraSelectors: ({ selectChatbotState, selectUi }) => ({
    /**
     * Select the list of exchanges as a list of ChatbotMessageModel (UI Model)
     */
    selectChatbotMessageModelList: createSelector(
      selectChatbotState,
      (state) => {
        if (!state.entities) {
          return [];
        }

        return selectAll(state).flatMap((e) => toMessageModel(e));
      }
    ),

    /**
     * Select the list of exchanges that are not presentation and have an AI response
     */
    selectChatbotExchangeDtoList: createSelector(
      selectChatbotState,
      (state): fromGenerated.ChatbotConversationExchangeDto[] => {
        if (!state.entities) {
          return [];
        }

        const tmp = selectAll(state).filter((e) => {
          return (
            e.type !== 'presentation' &&
            e.type !== 'waiting' &&
            e.ai &&
            e.ai.text !== ''
          );
        });

        return tmp;
      }
    ),

    selectIsChatOpen: createSelector(selectUi, (ui) => {
      return ui.isChatOpen && ui.isChatEnabled;
    }),
    selectIsChatbotFabDisplayed: createSelector(selectUi, (ui) => {
      return !ui.isChatOpen && ui.isChatEnabled;
    }),
  }),
});
