<label class="input-main">
  <span *ngIf="label.length > 0" class="text-caption--semibold">{{
    label + (required ? ' *' : '')
  }}</span>
  <textarea
    *ngIf="type === 'textarea'; else input"
    class="text-p3"
    [disabled]="disabled"
    [name]="name"
    [placeholder]="placeholder"
    (input)="onInputChange($event)"
    [attr.maxlength]="maxLength"
    >{{ value }}</textarea
  >
  <ng-template #input>
    <div
      class="input-container"
      [ngClass]="{
        'input-container--has-leading-content': leadingContent.children.length,
        'input-container--has-trailing-content': trailingContent.children.length
      }"
    >
      <div
        class="icon-container"
        [ngClass]="{
          'icon-container--has-content': leadingContent.children.length
        }"
        #leadingContent
      >
        <ng-content select="[leading-content]"></ng-content>
      </div>
      <input
        [type]="type"
        [value]="value"
        (input)="onInputChange($event)"
        [disabled]="disabled"
        [name]="name"
        [placeholder]="placeholder"
        [attr.inputmode]="type === 'password' ? 'password' : ''"
        [attr.autocomplete]="type === 'password' ? 'password' : ''"
        class="text-p3"
        (focus)="onFocus()"
        (blur)="onBlur()"
      />
      <div
        class="icon-container"
        [ngClass]="{
          'icon-container--has-content': trailingContent.children.length
        }"
        #trailingContent
      >
        <ng-content select="[trailing-content]"></ng-content>
      </div>
    </div>
  </ng-template>
</label>
<div
  *ngIf="errorMessage && objectKeys(errorMessage).length > 0"
  class="error-message"
  [ngClass]="{ 'error-message--show': errors }"
>
  <div class="error-message__inner">
    <div
      *ngFor="let error of errorMessage | keyvalue"
      class="error-message__item"
      [ngClass]="{
        'error-message__item--show': errors?.hasOwnProperty(error.key)
      }"
    >
      <div class="error-message__item-inner text-caption">
        {{ error.value }}
      </div>
    </div>
  </div>
</div>
