import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';

export const MegActions = createActionGroup({
  source: 'Meg Actions',
  events: {
    Init: emptyProps(),
    'Select Scenario': props<{
      scenarioFilename: string;
    }>(),
    'Start Scenario': props<{
      scenarioFilename: string;
    }>(),
    'Start Scenario Success': emptyProps(),
    'Start Scenario Failure': props<{ error: any }>(),
    'Update Status': props<{ execution: fromGenerated.MegExecution }>(),
    'Update Requested State': props<{
      scenario: string;
      executionId: string;
      changes: {
        requestedState: fromGenerated.MegRequestedStateEnum;
      };
    }>(),
    'Update Requested State Success': emptyProps(),
    'Update Requested State Failure': props<{ error: any }>(),
    'Clear Db Requested': emptyProps(),
    'Clear Db Requested Confirmed': emptyProps(),
    'Clear Db Requested Success': emptyProps(),
    'Clear Db Requested Failure': props<{ error: any }>(),
  },
});
