import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MegState, megFeature } from './store';

@Component({
  selector: 'ch-meg-subheader',
  standalone: true,
  imports: [TranslateModule, AsyncPipe],
  template: `
    <div class="flex gap-2 text-lg font-semibold">
      <span>{{ 'MEG.SUBHEADER' | translate }}:</span>
      <span class="text-neutral-500">{{ scenarioFilename$ | async }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegSubheaderComponent {
  private readonly store = inject<Store<MegState>>(Store);

  readonly scenarioFilename$ = this.store.select(
    megFeature.selectScenarioFilename
  );
}
