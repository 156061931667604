import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AmplifyProvider, CoreAuthAmplifyProvider } from '../providers';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { CoreAuthRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthCognitoGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: AmplifyProvider,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectError: string = route?.data['redirectError'];

    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'pending'
      );

      await this.coreAuthAmplify.currentSessionIdToken();

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'success'
      );

      return true;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.IS_LOGGED_IN,
        'error',
        error
      );

      if (redirectError) {
        console.log('CoreAuthCognitoGuard redirectError', redirectError);
        this.router.navigate([redirectError], {
          queryParams: { returnUrl: state.url },
        });

        return false;
      }

      throw error;
    }
  }
}
