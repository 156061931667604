import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AppActions = createActionGroup({
  source: 'App Actions',
  events: {
    Init: emptyProps(),
    'SignOut Requested': emptyProps(),
    'SignOut Failure': props<{
      errorMsg: Readonly<string>;
    }>(),
    'Set WSS Live': emptyProps(),
    'Set WSS Offline': emptyProps(),
  },
});
