import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';
import {
  selectFeatureFlag,
  selectReady,
} from './store/feature-flags.selectors';
import { FeatureFlagsState } from './store/feature-flags.state';

export const featureFlagsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const featureFlagStore = inject(Store<FeatureFlagsState>);
  const router = inject(Router);

  const featureFlagKey = route.routeConfig?.data?.['featureFlag'];
  const redirectTo = route.routeConfig?.data?.['redirectTo'] || false;

  if (!featureFlagKey) {
    return true;
  }

  return featureFlagStore.select(selectReady()).pipe(
    filter((ready) => ready),
    switchMap(() =>
      featureFlagStore.select(selectFeatureFlag(featureFlagKey)).pipe(
        map((featureFlagEnabled) => {
          if (!featureFlagEnabled && redirectTo) {
            router.navigate([redirectTo]);
          }

          return featureFlagEnabled;
        })
      )
    )
  );
};
