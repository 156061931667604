import { TmnDashboardTbjDataDto } from '../../../_generated';

export class TmnDashboardTbjData implements TmnDashboardTbjDataDto {
  mrfTotalTransportQty = 0;
  mrfTotalProdCrusherQty = 0;
  actualShovelDispoCount = 0;
  actualTruckDispoCount = 0;

  actualTotalTransportQty = 0;
  actualTotalTransportQtyTrend = 0;

  actualTruckMeanCycle = 0;
  actualTruckMeanCycleTrend = 0;

  actualTruckUsagePercent = 0;
  actualTruckUsagePercentTrend = 0;
}
