// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of the attachment.
 */
export type ChatbotMessageAttachmentType = 'csv' | 'image';

export const ChatbotMessageAttachmentType = {
    csv: 'csv' as ChatbotMessageAttachmentType,
    image: 'image' as ChatbotMessageAttachmentType
};

