import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as fromDesignSystem from '@common/ng-design-system';

@Component({
  selector: 'ch-sidebar-button',
  standalone: true,
  imports: [CommonModule, fromDesignSystem.CommonNgDesignSystemModule],
  template: `
    <button
      ds-button
      kind="text"
      size="xs"
      color="neutral"
      class="disabled:pointer-events-none"
      [ngClass]="{ active }"
      [disabled]="disabled"
    >
      <ad-icon [glyph]="glyph"></ad-icon>
    </button>
  `,
  styles: [
    `
      :host {
        button {
          background-color: transparent;
          padding: 10px;

          &.active {
            --ad-icon-fill-color: rgb(var(--color-primary-500));
          }

          &:hover {
            --ad-icon-hover-fill-color: rgb(var(--color-neutral-100));
            --ad-icon-fill-color: rgb(var(--color-neutral-100));

            cursor: pointer;
            background-color: rgb(var(--color-neutral-700));
          }

          ad-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    `,
  ],
})
export class SidebarButtonComponent {
  @Input()
  glyph!: fromDesignSystem.IconAtom['glyph'];
  @Input()
  active = false;
  @Input()
  disabled = false;
}
