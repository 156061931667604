import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxonDate',
  standalone: true,
  pure: true,
})
export class LuxonDatePipe implements PipeTransform {
  transform(dateTime: DateTime | null, format = 'DD'): string {
    return dateTime ? dateTime.toFormat(format) : '';
  }
}
