import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap, tap } from 'rxjs';
import * as fromAuth from '../../auth';
import * as fromModels from '../../models';
import {
  LAUNCH_DARKLY_CLIENT,
  LaunchDarklyContext,
  LaunchDarklyFlagSet,
} from '../launch-darkly.provider';
import { FeatureFlagsActions } from './feature-flags.actions';
import { FeatureFlagsState } from './feature-flags.state';

@Injectable()
export class FeatureFlagsEffects {
  private readonly actions$ = inject(Actions);
  private readonly featureFlagStore = inject(Store<FeatureFlagsState>);
  private readonly launchDarklyClient = inject(LAUNCH_DARKLY_CLIENT);
  private readonly storeRepository = inject(fromAuth.AuthStore.StoreRepository);

  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagsActions.initialLoadRequested),
      map(() =>
        FeatureFlagsActions.initialLoadSuccess({
          featureFlags: Object.entries(this.launchDarklyClient.allFlags()).map(
            ([key, value]) => ({
              id: key,
              value,
            })
          ),
        })
      )
    )
  );

  identifyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.identifyUserRequested),
        exhaustMap(() => this.storeRepository.user$),
        filter((user) => !!user),
        map<fromModels.User, LaunchDarklyContext>((user) => ({
          kind: 'user',
          key: user.email,
          email: user.email,
          name: user.email,
          anonymous: false,
        })),
        switchMap(
          async (ldContext) => await this.launchDarklyClient.identify(ldContext)
        )
      ),
    { dispatch: false }
  );

  onChangeSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.onChangeSyncRequested),
        tap(() => {
          this.launchDarklyClient.on('change', (flags: LaunchDarklyFlagSet) => {
            this.featureFlagStore.dispatch(
              FeatureFlagsActions.onChangeSyncSuccess({
                featureFlags: Object.entries(flags).map(([key, value]) => ({
                  id: key,
                  value: (value?.current as boolean) || false,
                })),
              })
            );
          });
        })
      ),
    { dispatch: false }
  );

  onIdentifyReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.identifyReset),
        tap(() => {
          this.launchDarklyClient.identify({ anonymous: true });
        })
      ),
    { dispatch: false }
  );
}
