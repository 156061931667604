import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { LuxonDatePipe } from '../../pipes/luxon-pipe/luxon.pipe';
import { EquipmentHealthHeaderDatePulseComponent } from './equipment-health-header-date-pulse.component';
import { EquipmentHealthState, equipmentHealthFeature } from './store';

@Component({
  selector: 'ch-equipment-health-date',
  standalone: true,
  imports: [AsyncPipe, LuxonDatePipe, EquipmentHealthHeaderDatePulseComponent],
  template: `
    <div class="grid grid-flow-col gap-3 place-items-center">
      <ch-equipment-health-header-date-pulse
        [variant]="wssConnectionStatus$ | async"
      ></ch-equipment-health-header-date-pulse>
      <span class="font-semibold">{{ date$ | async | luxonDate }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthHeaderDateComponent {
  private readonly store = inject<Store<EquipmentHealthState>>(Store);

  date$ = this.store.select(equipmentHealthFeature.selectDate);
  wssConnectionStatus$ = this.store.select(
    equipmentHealthFeature.selectWssConnectionStatus
  );
}
