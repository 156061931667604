import { createReducer, on } from '@ngrx/store';
import { FeatureFlagsActions } from './feature-flags.actions';
import {
  featureFlagsAdapter,
  featureFlagsInitialState,
} from './feature-flags.state';

export const featureFlagsReducer = createReducer(
  featureFlagsInitialState,
  on(FeatureFlagsActions.identifyUserRequested, (_state) => ({
    ..._state,
    ready: false,
  })),
  on(FeatureFlagsActions.initialLoadSuccess, (_state, { featureFlags }) =>
    featureFlagsAdapter.setAll(featureFlags, { ..._state, ready: true })
  ),
  on(FeatureFlagsActions.onChangeSyncSuccess, (_state, { featureFlags }) =>
    featureFlagsAdapter.upsertMany(featureFlags, { ..._state, ready: true })
  )
);
