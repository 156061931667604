import { Inject, Injectable } from '@angular/core';
import { AmplifyProvider, CoreAuthAmplifyProvider } from '../providers';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { CoreAuthRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthService {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: AmplifyProvider,
    private readonly coreAuthRepository: CoreAuthRepository
  ) {}

  async isSignedIn(): Promise<boolean> {
    return await this.coreAuthAmplify.isSignedIn();
  }

  async signIn({ email, password }: { email: string; password: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'pending'
      );

      this.coreAuthRepository.reset();

      const signin = await this.coreAuthAmplify.signIn(
        email.toLowerCase(),
        password
      );

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'success'
      );

      return signin;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNIN,
        'error',
        error
      );

      throw error;
    }
  }

  async signOut() {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'pending'
      );

      await this.coreAuthAmplify.signOut();
      this.coreAuthRepository.reset();

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'success'
      );

      this.coreAuthRepository.reset();
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNOUT,
        'error',
        error
      );

      // throw new CoreAuthError(CoreAuthError.CORE_AUTH_UNKNOWN_ERROR);
    }
  }

  async getToken() {
    return this.coreAuthAmplify.currentSessionIdToken();
  }
}
