import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { environment } from '../../environments/environment';
import { FeatureFlagsActions } from './store/feature-flags.actions';
import { FeatureFlagsState } from './store/feature-flags.state';

export type LaunchDarklyClient = LaunchDarkly.LDClient;
export type LaunchDarklyContext = LaunchDarkly.LDContext;
export type LaunchDarklyFlagValue = LaunchDarkly.LDFlagValue;
export type LaunchDarklyFlagSet = LaunchDarkly.LDFlagSet;

export const LAUNCH_DARKLY_CLIENT = new InjectionToken<LaunchDarklyClient>(
  'LAUNCH_DARKLY_CLIENT'
);

const ldClient: LaunchDarklyClient = LaunchDarkly.initialize(
  environment.charlyLaunchDarkly.clientSideId,
  {
    anonymous: true,
  },
  {
    bootstrap: 'localStorage',
    application: {
      id: `charly-app-ui-${environment.name}`,
      version: environment.version,
    },
  }
);

export function provideLaunchDarklyInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory:
      (
        _ldClient: LaunchDarkly.LDClient,
        featureFlagStore: Store<FeatureFlagsState>
      ) =>
      async () => {
        await _ldClient.waitForInitialization();

        featureFlagStore.dispatch(FeatureFlagsActions.initialLoadRequested());
        featureFlagStore.dispatch(FeatureFlagsActions.identifyUserRequested());
        featureFlagStore.dispatch(FeatureFlagsActions.onChangeSyncRequested());
      },
    deps: [LAUNCH_DARKLY_CLIENT, Store],
    multi: true,
  };
}

export function provideLaunchDarklyClient(): Provider {
  return {
    provide: LAUNCH_DARKLY_CLIENT,
    useValue: ldClient,
  };
}
