// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of the conversation.
 */
export type ChatbotConversationType = 'equipment-status' | 'fiabiliste';

export const ChatbotConversationType = {
    equipment_status: 'equipment-status' as ChatbotConversationType,
    fiabiliste: 'fiabiliste' as ChatbotConversationType
};

