import { animate, style, transition, trigger } from '@angular/animations';
import { Location, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { ChatbotConversationMessageAttachmentDto } from '../../../_generated';
import { MessageType } from '../models/chatbot-message-type';
import { ChatbotMessageAttachmentComponent } from './chatbot-message-attachment.component';

@Component({
  standalone: true,
  selector: 'ch-chatbot-message',
  template: `
    <div class="ch-msg-container" @chatbotMsgAnimation>
      <div
        class="ch-msg-wrapper"
        [ngClass]="{ 'msg-human': type === 'human' }"
        *ngIf="!_loading"
      >
        <span
          class="ch-msg-content text-p3"
          [innerHTML]="formattedMessage"
        ></span>

        <ch-chatbot-message-attachment
          *ngFor="let attachment of attachments"
          [attachment]="attachment"
        ></ch-chatbot-message-attachment>
      </div>
      <div class="ch-dot-loader" *ngIf="_loading">
        <div class="dot-flashing"></div>
      </div>
    </div>
  `,
  styleUrls: ['./chatbot-message.component.scss'],
  animations: [
    trigger('chatbotMsgAnimation', [
      transition(':enter', [
        style({ opacity: 0.0 }),
        animate('700ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
  imports: [NgClass, NgFor, NgIf, ChatbotMessageAttachmentComponent],
})
export class ChatbotMessageComponent {
  _loading = false;
  @Input()
  set loading(value: boolean) {
    this._loading = value;
  }

  _message = '';
  @Input() set message(value: string) {
    this._message = value;
    this.formatMessage();
  }

  @Input() type: MessageType = MessageType.ai;
  @Input() attachments?: ChatbotConversationMessageAttachmentDto[] = [];

  formattedMessage: SafeHtml = '';

  pathName = '';

  _album: any[] = [];

  constructor(private domSanitizer: DomSanitizer, private location: Location) {
    this.pathName = this.location.path();
  }
  private formatUrl(date: DateTime, html: string, match: string): string {
    const dateStr = date.toISODate();
    return html.replace(
      match,
      `<a href="${this.pathName}#${dateStr}" data="${dateStr}">${match}</a>`
    );
  }

  private formatMessage() {
    if (typeof this._message !== 'string') {
      this.formattedMessage = this._message;
      return;
    }

    let html = this._message.replace(/1er/gi, '1');

    html = html.replace(/fevrier/gi, 'février');
    html = html.replace(/aout/gi, 'août');
    html = html.replace(/decembre/gi, 'décembre');

    const regex2 = /\b\d{4}-\d{2}-\d{2}\b/g;

    const matches2 = html.match(regex2);
    if (matches2) {
      matches2.forEach((match) => {
        const date = DateTime.fromISO(match, {
          locale: 'fr',
          zone: 'America/Toronto',
        });

        html = this.formatUrl(date, html, match);
      });
    }

    // search for dates and replace them with an hyperlink
    const regex =
      /\b\d{1,2}\s+(?:janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)\s+\d{4}\b/gi;

    const matches = html.match(regex);
    if (matches) {
      matches.forEach((match) => {
        const date = DateTime.fromFormat(match, 'd LLLL yyyy', {
          locale: 'fr',
          zone: 'America/Toronto',
        });
        html = this.formatUrl(date, html, match);
      });
    }

    html = html.replace(/\n/g, '<br/>');

    const safe = this.domSanitizer.bypassSecurityTrustHtml(html);
    this.formattedMessage = safe;
  }
}
