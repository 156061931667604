import { InjectionToken as CoreAuthConfigProvider } from '@angular/core';
import { environment } from 'apps/charly-ui/src/environments/environment';
import { CoreAuthConfig } from '../interfaces';

export type providerType = CoreAuthConfig;
export const providerToken = new CoreAuthConfigProvider<CoreAuthConfig>(
  'CORE-AUTH-CONFIG'
);

export const provideCoreAuthConfigProvider = () => ({
  provide: providerToken,
  useValue: {
    auth: environment.auth,
    api_endpoints: [
      environment.charlyApi.baseUrl,
      environment.charlyTts.baseUrl,
    ],
  },
});
