import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chTrend',
  standalone: true,
})
export class TrendPipe implements PipeTransform {
  transform(trend: number): string {
    return `${trend > 0 ? '+' : ''}${Math.round(trend)}%`;
  }
}
