<div class="login">
  <form class="" id="test" [formGroup]="form" (ngSubmit)="signIn()">
    <div class="login-logo">
      <img class="logo-am desktop" src="assets/img/logo-full.svg" />
    </div>

    <div class="login-modal">
      <div class="login-header">
        <span
          class="login-title text-p1--semibold"
          data-testId="login-label-title"
        >
          Connexion</span
        >
        <div class="remember-me-container">
          <label class="text-p3">Rester connecté</label>
          <ad-switch
            size="sm"
            [checked]="rememberMe$ | async"
            (change)="onRememberMeChanged($event)"
          ></ad-switch>
        </div>
      </div>
      <div class="login-fields">
        <div class="">
          <div class="text-caption--semibold">
            {{ 'SIGNIN.EMAIL' | translate }}
          </div>
          <ad-input
            textSize="S"
            formControlName="email"
            [required]="true"
            inputmode="email"
            autocomplete="email"
            [placeholder]="'SIGNIN.EMAIL' | translate"
            [errors]="(emailBlurred && form.get('email')?.touched) ? form.get('email')?.errors! : null"
            [errorMessage]="emailErrorMessage"
            (blur)="onBlurEmail()"
          />
        </div>

        <div class="">
          <div class="text-caption--semibold">
            {{ 'SIGNIN.PASSWORD' | translate }}
          </div>
          <ad-input
            textSize="S"
            [type]="showPassword ? 'text' : 'password'"
            formControlName="password"
            [required]="true"
            [placeholder]="'SIGNIN.PASSWORD' | translate"
            [errors]="form.get('password')?.touched ? form.get('password')?.errors! : null"
            [errorMessage]="passwordErrorMessage"
            (blur)="onBlurPassword()"
          >
            <ad-icon
              trailing-content
              class="password-eye"
              [glyph]="passwordIcon"
              color="neutral-400"
              hoverStrokeColor="shades-0"
              [fillColor]="showPassword ? 'transparent' : 'transparent'"
              (click)="toggleShowPassword()"
            ></ad-icon>
          </ad-input>
        </div>

        <!-- <a class="forget-password-link text-p3">
            {{ 'SIGNIN.FORGOT_PASSWORD' | translate }}
          </a> -->
      </div>

      <div class="login-cta">
        <button
          ds-button
          kind="filled"
          color="primary"
          size="md"
          type="submit"
          theme="dark"
          [disabled]="!form.valid"
        >
          <div>
            <span class="ds-button-text"
              >{{ 'SIGNIN.SIGN_IN' | translate }}</span
            >
            <div class="spinner-wrapper" *ngIf="showSpinner">
              <div class="spinner"></div>
            </div>
          </div>
        </button>
      </div>
      <div class="remember-me-container mobile">
        <label class="text-p3">Rester connecté</label>
        <ad-switch
          size="sm"
          [checked]="rememberMe$ | async"
          (change)="onRememberMeChanged($event)"
        ></ad-switch>
      </div>
    </div>
  </form>
  <div class="logo-texture"></div>
</div>
