import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, finalize, map, of } from 'rxjs';
import * as fromServices from '../../services';
import * as fromStore from '../store';

export const UserGuard: CanActivateFn = () => {
  const userService = inject(fromServices.UserService);
  const storeRepository = inject(fromStore.StoreRepository);
  const navigateService = inject(fromServices.NavigateService);

  const isUserLoaded = !!storeRepository.storeValue.user;
  /**
   * If user is already loader, we can skip the global loading
   * and allow the module/page to be activated.
   *
   * We refresh the user data in the background.
   *
   * NOTE: We might not want this behavior.
   */
  if (isUserLoaded) {
    userService.userControllerGetMe().subscribe();
    return true;
  }

  storeRepository.store.update((store: any) => ({
    ...store,
    isLoading: true,
  }));

  return userService.userControllerGetMe().pipe(
    map(() => true),
    catchError((error) => {
      console.error('UserGuard', error);
      navigateService.back();
      return of(false);
    }),
    finalize(() => {
      storeRepository.store.update((store: any) => ({
        ...store,
        isLoading: false,
      }));
    })
  );
};
