import { Inject, Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import {
  excludeKeys,
  localStorageStrategy,
  persistState,
} from '@ngneat/elf-persist-state';
import { selectRequestStatus, updateRequestStatus } from '@ngneat/elf-requests';
import { Observable, lastValueFrom } from 'rxjs';

import { CoreAuthStoreProvider } from '../providers';
import {
  AuthProps,
  AuthRequest,
  providerType,
} from '../providers/core-auth-store.provider';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthRepository {
  constructor(
    @Inject(CoreAuthStoreProvider.providerToken)
    public authStore: CoreAuthStoreProvider.providerType
  ) {
    this.persistStateAsync();
  }

  public get store$(): providerType {
    return this.authStore;
  }

  public get storeValue(): AuthProps {
    return this.authStore.getValue();
  }

  public get isRemembered(): Observable<boolean | undefined> {
    return this.authStore.pipe(select(({ isRemembered }: any) => isRemembered));
  }
  public setIsRemembered(isRemembered: boolean): void {
    this.authStore.update((state: any) => ({ ...state, isRemembered }));
  }

  public selectRequestStatus(
    request: CoreAuthStoreProvider.AuthRequest
  ): Observable<{
    value: 'idle' | 'pending' | 'success' | 'error';
    error?: Error;
  }> {
    return this.authStore.pipe<any>(selectRequestStatus(request));
  }

  updateRequestStatus(
    key: AuthRequest,
    value: Parameters<typeof updateRequestStatus>[1] | 'error',
    error?: Parameters<typeof updateRequestStatus>[2]
  ) {
    return this.authStore.update(
      updateRequestStatus(key as AuthRequest, value as any, error)
    );
  }

  private async persistStateAsync(): Promise<void> {
    await lastValueFrom(
      persistState(this.authStore, {
        key: this.authStore.name,
        storage: localStorageStrategy,
        source: () => this.authStore.pipe(excludeKeys(['requestsStatus'])),
      }).initialized$
    );
  }

  public reset() {
    this.authStore.reset();
  }
}
