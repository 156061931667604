import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

import { IconAtomType, IconAtomWeatherEnum } from './icon.enum';

@Component({
  selector: 'ad-icon',
  template: `
    <svg
      [ngClass]="getSVGClass()"
      [class]="svgClass"
      [attr.width]="defaultWidth"
      [attr.height]="defaultWidth"
      [attr.viewBox]="'0 0 ' + defaultWidth + ' ' + defaultWidth"
    >
      <use [attr.href]="glyphUrl"></use>
    </svg>
    <ng-content select="[iconContent]"></ng-content>
  `,
  styleUrls: ['./icon.component.scss'],
})
export class IconAtom implements OnInit {
  _color: keyof typeof colors | undefined = undefined;
  _fillColor: keyof typeof colors | undefined = undefined;
  _hoverStrokeColor: keyof typeof colors | undefined = undefined;
  _hoverFillColor: keyof typeof colors | undefined = undefined;

  @Input()
  glyph: IconAtomType = 'bus';

  @Input()
  svgClass = '';

  @HostBinding('style.--ad-icon-fill-color')
  get styleFillColor(): string {
    if (this._fillColor) {
      return colors[this._fillColor];
    }

    return '';
  }
  @HostBinding('style.--ad-icon-stroke-color')
  get styleStrokeColor(): string {
    if (this._color) {
      return colors[this._color];
    }
    return '';
  }

  @HostBinding('style.--ad-icon-hover-stroke-color')
  get styleHoverStrokeColor(): string {
    if (this._hoverStrokeColor) {
      return colors[this._hoverStrokeColor];
    }
    return '';
  }

  @HostBinding('style.--ad-icon-hover-fill-color')
  get styleHoverFillColor(): string {
    if (this._hoverFillColor) {
      return colors[this._hoverFillColor];
    }
    return '';
  }

  @Input()
  public get color(): keyof typeof colors | undefined {
    return this._color;
  }
  public set color(val: keyof typeof colors | undefined) {
    this._color = val;
  }

  @Input()
  public get fillColor(): keyof typeof colors | undefined {
    return this._fillColor;
  }
  public set fillColor(val: keyof typeof colors | undefined) {
    this._fillColor = val;
  }

  @Input()
  public get hoverFillColor(): keyof typeof colors | undefined {
    return this._hoverFillColor;
  }
  public set hoverFillColor(val: keyof typeof colors | undefined) {
    this._hoverFillColor = val;
  }

  @Input()
  public get hoverStrokeColor(): keyof typeof colors | undefined {
    return this._hoverStrokeColor;
  }
  public set hoverStrokeColor(val: keyof typeof colors | undefined) {
    this._hoverStrokeColor = val;
  }

  @Input()
  defaultWidth = 24;

  @Input()
  stroke: keyof typeof colors | undefined = undefined;

  // Some icons aren't pathed out in a way that fill would work by default,
  // so this array is used to force a default shift on certain icons
  useColorAsStrokeFor: IconAtomType[] = ['check', 'email-stroke'];

  ngOnInit(): void {
    this.defaultWidth =
      this.glyph in IconAtomWeatherEnum ? 40 : this.defaultWidth;
  }

  getSVGClass() {
    return this.useColorAsStrokeFor.includes(this.glyph)
      ? 'alternate-colors'
      : '';
  }

  public get glyphUrl(): string {
    const url = `/assets/icons/icons.svg#${this.glyph}`;
    return url;
  }
}
