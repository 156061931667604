import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { tap } from 'rxjs';
import { EquipmentHealthDiagnosticView } from '../../_generated';
import { EquipmentHealthKanbanColumnComponent } from './equipment-health-kanban-column.component';
import { DRAGULA_DIAGNOSTICS_KEY } from './equipment-health.constants';
import {
  EquipmentHealthActions,
  EquipmentHealthState,
  equipmentHealthFeature,
} from './store';

@Component({
  selector: 'ch-equipment-health-kanban',
  standalone: true,
  imports: [EquipmentHealthKanbanColumnComponent, AsyncPipe],
  template: `
    <div
      class="grid grid-cols-1 auto-rows-min lg:grid-cols-3 lg:auto-rows-auto gap-6 mb-6 h-full *:h-full"
    >
      <!--
        For every column, we set the id so we can use it in the drop event
        to know what status to update the diagnostic to.
      -->
      <ch-equipment-health-kanban-column
        id="todo"
        status="todo"
        [diagnostics]="todo()"
      ></ch-equipment-health-kanban-column>
      <ch-equipment-health-kanban-column
        id="ongoing"
        status="ongoing"
        [diagnostics]="ongoing()"
      ></ch-equipment-health-kanban-column>
      <ch-equipment-health-kanban-column
        id="incharge"
        status="incharge"
        [diagnostics]="incharge()"
      ></ch-equipment-health-kanban-column>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthKanbanComponent {
  private readonly store = inject<Store<EquipmentHealthState>>(Store);
  private readonly dragulaService = inject(DragulaService);

  readonly todo = toSignal(
    this.store.select(equipmentHealthFeature.selectDiagnosticsByStatus('todo')),
    { initialValue: [] }
  );
  readonly ongoing = toSignal(
    this.store.select(
      equipmentHealthFeature.selectDiagnosticsByStatus('ongoing')
    ),
    { initialValue: [] }
  );
  readonly incharge = toSignal(
    this.store.select(
      equipmentHealthFeature.selectDiagnosticsByStatus('incharge')
    ),
    { initialValue: [] }
  );

  constructor() {
    this.dragulaService
      .drop(DRAGULA_DIAGNOSTICS_KEY)
      .pipe(
        takeUntilDestroyed(),
        tap(({ el, target }) => {
          this.store.dispatch(
            EquipmentHealthActions.updateDiagnosticStatus({
              /**
               * The el.id has been set by the EquipmentHealthKanbanColumnComponent
               * when rendering the list of cards.
               */
              id: +el.id,
              changes: {
                /**
                 * The target.id is the status of the column where the card was dropped.
                 */
                status: target.id as EquipmentHealthDiagnosticView.StatusEnum,
              },
            })
          );
        })
      )
      .subscribe();
  }
}
