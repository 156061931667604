import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import * as fromHelpers from '../../helpers';

@Component({
  selector: 'ch-equipment-health-header-date-pulse',
  standalone: true,
  template: `
    <div [className]="className()">
      <div
        class="size-3 opacity-0 group-[.live]:opacity-40 group-[.live]:animate-ping"
      ></div>
      <div
        class="size-3 opacity-0 group-[.timetravel]:opacity-40 group-[.timetravel]:animate-ping"
      ></div>
      <div class="size-2 group-[.offline]:animate-pulse"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthHeaderDatePulseComponent {
  private readonly pulseVariants = cva(
    'grid place-items-center *:row-start-1 *:col-start-1 *:rounded-full group',
    {
      variants: {
        variant: {
          live: '*:bg-success-400 live',
          offline: '*:bg-neutral-200 offline',
          timetravel: '*:bg-danger-400 timetravel',
        },
      },
    }
  );
  readonly variant =
    input<VariantProps<typeof this.pulseVariants>['variant']>('offline');
  readonly className = computed(() =>
    fromHelpers.cn(this.pulseVariants({ variant: this.variant() }))
  );
}
