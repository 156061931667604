// @ts-nocheck
/**
 * NIC
 * The NIC API description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatbotExchangeType } from './chatbotExchangeType';
import { ChatbotConversationMessageDto } from './chatbotConversationMessageDto';


export interface ChatbotConversationExchangeDto { 
    /**
     * Unique identifier of the exchange.
     */
    id: string;
    type: ChatbotExchangeType;
    /**
     * Human sentence.
     */
    human?: ChatbotConversationMessageDto;
    /**
     * AI sentence.
     */
    ai?: ChatbotConversationMessageDto;
}
export namespace ChatbotConversationExchangeDto {
}


