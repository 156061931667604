import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EquipmentHealthHeaderDateComponent } from './equipment-health-header-date.component';
import { EquipmentHealthState, equipmentHealthFeature } from './store';

@Component({
  selector: 'ch-equipment-health-subheader',
  standalone: true,
  imports: [TranslateModule, AsyncPipe, EquipmentHealthHeaderDateComponent],
  template: `
    <div class="flex gap-2 text-lg font-semibold">
      <span>{{ 'EQUIPMENT_HEALTH.SUBHEADER' | translate }}</span>
      <span class="text-neutral-500">{{ allDiagnosticsCount$ | async }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthSubheaderComponent {
  private readonly store = inject<Store<EquipmentHealthState>>(Store);

  readonly allDiagnosticsCount$ = this.store.select(
    equipmentHealthFeature.selectDiagnosticsTotal
  );
}
