import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  skipWhile,
  switchMap,
  take,
} from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromServices from '../../../services';
import { MegActions } from './meg.actions';

@Injectable()
export class MegEffects {
  private readonly actions$ = inject(Actions);
  private readonly wsService = inject(fromServices.WebsocketService);
  private readonly megService = inject(fromGenerated.MegService);
  private readonly translateService = inject(TranslateService);

  // On init, subscribe to the websocket topic but wait until the connection is established
  wsSubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MegActions.init),
      switchMap(() =>
        this.wsService.connectionStatus$.pipe(
          skipWhile((connected) => !connected),
          take(1)
        )
      ),
      exhaustMap(() => {
        return this.wsService
          .websocketSubscribe('meg')
          .pipe(
            map((message: fromServices.WsTopicData) =>
              MegActions.updateStatus({ execution: message.data })
            )
          );
      })
    )
  );

  onUpdateRequestedState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MegActions.updateRequestedState),
      switchMap(({ scenario, executionId, changes }) =>
        this.megService
          .megControllerUpdateRequestedState(scenario, executionId, {
            requestedState: changes.requestedState,
          })
          .pipe(
            map(() => MegActions.updateRequestedStateSuccess()),
            catchError((error) =>
              of(
                MegActions.updateRequestedStateFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  onClearDbRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MegActions.clearDbRequested),
      switchMap(() =>
        of(confirm(this.translateService.instant('MEG.CLEAR_DB_CONFIRM'))).pipe(
          filter((confirmed) => confirmed),
          exhaustMap(() => of(MegActions.clearDbRequestedConfirmed()))
        )
      )
    )
  );

  onClearDbRequestedConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MegActions.clearDbRequestedConfirmed),
      switchMap(() =>
        this.megService.megControllerClearDatabase().pipe(
          map(() => MegActions.clearDbRequestedSuccess()),
          catchError((error) =>
            of(
              MegActions.clearDbRequestedFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  onStartScenarioRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MegActions.startScenario),
      switchMap(({ scenarioFilename }) =>
        this.megService.megControllerStartScenario(scenarioFilename).pipe(
          map(() => MegActions.startScenarioSuccess()),
          catchError((error) =>
            of(
              MegActions.startScenarioFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
}
