import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EquipmentHealthHeaderDateComponent } from './equipment-health-header-date.component';

@Component({
  selector: 'ch-equipment-health-header',
  standalone: true,
  imports: [TranslateModule, EquipmentHealthHeaderDateComponent],
  styles: [
    `
      :host {
        @apply border-b border-neutral-800;
      }
    `,
  ],
  template: `
    <div class="flex justify-between items-center">
      <span class="font-heading text-2xl">{{
        'EQUIPMENT_HEALTH.TITLE' | translate
      }}</span>
      <ch-equipment-health-date></ch-equipment-health-date>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHealthHeaderComponent {}
