import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, from, map, of } from 'rxjs';
import * as fromServices from '../services';
import { AppActions } from './app.actions';

@Injectable()
export class AppEffects {
  private readonly actions$ = inject(Actions);

  private readonly wsService = inject(fromServices.WebsocketService);
  private readonly globalService = inject(fromServices.GlobalService);

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.signOutRequested),
        exhaustMap(() => {
          return from(this.globalService.signOut()).pipe(
            catchError((error: { message: string }) =>
              of(
                AppActions.signOutFailure({
                  errorMsg: error.message,
                })
              )
            )
          );
        })
      ),
    { dispatch: false }
  );

  onInitWssLiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.init),
      exhaustMap(() => this.wsService.connect()),
      map((connected) => {
        // Keep to help debugging
        console.log('connected', connected);
        if (connected) {
          return AppActions.setWSSLive();
        } else {
          return AppActions.setWSSOffline();
        }
      })
    )
  );
}
