import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.type';
import * as fromGenerated from '../_generated';
import * as fromInterfaces from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly configurationService = inject(
    fromGenerated.ConfigurationService
  );

  getConfig(): Environment {
    return environment;
  }

  public async getSttConfig(): Promise<fromInterfaces.SttConfig> {
    const apiKey = await firstValueFrom(
      this.configurationService.configurationControllerGetSttApiKey()
    );

    return {
      apiKey: apiKey.apiKey,
      emptyTranscriptTimeoutMs:
        environment.stt.emptyTranscriptTimeoutSeconds * 1000,
    };
  }
}
