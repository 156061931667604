import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs';
import * as fromGenerated from '../../_generated';
import * as fromChatbot from '../chatbot';
import { MegHeaderComponent } from './meg-header.component';
import { MegStatusComponent } from './meg-status.component';
import { MegSubheaderComponent } from './meg-subheader.component';
import { MegActions } from './store';

@Component({
  selector: 'ch-meg',
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    MegHeaderComponent,
    MegSubheaderComponent,
    MegStatusComponent,
  ],
  template: `
    <div
      class="grid grid-rows-[80px_80px_calc(100vh-160px-24px)] *:place-content-center *:h-full *:px-10"
    >
      <ch-meg-header></ch-meg-header>
      <ch-meg-subheader></ch-meg-subheader>
      <ch-meg-status></ch-meg-status>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  private readonly chatbotStore =
    inject<Store<fromChatbot.ChatbotState>>(Store);
  private readonly store = inject<Store<fromGenerated.MegExecution>>(Store);

  constructor() {
    this.route.queryParams
      .pipe(
        takeUntilDestroyed(),
        filter<Params>((params) => !!params),
        tap<Params>((params) => {
          this.store.dispatch(
            MegActions.selectScenario({
              scenarioFilename: params['scenario'],
            })
          );
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.chatbotStore.dispatch(fromChatbot.ChatbotActions.disableChat());
    this.store.dispatch(MegActions.init());
  }
}
