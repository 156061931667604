import { Injectable } from '@angular/core';
import {
  createClient,
  DeepgramClient,
  LiveClient,
  LiveSchema,
  LiveTranscriptionEvents,
} from '@deepgram/sdk';
import { Store } from '@ngrx/store';
import * as fromChatbot from '../home/chatbot';
import * as fromInterfaces from '../interfaces';
import * as fromUtils from '../utils';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class SpeechToTextService {
  private deepgram: DeepgramClient | undefined;
  private liveClient: LiveClient | undefined;

  private microphone: MediaRecorder | undefined;
  private userMedia: MediaStream | undefined;
  private config: fromInterfaces.SttConfig | undefined;
  private emptyTranscriptStartTimestamp: number | undefined;

  constructor(private configService: ConfigService, private store: Store) {}

  async startRecording(language: string) {
    try {
      await this.createDeepgramClient(language);

      await this.startStreaming();

      await this.openMicrophone();

      return true;
    } catch (e) {
      console.error('startRecording error', e);
      this.stopRecording();
      return false;
    }
  }

  async stopRecording() {
    try {
      this.closeMicrophone();

      if (this.liveClient) {
        this.liveClient.finish();
      }
      this.liveClient = undefined;

      this.deepgram = undefined;

      this.emptyTranscriptStartTimestamp = undefined;
    } catch (e) {
      console.error('stopRecording error', e);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return false;
    }
  }

  private async createDeepgramClient(language: string) {
    this.config = await this.configService.getSttConfig();

    this.deepgram = createClient(this.config.apiKey);

    this.liveClient = this.deepgram.listen.live({
      interim_results: true,
      smart_format: true,
      model: 'nova-2',
      language: language,
      endpointing: 200,
      no_delay: true,
      replace: [
        'm quatre-cent-dix-sept:M0414',
        'm quatre cent dix-sept:M0414',
        'm zéro quatre-cent-dix-sept:M0414',
        'm zéro quatre cent dix-sept:M0414',
        'm quatre-cent-dix-sept:M0417',
        'm quatre cent dix-sept:M0417',
        'm zéro quatre-cent-dix-sept:M0417',
        'm zéro quatre cent dix-sept:M0417',
      ],
    } as LiveSchema);
  }

  private async startStreaming() {
    return new Promise<void>((resolve) => {
      console.log('startStreaming');

      fromUtils.assertIsDefined(this.liveClient);

      this.liveClient.on(LiveTranscriptionEvents.Open, async () => {
        fromUtils.assertIsDefined(this.liveClient);

        console.log('LiveClient: connected to websocket');

        this.liveClient.on(LiveTranscriptionEvents.Transcript, (data: any) => {
          // console.log('Transcript: ', data);
          console.log('Transcript received.');

          const transcript = data.channel.alternatives[0].transcript;

          if (transcript && transcript.trim().length > 0) {
            this.emptyTranscriptStartTimestamp = undefined;

            this.store.dispatch(
              fromChatbot.ChatbotConversationsApiActions.speechToTextData({
                transcription: transcript,
                isFinal: data.is_final,
              })
            );
          } else {
            if (!this.emptyTranscriptStartTimestamp) {
              this.emptyTranscriptStartTimestamp = Date.now();
            } else {
              fromUtils.assertIsDefined(this.config);

              const now = Date.now();
              if (
                now - this.emptyTranscriptStartTimestamp >
                this.config?.emptyTranscriptTimeoutMs
              ) {
                console.log(
                  'Empty transcript for too long, stopping recording'
                );
                this.stopRecording();

                this.store.dispatch(
                  fromChatbot.ChatbotConversationsApiActions.speechToTextRecording(
                    {
                      isRecording: false,
                    }
                  )
                );
              }
            }
          }
        });

        this.liveClient.on(LiveTranscriptionEvents.Error, (e: any) =>
          console.error('Deepgram Error', e)
        );

        this.liveClient.on(LiveTranscriptionEvents.Warning, (e: any) =>
          console.warn('Deepgram Warning', e)
        );

        this.liveClient.on(LiveTranscriptionEvents.Close, (e: any) => {
          console.log('Deepgram closed', e);
          this.stopRecording();

          this.store.dispatch(
            fromChatbot.ChatbotConversationsApiActions.speechToTextRecording({
              isRecording: false,
            })
          );
        });

        resolve();
      });
    });
  }

  private async openMicrophone() {
    this.microphone = await this.getMicrophone();

    await this.microphone.start(250);

    this.microphone.onstart = () => {
      console.log('client: microphone opened');
    };

    this.microphone.onstop = () => {
      console.log('client: microphone closed');
      this.microphone = undefined;
    };

    this.microphone.ondataavailable = (e: any) => {
      const data = e.data;
      if (this.liveClient && data.size > 0) {
        // console.log('ondataavailable', data.size);
        this.liveClient.send(data);
      }
    };
  }

  private closeMicrophone() {
    if (this.userMedia) {
      const tracks = this.userMedia.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.userMedia = undefined;
    }

    if (this.microphone) {
      this.microphone.stop();
      this.microphone = undefined;
    }
  }

  private async getMicrophone() {
    this.userMedia = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    return new MediaRecorder(this.userMedia);
  }
}
