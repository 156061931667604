import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';

import classNames from 'classnames';

import { IconAtom, IconAtomType } from '../../../atoms';
import { LabelMolecule } from '../../../molecules/input-field/label/label.component';
import { InputIconType, InputLabelType, InputStateType } from '../input.enum';

@Directive({
  selector: '[adInput]',
})
export class AdInputDirective implements OnInit, OnChanges {
  @Input() leadingIcon?: IconAtomType;

  @Input() trailingIcon?: IconAtomType;

  @Input() label?: InputLabelType;

  @Input() textSize: 'XS' | 'S' | 'M' | 'L' = 'M';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value?: any; // Could be anything

  @Input() placeholder?: string;

  @Input() required = false;

  @Input() error = false;

  @Input() autoHide = false;

  disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() valueChange = new EventEmitter<any>();

  leadingIconComponent?: ComponentRef<IconAtom>;
  trailingIconComponent?: ComponentRef<IconAtom>;
  labelComponent?: ComponentRef<LabelMolecule>;

  constructor(
    private vcRef: ViewContainerRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}
  ngOnInit(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const parentElement = inputElement.parentNode;
    const divElement = this.renderer.createElement('div');

    this.el.nativeElement.placeholder = this.placeholder;
    this.el.nativeElement.value = this.value;
    this.disabled = this.el.nativeElement.disabled;

    // Value could be anything
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.el.nativeElement.addEventListener(
      'keyup',
      (event: { target: { value: string } }) => {
        this.el.nativeElement.value = event.target.value;
        this.value = event.target.value;
        this.valueChange.emit(this.value);
        this.setInput(inputElement);
      }
    );

    this.renderer.insertBefore(parentElement, divElement, inputElement);
    this.renderer.appendChild(divElement, inputElement);

    this.setLeadingIcon(divElement);
    this.setTrailingIcon(divElement);
    this.setLabel(divElement);
    this.setContainer(divElement);
    this.setInput(inputElement);
  }

  ngOnChanges(): void {
    this.disabled = this.el.nativeElement.disabled;
    const inputElement = this.el.nativeElement as HTMLInputElement;
    this.setInput(inputElement);

    if (this.leadingIconComponent && this.leadingIcon) {
      this.setIconParams(this.leadingIconComponent.instance, this.leadingIcon);
    }
    if (this.trailingIconComponent && this.trailingIcon) {
      this.setIconParams(
        this.trailingIconComponent.instance,
        this.trailingIcon
      );
    }
    if (this.labelComponent) {
      this.setLabelParams(this.labelComponent.instance);
    }
  }

  private setLeadingIcon(divElement: HTMLElement): void {
    if (!this.leadingIcon) {
      return;
    }

    this.leadingIconComponent = this.configureDynamicComponent<IconAtom>(
      IconAtom,
      divElement,
      'ad-input__leading-icon'
    );
    this.setIconParams(this.leadingIconComponent?.instance, this.leadingIcon);
  }

  private setTrailingIcon(divElement: HTMLElement): void {
    if (!this.trailingIcon) {
      return;
    }

    this.trailingIconComponent = this.configureDynamicComponent<IconAtom>(
      IconAtom,
      divElement,
      'ad-input__trailing-icon'
    );
    this.setIconParams(this.trailingIconComponent?.instance, this.trailingIcon);
  }

  private setLabel(divElement: HTMLElement): void {
    if (!this.label) {
      return;
    }

    this.labelComponent = this.configureDynamicComponent<LabelMolecule>(
      LabelMolecule,
      divElement,
      'ad-input__label'
    );
    this.setLabelParams(this.labelComponent?.instance);
  }

  private setContainer(divElement: HTMLElement): void {
    divElement.classList.add('ad-input-container');
  }

  private setInput(inputElement: HTMLInputElement): void {
    inputElement.className = '';
    inputElement.classList.add(...this.inputClasses.split(' '));
    // inputElement.disabled = this.disabled;
  }

  private configureDynamicComponent<C>(
    component: Type<C>,
    parentElement: HTMLElement,
    elementClasses: string
  ) {
    const componentRef = this.vcRef.createComponent(component);
    const htmlElement = componentRef.location.nativeElement;
    this.renderer.appendChild(parentElement, htmlElement);
    htmlElement.classList.add(...elementClasses.split(' '));
    return componentRef;
  }

  private setIconParams(
    iconComponent: IconAtom | undefined,
    glyph: IconAtomType
  ): void {
    if (iconComponent instanceof IconAtom) {
      iconComponent.color = this.disabled ? 'neutral-400' : 'neutral-700';
      iconComponent.glyph = glyph;
      Object.assign(iconComponent, this);
    }
  }

  private setLabelParams(labelComponent?: LabelMolecule): void {
    if (labelComponent) {
      labelComponent.label = this.label?.text;
      labelComponent.disabled = this.disabled;
      if (this.label?.leadingIcon) {
        labelComponent.iconLeft = this.label?.leadingIcon;
        labelComponent.iconLeftFill = 'neutral-700';
      }
      if (this.label?.trailingIcon) {
        labelComponent.iconRight = this.label?.trailingIcon;
        labelComponent.iconRightFill = 'neutral-700';
      }
    }
  }

  private get inputClasses() {
    const inputIconType: InputIconType = this.leadingIcon
      ? `leadingIcon${this.trailingIcon ? '-and-trailingIcon' : ''}`
      : this.trailingIcon
      ? 'trailingIcon'
      : '';

    const type: InputStateType = `input-${inputIconType}${
      this.disabled
        ? '-disabled'
        : this.error || this.required
        ? '-error-or-required'
        : ''
    }`;

    const fontSize = {
      XS: 'text-caption',
      S: 'text-p3',
      M: 'text-p2',
      L: 'text-p1',
    };

    return classNames('ad-input', fontSize[this.textSize], {
      'has-leading-icon': type.includes('leadingIcon'),
      'has-trailing-icon': type.includes('trailingIcon'),
      'is-disabled': type.includes('disabled'),
      'has-error': type.includes('error'),
    });
  }

  @HostListener('focus')
  onFocus() {
    if (this.autoHide) {
      this.el.nativeElement.placeholder = '';
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.autoHide) {
      this.el.nativeElement.placeholder = this.placeholder;
    }
  }
}
