import * as fromGenerated from '../../../_generated';
import { ChatbotMessageModel } from '../models';
import { MessageType } from '../models/chatbot-message-type';
import {
  ChatbotConversationExchangeInteraction,
  ChatbotConversationExchangePresentation,
} from '../models/conversation-exchange';

export function toMessageModel(
  exchange: fromGenerated.ChatbotConversationExchangeDto
): ChatbotMessageModel[] {
  if (exchange.type === 'presentation') {
    const e = exchange as ChatbotConversationExchangePresentation;
    return [
      {
        id: 'a-' + e.id,
        type: MessageType.ai,
        text: e.ai.text,
        isException: false,
        isTemporary: e.ai.timestamp === '',
      },
    ];
  } else {
    const e = exchange as ChatbotConversationExchangeInteraction;

    return [
      {
        id: 'h-' + e.id,
        type: MessageType.human,
        text: e.human.text,
        attachments: e.human.attachments,
        isWaiting: false,
        isException: false,
        isTemporary: e.ai.timestamp === '',
      },
      {
        id: 'a-' + e.id,
        type: MessageType.ai,
        text: e.ai.text,
        attachments: e.ai.attachments,
        isWaiting: e.ai.timestamp === '',
        isException: false,
        isTemporary: e.ai.timestamp === '',
      },
    ];
  }
}
