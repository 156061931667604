import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, finalize, from, map, mergeMap, of } from 'rxjs';
import * as fromServices from '../../services';
import { CoreAuthService } from '../services';
import * as fromStore from '../store';

export const GuestGuard: CanActivateFn = () => {
  const authService = inject(CoreAuthService);
  const userService = inject(fromServices.UserService);
  const storeRepository = inject(fromStore.StoreRepository);
  const globalService = inject(fromServices.GlobalService);

  storeRepository.store.update((store: any) => ({
    ...store,
    isLoading: true,
  }));

  return from(authService.isSignedIn()).pipe(
    mergeMap(() => userService.userControllerGetMe()),
    map(() => {
      globalService.goToHome();

      return false;
    }),
    catchError(() => {
      console.warn('GuestGuard', 'Allowed');
      return of(true);
    }),
    finalize(() => {
      storeRepository.store.update((store: any) => ({
        ...store,
        isLoading: false,
      }));
    })
  );
};
