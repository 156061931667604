import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DateTime } from 'luxon';
import * as fromGenerated from '../../../_generated';

export const EquipmentHealthActions = createActionGroup({
  source: 'Equipment Health Actions',
  events: {
    Init: emptyProps(),
    'Set Date': props<{ date: DateTime }>(),
    'Upsert Diagnostic': props<{
      diagnostic: fromGenerated.EquipmentHealthDiagnosticView;
    }>(),
    'Update Diagnostic Status': props<{
      id: number;
      changes: {
        status: fromGenerated.EquipmentHealthDiagnosticStatusUpdateDto.StatusEnum;
      };
    }>(),
    'Update Diagnostic Status Success': emptyProps(),
    'Update Diagnostic Status Failure': props<{ error: any }>(),
    'Load Diagnostics': emptyProps(),
    'Load Diagnostics Success':
      props<fromGenerated.EquipmentHealthDiagnosticViewDto>(),
    'Load Diagnostics Failure': props<{ error: any }>(),
    'Update Meg Simulated Date': props<{
      execution: fromGenerated.MegExecution;
    }>(),
  },
});
