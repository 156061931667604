import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { LaunchDarklyFlagValue } from '../launch-darkly.provider';

enum FeatureFlagKeyEnum {}
export type FeatureFlagKey = `${FeatureFlagKeyEnum}`;

export interface FeatureFlag extends LaunchDarklyFlagValue {
  id: string;
  value: boolean;
}

export interface FeatureFlagsState extends EntityState<FeatureFlag> {
  ready: boolean;
}

export const featureFlagsAdapter: EntityAdapter<FeatureFlag> =
  createEntityAdapter<FeatureFlag>({
    selectId: (flag) => flag.id,
  });

export const featureFlagsInitialState: FeatureFlagsState =
  featureFlagsAdapter.getInitialState({ ready: false });
