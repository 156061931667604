import packageInfo from '../../../../package.json';
import type { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  name: 'dev',
  version: packageInfo.version,
  charlyApi: {
    baseUrl: 'https://api-dev.charly.halfserious.com',
  },
  charlyGpt: {
    baseUrl:
      'https://fxgjfpnj02.execute-api.ca-central-1.amazonaws.com/dev/chat',
  },
  charlyTts: {
    baseUrl:
      'https://6rh0gzrk02.execute-api.ca-central-1.amazonaws.com/dev/tts',
  },
  charlyLaunchDarkly: {
    clientSideId: '66300788f84f5b106d4cfcd7',
  },
  charlyWsApi: {
    baseUrl: 'wss://hiis7i5r46.execute-api.ca-central-1.amazonaws.com/dev/',
    pingInterval: 60000,
  },
  auth: {
    aws_project_region: 'ca-central-1',
    aws_cognito_identity_pool_id:
      'ca-central-1:2e0489a4-dec3-417c-ae2d-915a23605c2f',
    aws_cognito_region: 'ca-central-1',
    aws_user_pools_id: 'ca-central-1_9ZKfBVKby',
    aws_user_pools_web_client_id: '1hbncusb4vqnkrhhph3pj82ucb',
  },
  stt: {
    emptyTranscriptTimeoutSeconds: 10,
  },
};
